import React, { useContext, useEffect, useState } from 'react';
import useExperienceApi from './useExperienceApi';
import { ExperienceContext } from './experienceContext.js';
import { AuthContext } from '@/auth/lib/authContext';
import useProductsApi from '../../products/lib/useProductsApi.js';
import usePersonaApi from '../../persona/lib/usePersonaApi.js';
import useInsightApi from '../../insight/lib/useInsightApi.js';

export default function ExperienceProvider(props) {

  const {
    experienceId,
    needPersonasAndProducts = false,
    needInsights = false,
    children } = props;
  const [experience, setExperience] = useState();
  const [rights, setRights] = useState({
    can_update: false,
    can_select: false,
    can_delete: false
  });
  const experienceApi = useExperienceApi();
  const productApi = useProductsApi();
  const personaApi = usePersonaApi();
  const insightApi = useInsightApi();
  const { profile, hasProfile } = useContext(AuthContext);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);
  const [isFetchingPersonaList, setIsFetchingPersonaList] = useState(false);
  const [isFetchingProductList, setIsFetchingProductList] = useState(false);
  const [isFetchingExperience, setIsFetchingExperience] = useState(false);
  const [productList, setProductList] = useState();
  const [personaList, setPersonaList] = useState();
  const [isFetchingInsights, setIsFetchingInsights] = useState(false);
  const [activeInsights, setActivesInsights] = useState();
  const [archivedInsights, setArchivedInsights] = useState();

  const refreshInsights = async () => {
    try {
      setIsFetchingInsights(true);
      const newInsightList = await insightApi.fetchInsightListByExperienceId(experienceId);
      if (newInsightList) {
        const newActives = newInsightList.filter((f) => {
          return !f.archived
        });
        setActivesInsights(newActives);
        const newArchived = newInsightList.filter((f) => {
          return f.archived;
        });
        setArchivedInsights(newArchived);
      }
      setIsFetchingInsights(false);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshProductList = async () => {
    try {
      setIsFetchingProductList(true);
      const newProducts = await productApi.fetchProductsByExperienceId(experienceId);
      setProductList((newProducts ? newProducts : []));
      setIsFetchingProductList(false);
    } catch (error) {
      console.error(error)
    }
  }

  const refreshPersonaList = async () => {
    try {
      setIsFetchingPersonaList(true);
      const newPersonaList = await personaApi.fetchManyPersonaByExperienceId(experienceId);
      setPersonaList((newPersonaList ? newPersonaList : []));
      setIsFetchingPersonaList(false);
    } catch (error) {
      console.error(error)
    }
  }

  const refreshPersonaAndProductList = async () => {
    refreshPersonaList();
    refreshProductList();
  }

  const refresh = async () => {
    try {
      if (needPersonasAndProducts) {
        refreshPersonaAndProductList();
      }
      if (needInsights) {
        refreshInsights();
      }
      setIsFetchingExperience(true);
      const promises = [
        experienceApi.fetchExperience(experienceId),
        experienceApi.fetchUserRights(experienceId, profile.id)
      ];
      const result = await Promise.all(promises)
      setExperience(result[0]);
      setRights(result[1]);
      setIsFetchingExperience(false);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (isReadyToFetch && !previouslyReadyToFetch) {
      setPreviouslyReadyToFetch(true)
      refresh()
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

  useEffect(() => {
    if (experienceId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
    }
  }, [experienceId, hasProfile]);

  return (
    <ExperienceContext.Provider value={{
      experience,
      experienceId,
      rights,
      refresh,
      refreshPersonaAndProductList,
      refreshPersonaList,
      refreshProductList,
      isFetchingPersonaList,
      isFetchingProductList,
      productList,
      personaList,
      isFetchingInsights,
      archivedInsights,
      activeInsights,
      refreshInsights,
      isFetchingExperience
    }} >
      {children}
    </ExperienceContext.Provider>
  );
}