'use client'

import React, { useContext, useState } from 'react';
import {Button, FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {Box, Divider, Flex} from '@chakra-ui/layout';
import {Text} from '@chakra-ui/layout';
import { useForm } from 'react-hook-form';
import GoogleIcon from './googleIcon';
import { AuthContext } from '../lib/authContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export default function SignInForm() {
  
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [globalError, setGlobalError] = useState(null);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');
  const arg = searchParams.get('arg');

   const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({ mode: 'onBlur'});

  const signInWithGoogle = async () => {
    try {
      authContext.signInWithGoogle();
    } catch (e) {
      setGlobalError(e.message);
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      await authContext.signIn(data.email, data.password);
    } catch (e) {
      setGlobalError(e.message);
      console.error(e);
    }
  };
  
  const goToPasswordReset = () => {
    const email = getValues('email');
    if(email) {
      navigate('/auth/reset-password' + '?email=' + email)
    } else {
      navigate('/auth/reset-password')
    }
  }

  return (
    <>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        w="100%"
        >
        <Flex
          as='form'
          w='100%'
          flexDirection={'column'}
          alignItems={'center'}
          onSubmit={handleSubmit(onSubmit)} >
          <FormControl isInvalid={errors.email} mb={2}>
              <Input
                placeholder='Email'
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
              />
              <FormErrorMessage color="text.medium.red">{errors.email?.message}</FormErrorMessage>
            </FormControl>
          <FormControl isInvalid={errors.password} mb={1}>
              <Input
                placeholder='Password'
                type="password"
                {...register('password', {
                  required: true
                })}
              />
              <FormErrorMessage color="text.medium.red">{errors.password?.message}</FormErrorMessage>
            </FormControl>
            {globalError && (
            <Box mb={3}>
              <Text color={'text.medium.red'}>{globalError}</Text>
            </Box>
          )}
          <Flex mb={3} justifyContent={'center'}>
            <Button
              textAlign={'center'}
              variant="coralTransparent"
              onClick={goToPasswordReset}>
              Forgot Password?
            </Button>
          </Flex>
          <Flex
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
          > 
            <Button
              w="120px"
              type={'submit'}
              variant="coralFill"
              isLoading={isSubmitting}
              isDisabled={!isValid}
              mb={3}
            >Sign In</Button>
            <Button
              onClick={signInWithGoogle}
              variant="greyOutline"
              leftIcon={<GoogleIcon />} >
              Sign In with Google
            </Button>
          </Flex>
          <Divider mt={3} />
          <Link to={"/auth/sign-up" + (redirectUrl ? '?redirect_url=' + redirectUrl : '') + (arg ? '&arg=' + arg : '')}>
              <Button
                variant="coralTransparent"
                mt={3}
                textAlign={'center'}
              >
              New here? Sign up
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  );
}
