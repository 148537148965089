import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import SizableImage from './sizableImage';
import { Button, Icon, Input, useStyleConfig } from '@chakra-ui/react';
import { MdDelete, MdSync, MdUpload } from 'react-icons/md';
import { IconButton } from '@chakra-ui/button';
import Cropper from 'react-easy-crop'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import resizeImageWithRatio from '../lib/resizeImageWithRatio';

export default function ImageInput(props) {

  const {
    value,
    maxWidth,
    maxHeight,
    text,
    crop,
    outputWidths,
    onChange,
    outputFormat='png'
  } = props;
  const [image, setImage] = useState(null);
  const [resizedAndCropped, setResizedAndCropped] = useState(false);
  const [cropValue, setCropValue] = useState({ x: 0, y: 0 });
  const [zoomValue, setZoomValue] = useState(1);
  const [croppedArea, setResizedAndCroppedArea] = useState(null);
  const [croppedAreaPixels, setResizedAndCroppedAreaPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setResizedAndCroppedArea(croppedArea);
    setResizedAndCroppedAreaPixels(croppedAreaPixels);
  };

  const resizeAndCrop = async () => {
    setIsLoading(true);
    let originalImage ;
    if(croppedAreaPixels && (croppedAreaPixels.x || croppedAreaPixels.y)) {
      originalImage = await resizeImageWithRatio(image, null, {croppedAreaPixels, format: outputFormat})
    } else {
      originalImage = await resizeImageWithRatio(image, null, {format: outputFormat})
    }
    const outputBlobImages = { originalImage }

    if (outputWidths) {
      const promises = outputWidths.map((w) => {
        return resizeImageWithRatio(image, w, {croppedAreaPixels, format: outputFormat})
      });
      const images = await Promise.all(promises);
      images.forEach((img, i) => {
        outputBlobImages[outputWidths[i]] = img;
      });
    }
    onChange(outputBlobImages);
    onClose();
    setResizedAndCropped(true);
    setIsLoading(false);
  };

  const onImageLoaded = (e) => {
    setImage(e.target.result);
  };

  const reset = () => {
    setImage(null)
    onChange(null)
    inputRef.current.children[0].value = null;
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  }

  const onDrop = async (event) => {
    event.preventDefault();
    try {
      const nativeEvent = event.nativeEvent;
      let dt = nativeEvent.dataTransfer
      let files = dt.files
      if (files[0].type.includes('image/')) {
        setResizedAndCropped(false);
        onChange({
          originalImage: files[0]
        });
      }
      setIsDragging(false);
    } catch (error) {
      console.error(error)
    }
  }

  const baseStyle = useStyleConfig('DraggableBox', { variant: 'base' });
  const draggingStyle = useStyleConfig('DraggableBox', { variant: 'dragging' });

  useEffect(() => {
    if (value && value.originalImage) {
      if (typeof value.originalImage === 'string') {
        setImage(value.originalImage);
      } else {
        const reader = new FileReader();
        reader.onload = onImageLoaded;
        reader.readAsDataURL(value.originalImage);
      }
    }
  }, [value]);

  useEffect(() => {
    if (image && inputRef.current.children[0].value) {
      if (crop && !resizedAndCropped) {
        setIsLoading(false);
        onOpen()
      } else if (!crop && !resizedAndCropped) {
        resizeAndCrop();
      }
    } else if (!inputRef.current.children[0].value && !resizedAndCropped) {
      resizeAndCrop();
    } 
  }, [image]);

  useEffect(() => {
    if (crop) {
      setCropValue({
        x: crop.x,
        y: crop.y,
      });
      setZoomValue(crop.zoom)
    }
  }, [crop]);

  return (
    <Flex
      flex="1"
      flexDirection={"column"}
      alignItems={"stretch"}
    >
      {image && (
        <Flex>
          <Flex
            mb={2}
            mr={3}
            boxShadow={"0px 0px 6px 0px rgba(163,163,163,0.30)"}
          >
            <SizableImage
              src={image}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
            />
          </Flex>
          <Flex alignItems={"center"}>
            <IconButton
              variant="redOutline"
              icon={<MdDelete />}
              onClick={reset}
              mr={1}
            />
            <IconButton
              icon={<MdSync />}
              variant="greyOutline"
              onClick={() => {
                inputRef.current.children[0].click();
              }}
            />
          </Flex>
        </Flex>
      )}
      <Flex style={{ display: 'none' }} ref={inputRef} >
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => {
            setResizedAndCropped(false);
            onChange({
              originalImage: event.currentTarget.files[0]
            });
          }}
        />
      </Flex>
      {!image && (
        <Flex
          cursor={"pointer"}
          h="200px"
          alignItems={"center"}
          justifyContent={"center"}
          onDragOver={onDragOver}
          onDrop={onDrop}
          sx={isDragging ? draggingStyle : baseStyle}
          onClick={() => {
            inputRef.current.children[0].click();
          }}
        >
          <Icon
            as={MdUpload}
            color={'text.medium.grey'}
            boxSize={'14px'}
            mr={2}
          />
          {text || <Text color={"text.medium.grey"} fontSize="14px">Upload Image</Text>}
        </Flex>)
      }
      {crop && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent >
            <ModalHeader>Resize</ModalHeader>
            <ModalBody >
              <Box position={'relative'} style={{ width: '100%', height: '350px', boxizing: 'border-box' }}  >
                <Box position={'absolute'} style={{ width: '100%', height: '100%' }}>
                  <Cropper
                    image={image}
                    crop={cropValue}
                    zoom={zoomValue}
                    aspect={crop.aspect}
                    onCropChange={setCropValue}
                    onZoomChange={setZoomValue}
                    onCropComplete={onCropComplete}
                  />
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="greyFill"
                isLoading={isLoading}
                ml={2}
                onClick={resizeAndCrop}>Validate</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}
