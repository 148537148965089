import React, { useContext, useEffect, useState } from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import PersonaProvider from '../lib/personaProvider';
import PersonaAside from './components/personaAside';
import ExperiencesInPersonaList from '@/persona/[id]/components/experiencesInPersonaList';
import PersonaMembers from './components/personaMembers';
import PersonaBio from './components/personaBio';
import PersonaPageProvider from './lib/personaPageProvider';
import InsightCardList from '@/insight/components/insightCardList';
import useInsightApi from '@/insight/lib/useInsightApi';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import { useParams } from 'react-router-dom';
import LoadingPersona from './LoadingPersona';
import InsightTab from '../../insight/components/insightTab';

export default function PersonaDetailPage() {


  const [fetched, setFetched] = useState(false);
  const [previouslyFetched, setPreviouslyFetched] = useState(false);
  const insightApi = useInsightApi();
  const [lists, setLists] = useState();
  const [loadingPersona, setLoadingPersona] = useState(true);

  const params = useParams();

  const fetchData = async () => {
    try {
      const result = await Promise.all([
        insightApi.fetchInsightListByPersonaId(params.id, { type: insightTypeDictionnary.negative }),
        insightApi.fetchInsightListByPersonaId(params.id, { type: insightTypeDictionnary.improvement }),
        insightApi.fetchInsightListByPersonaId(params.id, { type: insightTypeDictionnary.positive }),
        insightApi.fetchInsightListByPersonaId(params.id, { type: insightTypeDictionnary.motivation }),
        insightApi.fetchInsightListByPersonaId(params.id, { type: insightTypeDictionnary.behavior }),
      ])
      setLists({
        [insightTypeDictionnary.negative]: result[0],
        [insightTypeDictionnary.improvement]: result[1],
        [insightTypeDictionnary.positive]: result[2],
        [insightTypeDictionnary.motivation]: result[3],
        [insightTypeDictionnary.behavior]: result[4],
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (fetched && !previouslyFetched) {
      setPreviouslyFetched(true);
      fetchData();
    }
  }, [fetched]);

  useEffect(() => {
    if (params.id && !fetched) {
      setFetched(true);
    }
  }, [params.id])

  useEffect(() => {
    if (params.id) {
      setLoadingPersona(true);
    }
  }, [params.id])

  return (
    <>
      {params.id && (
        <>
        {loadingPersona && (
          <LoadingPersona />
        )}
        <PersonaProvider
          personaId={params.id}
          onInit={() => {
            setLoadingPersona(false);
          }}
          >
          <PersonaPageProvider>
            {!loadingPersona && (
            <Flex
            flexDirection="row"
            w="100%"
            h="100%"
          >
            <PersonaAside />
            <Flex
              flex="1"
              flexDirection={"column"}
              overflow={"auto"}
            >
              <Flex
                px={3}
                py={2}
                bg="white"
                borderBottom="1px"
                borderColor="divider.grey"
                borderStyle="solid"
                justifyContent="flex-end"
              >
                <PersonaMembers />
              </Flex>
              <Flex
                p={3}
                flex="1"
                overflow={"auto"}
                flexDirection={'column'}
              >
                <PersonaBio />
                <InsightTab
                  lists={lists}
                  onSetAsOutdated={fetchData}
                 />
                <ExperiencesInPersonaList />
              </Flex>
            </Flex>
          </Flex>
            )}
          </PersonaPageProvider>
        </PersonaProvider>
      </>)}
    </>
  )
}




