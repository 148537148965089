'use client'

import React, { useContext, useEffect, useState } from 'react';
import PersonaList from '../persona/components/personaList';
import ProductList from '../products/components/productList';
import InsightCardList from '../insight/components/insightCardList';
import useInsightApi from '../insight/lib/useInsightApi';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { MdClose } from 'react-icons/md';
import { SettingsContext } from '../settings/lib/settingsContext';

export default function HomePage() {

  const [lists, setLists] = useState();
  const insightApi = useInsightApi();
  const settingsContext = useContext(SettingsContext);

  const fetchData = async () => {
    try {
     const result = await Promise.all([
        insightApi.fetchAllMyActiveInsightByType(insightTypeDictionnary.negative),
        insightApi.fetchAllMyActiveInsightByType(insightTypeDictionnary.improvement)
      ])
      setLists({
        [insightTypeDictionnary.negative]: result[0],
        [insightTypeDictionnary.improvement]: result[1]
      })
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!lists) {
      fetchData();
    }
  }, [])
    
  return (
    <>
      <Flex
          py={3}
          flexDirection={"column"}
          alignSelf={"stretch"}
          flex={1}
          overflowY={"auto"}
        >
          {(settingsContext?.settings && settingsContext.settings.display_onboarding_video) && (
            <Flex
              m={3}
              mt={0}
              flexDirection={"column"}
              border="1px solid"
              borderColor="divider.grey"
              backgroundColor={"white"}
            >
            <Flex  px={3} py={2}  flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <Flex fontSize={"18px"} flexDirection={"column"} >
                <Text>Welcome!</Text>
                <Text fontSize={"14px"} color="text.medium.grey">Are you new here? Watch this video to familiarize yourself with Bibliona.</Text>
              </Flex>
              <IconButton
                className="close"
                onClick={() => {
                settingsContext.hideOnboardingVideo();
              }}
              variant={"greyTransparent"}
              icon={<Icon as={MdClose}
              boxSize={"20px"}/>} />
            </Flex>
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/ukM0ZY4bHfY?si=N4bjMSIe64l7u1KD" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </Flex>
          )}
        <Flex 
          px={3}
          width={"100%"}
         >
          <InsightCardList
            lists={lists}
            onSetAsOutdated={fetchData}
          />
        </Flex>
        <Flex
          w="100%"
          px={3}
          mb={3}
        >
          <Flex
            border="1px solid"
            borderColor={"divider.grey"}
            w="100%"
          >
            <PersonaList
              insidePaddings={false}
              emptyHeight={"300px"}
              maxHeight={"450px"}
            />
          </Flex>
        </Flex>
        <Flex
          w="100%"
          px={3}
          >
          <Flex
            w="100%"
            border="1px solid"
            borderColor={"divider.grey"}
          >
            <ProductList
              insidePaddings={false}
              emptyHeight={"300px"}
              maxHeight={"450px"}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
