import React, { useContext, useEffect, useRef, useState } from 'react';
import { OrganisationContext } from '../lib/organisationContext';
import { Box, Button, Flex, Icon, IconButton, Skeleton, Text, useDisclosure, useToast } from '@chakra-ui/react';
import ProfileProvider from '../../profile/lib/profileProvider';
import OrganisationMember from './organisationMember';
import { MdClose, MdOutlineInfo } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import AddProfileInput from '../../profile/component/AddProfileInput';
import useOrganisationApi from '../lib/useOrganisationApi';
import { AuthContext } from '../../auth/lib/authContext';

export default function OrganisationMembers(props) {

  const {
    members,
    isFetchingMembers,
    rights,
    organisation
  } = useContext(OrganisationContext);

  const {
    profile
  } = useContext(AuthContext);

  const modalInvite = useDisclosure();
  const organisationApi = useOrganisationApi();
  const modaleRef = useRef()
  const [email, setEmail] = useState();
  const [isDomainValid, setIsDomainValid] = useState();
  const [fullDomain, setFullDomain] = useState();
  const [isInviting, setIsInviting] = useState(false);
  const toast = useToast();

  const checkDomain = () => {
    const newFullDomain = email.split('@')[1];
    let exists = false;
    organisation.domains.forEach((domain) => {
      if(domain === newFullDomain) {
        exists = true;
      }
    })
    setFullDomain(newFullDomain);
    setIsDomainValid(exists);
  }

  const sendInvitation = async () => {
    try {
      setIsInviting(true);
      await organisationApi.sendInvitation(email,
        organisation.id,
        profile.id);
      setEmail(null);
      toast({
        position: 'bottom-right',
        description: "Invitation successfuly sent",
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
     modalInvite.onClose();
     setIsInviting(false);
    } catch (error) {
      console.error(error)
      toast({
        position: 'bottom-right',
        description: "Sorry for some reasons the invitation wasn't sent.",
        status: 'fail',
        duration: 1000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if(email) {
      checkDomain();
    }
  }, [email])

  return (
    <Flex
      width={"100%"}
      flexDirection={"column"}
      gap={3}
    >
      {(members && members.length > 0) && (
        <Flex
          flexDirection={"column"}
          gap={2}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            backgroundColor={"white"}
            borderBottom={"1px solid"}
            borderColor={"divider.grey"}
            px={3}
            py={1}
          >
            <Text>Members</Text>
            {(rights.can_update) && (
              <Button
                variant="greyFill"
                onClick={() => {
                modalInvite.onOpen();
              }}>Invite</Button>
            )}
          </Flex>
          <Flex
            flexDirection={"column"}
            px={3}
            py={2}
            gap={2}
          >
            <Flex
              flexDirection={"column"}
              border={"1px solid"}
              borderColor={"divider.grey"}
            >
              {isFetchingMembers &&
                <Skeleton width={"100%"} height={"300px"} />
              }
              {!isFetchingMembers
                && members.map((m, i) => {
                  return (
                    <Flex
                      key={i}
                      w={"100%"}
                      borderBottom={"1px solid"}
                      borderColor={i < members.length - 1 ? "divider.grey" : 'transparent'}
                    >
                      <ProfileProvider profileId={m.profile_id}>
                        <OrganisationMember member={m} />
                      </ProfileProvider>
                    </Flex>
                  )
                })}
            </Flex>
            {(rights.can_update && members?.length === 1) && (
              <Flex
                p={3}
                backgroundColor={"white"}
                border={"1px solid"}
                borderColor={"divider.grey"}
                flexDirection={"column"}
                gap={1}
              >
                <Flex
                  gap={2}
                  alignItems={"center"}
                >
                  <Icon as={MdOutlineInfo} color={'text.medium.grey'} />
                  <Text color={'text.medium.grey'}>How to add new members?</Text>
                </Flex>
                <Text maxW={"600px"}>New users will need to sign in to Bibliona first. If your organisation has multiple email domains, please add them on the left panel to grant authorization.</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
      <Modal
        isOpen={modalInvite.isOpen}
        onClose={modalInvite.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Invite New Member</ModalHeader>
          <ModalBody ref={modaleRef} >
            <Box p={3} >
              <Flex
                p={3}
                backgroundColor={"white"}
                border={"1px solid"}
                borderColor={"divider.grey"}
                flexDirection={"column"}
                gap={1}
              >
                <Flex
                  gap={2}
                  alignItems={"center"}
                >
                  <Icon as={MdOutlineInfo} color={'text.medium.grey'} />
                  <Text color={'text.medium.grey'}>How to invite new members?</Text>
                </Flex>
                <Text maxW={"600px"}>By clicking on "send email" the users will receive a link to sign in into Bibliona.</Text>
                <Flex mt={2}>
                  {!email && (
                      <AddProfileInput
                      placeholder='Add People by searching or typing email'
                      clickTarget={modaleRef}
                      variant="greenOutline"
                      onChange={(e) => {
                        setEmail(e.email);
                      }}
                    />
                  )}
                  {email && (
                    <Flex
                      flexDirection={"column"}
                      width={"100%"}>
                     <Flex
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Text fontSize={"16px"}>{email}</Text>
                      <IconButton
                        variant="greyOutline"
                        fontSize='16px'
                        icon={<MdClose />}
                        ml={2}
                        onClick={() => {
                          setEmail('');
                        }}
                      />
                    </Flex>
                    {!isDomainValid && (
                      <Flex
                        flexDirection={"column"}
                      >
                         <Text 
                         color={"text.medium.orange"}
                         mt={2}
                         fontSize={"16"}>
                              External Domain
                          </Text>
                          <Text color={"text.medium.grey"} mt={1}>
                              You haven't granted the autorization for the domain of this email address "{fullDomain}". Please add the domain on the left panel (current domains: {organisation.domains.concat(',')}).
                          </Text>
                        </Flex>
                    )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="greyOutline"
              ml={2}
              onClick={() => {
                modalInvite.onClose();
              }}
            >Cancel</Button>
            <Button
              variant="greyFill"
              ml={2}
              isDisabled={(!email || !isDomainValid)}
              onClick={sendInvitation}
              isLoading={isInviting}
            >Send email</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}