import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ProfileThumbnail from '../profile/component/profileThumbnail';
import ProfileProvider from '../profile/lib/profileProvider';
import dayjs from "dayjs";
import { ProfileContext } from '../profile/lib/profileContext';
import { AuthContext } from '../auth/lib/authContext';
import { MdCheck, MdClose, MdMoreVert, MdOutlineWarning } from 'react-icons/md';
import ResizeTextarea from "react-textarea-autosize";

export default function Comment(props) {

  const {
    comment,
    editComment,
    deleteComment
  } = props;

  const [postDate, setPostDate] = useState();
  const authContext = useContext(AuthContext)
  const [nbOfLines, setNbOfLines] = useState(3);
  const [editMode, setEditMode] = useState(false);
  const [newMessage, setNewMessage] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textRef = useRef();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const deleteDisclosure = useDisclosure();

  const checkOverflow = () => {
    const element = textRef.current;
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      checkOverflow();
    }, 100)
  }, []);

  useEffect(() => {
    if (comment.created_at) {
      const date = dayjs(comment.created_at);
      const newPostDate = dayjs().to(date);
      setPostDate(newPostDate);
    }
  }, [comment]);

  useEffect(() => {
    setNewMessage(comment.message)
  }, [editMode]);

  return (
    <ProfileProvider
      profileId={comment.profile_id}
      originalProfile={comment.profile}
    >
      <Flex
        gap={2}
        px={2}
        py={2}
        alignItems={"flex-start"}
      >
        <ProfileThumbnail size={35} />
        <Flex
          flexDirection={"column"}
          gap={2}
          flex={"1"}
        >
          <Flex>
            <Flex
              flexDirection={"column"}
              flex={"1"}
            >
              <CommentUserPseudo />
              <Flex>
                <Text
                  fontSize={"12px"}
                  color={"text.medium.grey"}
                >{postDate}</Text>
                {comment.updated && (
                  <>
                    <Text
                      fontSize={"12px"}
                      color={"text.medium.grey"}
                      mx={1}
                    >
                      •
                    </Text>
                    <Text
                      fontSize={"12px"}
                      color={"text.medium.grey"}
                    >Modified</Text>
                  </>
                )}
              </Flex>
            </Flex>
            {authContext?.profile?.id === comment.profile_id
              &&
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={IconButton}
                      icon={<MdMoreVert />}
                      fontSize={"16px"}
                      variant='greyTransparent'
                    />
                    <MenuList>
                      {editComment &&
                        <MenuItem onClick={() => { setEditMode(true) }}>Edit</MenuItem>
                      }
                      {deleteComment && (
                        <MenuItem onClick={async () => {
                          deleteDisclosure.onOpen();
                        }}>
                          <Text color='text.medium.red' >Delete</Text>
                          <Modal
                            isOpen={deleteDisclosure.isOpen}
                            onClose={deleteDisclosure.onClose}
                            >
                            <ModalOverlay />
                            <ModalContent >
                              <ModalHeader>Delete Comment</ModalHeader>
                              <ModalBody p={3}>
                                <Flex
                                  border="1px"
                                  borderColor="divider.grey"
                                  borderRadius="2px"
                                  backgroundColor="white"
                                  alignItems={'center'}
                                  p={3}
                                >
                                  <Icon
                                    as={MdOutlineWarning}
                                    color={'fill.red'}
                                    boxSize={'18px'}
                                    mr={3}
                                  />
                                  <Text textStyle="body" color={'fill.red'} >Are you sure to delete this comment?</Text>
                                </Flex>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  variant='redOutline'
                                  onClick={deleteDisclosure.onClose}
                                >Close</Button>
                                <Button
                                  variant="redFill"
                                  ml={2}
                                  isLoading={isDeleting}
                                  onClick={async () => {
                                    setIsDeleting(true);
                                    await deleteComment(comment.id)
                                    setIsDeleting(false);
                                  }}>Delete</Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </MenuItem>
                      )}
                    </MenuList>
                  </>
                )}
              </Menu>
            }
          </Flex>
          {!editMode &&
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Text
                ref={textRef}
                noOfLines={nbOfLines}
              >
                {comment.message}
              </Text>
              {(isOverflowing && nbOfLines === 3) && (
                <Button
                  height={"25px"}
                  px={0}
                  variant={"coralTransparent"}
                  onClick={() => { setNbOfLines(100) }}>See more</Button>)
              }
              {(isOverflowing && nbOfLines === 100) && (
                <Button
                  height={"25px"}
                  px={0}
                  variant={"coralTransparent"}
                  onClick={() => { setNbOfLines(3) }}>See less</Button>)
              }
            </Flex>
          }
          {editMode &&
            <Flex
              flexDirection={"column"}
              gap={2}
              alignItems={"flex-end"}
            >
              <Textarea
                placeholder='Edit comment'
                as={ResizeTextarea}
                resize="none"
                minH={"42px"}
                maxH={"200px"}
                value={newMessage}
                onChange={(e) => {
                  setNewMessage(e.target.value)
                }}
              />
              <Flex
                gap={2}
              >
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={() => { setEditMode(false) }}
                />
                <IconButton
                  variant="coralOutline"
                  icon={<MdCheck />}
                  aria-label='check'
                  isLoading={isSubmitting}
                  onClick={async () => {
                    setIsSubmitting(true);
                    await editComment(newMessage, comment.id);
                    setEditMode(false);
                    setIsSubmitting(false);
                  }}
                />
              </Flex>
            </Flex>
          }
        </Flex>
      </Flex>
    </ProfileProvider>
  );
}

function CommentUserPseudo(props) {

  const { profile } = useContext(ProfileContext)

  return (
    <Text
      fontWeight="500"
      noOfLines={1}
    >{profile.pseudo}</Text>
  );
}
