import React, { useEffect, useState } from 'react';
import useInsightApi from '@/insight/lib/useInsightApi';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import InsightTags from '@/insight/components/insightTags';

export default function PersonaInsightTags(props) {

  const { personaId,
    insightMetadata
  } = props;
  const insightApi = useInsightApi();
  const [isInit, setIsInit] = useState(false);
  const [nbPostiveFeedback, setNbPostiveFeedback] = useState(0);
  const [nbNegativeFeedback, setNbNegativeFeedback] = useState(0);
  const [nbImprovementFeedback, setNbImprovementFeedback] = useState(0);
  const [nbBehavior, setNbBehavior] = useState(0);
  const [nbMotivation, setNbMotivation] = useState(0);

  const init = async () => {
    try {
      setIsInit(true);
      if (insightMetadata === undefined) {
        if (personaId) {
          const promises = []
          promises.push(
            insightApi.fetchInsightListByPersonaId(personaId, {
              type: insightTypeDictionnary.positive,
              status: 'active',
            }),
            insightApi.fetchInsightListByPersonaId(personaId, {
              type: insightTypeDictionnary.negative,
              status: 'active',
            }),
            insightApi.fetchInsightListByPersonaId(personaId, {
              type: insightTypeDictionnary.improvement,
              status: 'active',
            }),
            insightApi.fetchInsightListByPersonaId(personaId, {
              type: insightTypeDictionnary.behavior,
              status: 'active',
            }),
            insightApi.fetchInsightListByPersonaId(personaId, {
              type: insightTypeDictionnary.motivation,
              status: 'active',
            })
          )
          const result = await Promise.all(promises) ;
          setNbPostiveFeedback((result[0].length));
          setNbNegativeFeedback((result[1].length));
          setNbImprovementFeedback((result[2]).length);
          setNbBehavior((result[3].length));
          setNbMotivation((result[4].length));
        }
      } else {
        if(insightMetadata) {
          setNbPostiveFeedback(insightMetadata.positive_insight_count);
          setNbNegativeFeedback(insightMetadata.negative_insight_count);
          setNbImprovementFeedback(insightMetadata.improvement_insight_count);
          setNbBehavior(insightMetadata.behavior_insight_count);
          setNbMotivation(insightMetadata.motivation_insight_count);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (personaId) {
      init()
    }
  }, [personaId]);

  return (
    <> {
      isInit && (
        <InsightTags
          nbPostiveFeedback={nbPostiveFeedback}
          nbNegativeFeedback={nbNegativeFeedback}
          nbImprovementFeedback={nbImprovementFeedback}
          nbBehavior={nbBehavior}
          nbMotivation={nbMotivation}
        />
      )
    }
    </>
  );
}
