import React, { useContext, useEffect, useState } from 'react';
import useInsightApi from './useInsightApi';
import { InsightContext } from './insightContext.js';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import useProductsApi from '@/products/lib/useProductsApi';
import { AuthContext } from '@/auth/lib/authContext';

export default function InsightProvider(props) {

  const {
    insightId,
    children,
    fetchPersonaList = 'only-specified',
    fetchProductList = 'only-specified',
    originalInsight,
    onInit,
    onInsightChange
  } = props;
  const [insight, setInsight] = useState();
  const [personaList, setPersonaList] = useState();
  const [productList, setProductList] = useState();
  const insightApi = useInsightApi();
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [rights, setRights] = useState({
    can_update: false,
    can_select: false,
    can_delete: false
  });
  const { profile, hasProfile } = useContext(AuthContext);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const fetchPersonaAndProductList = async () => {
    try {
      if (profile && insight) {
        if (fetchPersonaList) {
          let newPersonaList;
          if (!insight.applicable_to_all_persona) {
            newPersonaList = await personaApi.fetchManyPersonaByInsightId(insight.id);
          } else if (fetchPersonaList === 'always') {
            newPersonaList = await personaApi.fetchManyPersonaByExperienceId(insight.experience_id);
          }
          setPersonaList(newPersonaList);
        }
        if (fetchProductList) {
          let newProductList;
          if (!insight.applicable_to_all_products) {
            newProductList = await productApi.fetchProductsByInsightId(insight.id)
          } else if (fetchProductList === 'always') {
            newProductList = await productApi.fetchProductsByExperienceId(insight.experience_id);
          }
          setProductList(newProductList);
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const refresh = async (options = {skipInsight: false}) => {
    try {
      if(options.skipInsight && originalInsight) {
        const newRights = await insightApi.fetchUserRights(originalInsight.id, profile.id)
        setRights(newRights);
      } else {
        const newInsight = await insightApi.fetchInsight(insightId);
        setInsight(newInsight);
        const newRights = await insightApi.fetchUserRights(newInsight.id, profile.id)
        setRights(newRights);
      }
      if (!isInit && onInit) {
        onInit(true);
        setIsInit(true);
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (hasProfile && insight) {
      fetchPersonaAndProductList();
    }
  }, [hasProfile, insight]);

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      if (originalInsight) {
        refresh({skipInsight: true})
      } else {
        refresh()
      }
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

  useEffect(() => {
    if (insightId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
      setIsInit(false);
    }
  }, [insightId, hasProfile]);

  useEffect(() => {
    if (originalInsight && hasProfile) {
      setInsight(originalInsight);
    }
  }, [originalInsight, hasProfile]);

  useEffect(() => {
    if (onInsightChange) {
      onInsightChange();
    }
  }, [insight]);

  return (
    <InsightContext.Provider value={{
      insight,
      insightId,
      refresh,
      personaList,
      productList,
      rights,
    }} >
      {children}
    </InsightContext.Provider>
  );
}