import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, Spinner, list } from '@chakra-ui/react';
import { Box, Flex, Text, Stack } from '@chakra-ui/layout';
import { MdAdd, MdArrowBack, MdOutlineLayers } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Icon } from '@chakra-ui/icon';
import { MdOutlineRoute } from 'react-icons/md';
import useExperienceApi from '@/experiences/lib/useExperienceApi';
import InsightTags from '@/insight/components/insightTags';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import { PersonaContext } from '../../lib/personaContext';
import { PersonaPageContext } from '../lib/personaPageContext';
import PersonaInsightTags from './personaInsightTags';
import ExperienceGroupInPersonaNameForm from './ExperienceGroupInPersonaNameForm';
import AddExperienceToPersonaForm from './AddExperienceToPersonaForm';
import NewExperienceInPersonaForm from './newExperienceInPersonaForm';
import NewExperienceGroupInPersonaForm from './newExperienceGroupInPersonaForm';
import ExperiencesInPersonaListItem from './experiencesInPersonaListItem';
import ExperienceGroupInPersonaListItem from './experienceGroupInPersonaListItem';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export default function ExperiencesInPersonaList() {

  const {
    personaId,
    persona,
    rights,
  } = useContext(PersonaContext);

  const {
    refreshExperienceList,
    refreshProductList,
    isFetchingExperiences,
    experienceList,
    experienceGroupList,
  } = useContext(PersonaPageContext);

  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [goingBack, setGoingBack] = useState(false);
  const [localList, setLocalList] = useState(null);
  const [objectiveDefault, setObjectiveDefault] = useState(null);
  const [group, setGroup] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const modalAddExperience = useDisclosure();
  const modalCreateExperience = useDisclosure();
  const modalAddExperienceGroup = useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const navigate = useNavigate();
  const experienceApi = useExperienceApi();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const groupId = searchParams.get('group-id')

  function compare(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  const updateOrderLocally = (item, destination) => {
    let newLocalList;
    const source = item.order;
    if (destination < source) {
      newLocalList = localList.map((e) => {
        if (
          e.order < source
          && e.order >= destination
          && e.id !== item.id
        ) {
          e.order++
        } else if (e.id === item.id) {
          e.order = destination;
        }
        return e;
      })
    } else if (destination > source) {
      newLocalList = localList.map(e => {
        if (e.order > source
          && e.order <= destination
          && e.id !== item.id) {
          e.order--;
        } else if (e.id === item.id) {
          e.order = destination;
        }
        return e;
      })
    } else if (destination === source) {
      newLocalList = Array.from(localList);
    }
    newLocalList.forEach(newLocalListItem => {
      newLocalListItem.hover = false;
    })
    newLocalList.forEach(newLocalListItem => {
      newLocalListItem.area = null;
    })
    newLocalList = newLocalList.sort(compare);
    setLocalList(newLocalList);
  };

  const orderAndMoveItems = async () => {
    try {
      let newLocalList = Array.from(localList);
      let lastItemHover;
      newLocalList.forEach((newLocalListItem) => {
        if (newLocalListItem.hover) {
          lastItemHover = newLocalListItem;
        }
      })
      if (lastItemHover.id !== draggingItem.id) {
        if (lastItemHover.type === 'persona_experience_group') {
          if (lastItemHover.area === 'middle') {
            if (draggingItem.type === 'experiences_persona') {
              await experienceApi.moveExperienceToGroupInPersona(
                personaId,
                draggingItem.data.id,
                lastItemHover.data.id
              );
              await Promise.all([
                refreshProductList(),
                refreshExperienceList()
              ])
            }
          } else {
            if (lastItemHover.area === 'border-top') {
              let newOrder;
              if (draggingItem.order < lastItemHover.order) {
                newOrder = lastItemHover.order - 1;
              } else {
                newOrder = lastItemHover.order;
              }
              experienceApi.updateOrderInPersona(
                draggingItem.type,
                draggingItem.type  === 'experiences_persona' ? draggingItem.data.experiences_persona_id : draggingItem.data.id,
                newOrder
              );
              updateOrderLocally(draggingItem, newOrder);
            } else if (lastItemHover.area === 'border-bottom') {
              let newOrder;
              if (draggingItem.order > lastItemHover.order) {
                newOrder = lastItemHover.order + 1;
              } else {
                newOrder = lastItemHover.order;
              }
              experienceApi.updateOrderInPersona(
                draggingItem.type,
                draggingItem.type  === 'experiences_persona' ? draggingItem.data.experiences_persona_id : draggingItem.data.id,
                newOrder
              );
              updateOrderLocally(draggingItem, newOrder);
            }
          }
        } else if (lastItemHover.type === 'experiences_persona') {
          if (lastItemHover.area === 'border-top') {
            let newOrder;
            if (draggingItem.order < lastItemHover.order) {
              newOrder = lastItemHover.order - 1;
            } else {
              newOrder = lastItemHover.order;
            }
            experienceApi.updateOrderInPersona(
              draggingItem.type,
              draggingItem.type  === 'experiences_persona' ? draggingItem.data.experiences_persona_id : draggingItem.data.id,
              newOrder
            );
            updateOrderLocally(draggingItem, newOrder);
          } else if (lastItemHover.area === 'border-bottom') {
            let newOrder;
            if (draggingItem.order > lastItemHover.order) {
              newOrder = lastItemHover.order + 1;
            } else {
              newOrder = lastItemHover.order;
            }
            experienceApi.updateOrderInPersona(
              draggingItem.type,
              draggingItem.type  === 'experiences_persona' ? draggingItem.data.experiences_persona_id : draggingItem.data.id,
              newOrder
            );
            updateOrderLocally(draggingItem, newOrder);
          }
        }
      } else {
        newLocalList.forEach(newLocalListItem => {
          newLocalListItem.hover = false;
        })
        newLocalList.forEach(newLocalListItem => {
          newLocalListItem.area = null;
        })
        newLocalList = newLocalList.sort(compare);
        setLocalList(newLocalList);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onDragEnd = async () => {
    try {
      await orderAndMoveItems();
      setDraggingItem(null);
    } catch (error) {
      console.error(error)
    }
  }

  const goBack = async () => {
    try {
      setGoingBack(true);
      await refreshExperienceList(),
      setGoingBack(false);
      navigate(`/persona/${persona.id}`);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    let filteredExperiences, filteredExperienceGroups;
    if (experienceList && experienceGroupList) {
      if (group) {
        filteredExperiences = experienceList
          .filter(experienceListItem => experienceListItem.persona_experience_group_id === group.id);
        filteredExperienceGroups = experienceGroupList.filter(experienceGroupListItem => experienceGroupListItem.persona_experience_group_id === group.id);
      } else {
        filteredExperiences = experienceList.filter(experienceListItem => !experienceListItem.persona_experience_group_id);
        filteredExperienceGroups = experienceGroupList.filter(experienceGroupListItem => !experienceGroupListItem.persona_experience_group_id);
      }
      let newLocalList = filteredExperiences.map(experienceListItem => ({
        type: 'experiences_persona',
        data: experienceListItem,
        order: experienceListItem.order,
        id: 'persona_experience_' + experienceListItem.experiences_persona_id,
        hover: false,
        area: null,
      }));
      newLocalList = newLocalList.concat(filteredExperienceGroups.map(groupListItem => ({
        type: 'persona_experience_group',
        data: groupListItem,
        order: groupListItem.order,
        id: 'persona_experience_group-' + groupListItem.id,
        hover: false,
        area: null,
      })))
      newLocalList = newLocalList.sort(compare);
      setLocalList(newLocalList);
    }
  }, [experienceList, experienceGroupList, group]);

  useEffect(() => {
    if (experienceGroupList) {
      if (groupId) {
        const newGroup = (experienceGroupList.filter(g => g.id === parseInt(groupId)))[0];
        setGroup(newGroup);
      } else {
        setGroup(null);
      }
    }
  }, [groupId, experienceGroupList]);

  const getMenu = () => {
    if (!group) {
      return (
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                icon={<MdAdd />}
                variant='blueOutline'
                style={{
                  'opacity': !experienceList ? '0' : '1',
                  'pointerEvents': !experienceList ? 'none' : 'inherit'
                }}
              />
              <MenuList>
                <MenuItem onClick={modalAddExperience.onOpen}>
                  <Icon
                    as={MdOutlineRoute}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text>Add Experience</Text>
                </MenuItem>
                <MenuItem onClick={modalAddExperienceGroup.onOpen} >
                  <Icon
                    as={MdOutlineLayers}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text>New Experience Group</Text>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      )
    } else {
      return (
        <IconButton
          icon={<MdAdd />}
          variant='blueOutline'
          onClick={modalAddExperience.onOpen}
        />
      )
    }
  }

  return (
    <>
      <Flex
        border="1px"
        borderRadius="2px"
        borderColor="divider.grey"
        flexDirection={"column"}
        height={((localList && localList.length > 0) ? 'fit-content' : '100%')}
      >
        <Flex
          alignItems='center'
          py={2}
          px={3}
          backgroundColor="white"
          justifyContent={"space-between"}
          h={"54px"}
        >
          {!group &&
            <>
              <Flex
                alignItems={"center"}
              >
                <Flex mr={3} alignItems='center' >
                  <Icon
                    as={MdOutlineRoute}
                    boxSize="18"
                    mr={2}
                  />
                  <Heading variant="secondary" >Experiences</Heading>
                </Flex>
                {rights.can_update && getMenu()}
              </Flex>
              <PersonaInsightTags personaId={persona.id} />
            </>
          }
          {group &&
            <>
              <Flex
                alignItems={"center"}
              >
                <Flex mr={3} alignItems='center' >
                  {goingBack && (
                    <Flex mr={3}>
                      <Spinner />
                    </Flex>
                  )}
                  {!goingBack && (
                    <Icon
                      as={MdArrowBack}
                      boxSize="18px"
                      mr={3}
                      cursor={"pointer"}
                      onClick={goBack}
                    />
                  )}
                  <Icon
                    as={MdOutlineLayers}
                    color="text.medium.blue"
                    boxSize="18"
                    mr={2}
                  />
                  <InlineFormWrapper
                    width={"100%"}
                    canEdit={rights.can_update}
                    value={<Text fontSize={"18px"} color="text.medium.blue">{group.name}</Text>}
                  >
                    {(close) => (
                      <Flex pr={3}>
                        <ExperienceGroupInPersonaNameForm
                          groupId={group.id}
                          group={group}
                          onSubmitted={close}
                          onCancel={close}
                        />
                      </Flex>
                    )}
                  </InlineFormWrapper>

                </Flex>
                {rights.can_update && getMenu()}
              </Flex>
              <InsightTags
                nbPostiveFeedback={group.positive_insight_count}
                nbNegativeFeedback={group.negative_insight_count}
                nbImprovementFeedback={group.improvement_insight_count}
                nbBehavior={group.behavior_insight_count}
                nbMotivation={group.motivation_insight_count}
              />
            </>
          }
        </Flex>
        <Divider />
        <Flex
          flexDirection={"column"}
          flex="1"
          overflow={"auto"}
        >
          {isFetchingExperiences && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
          )}
          {(!isFetchingExperiences && (!localList || localList.length === 0)) && (
            <Flex
              p={3}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              gap={2}
              flex="1"
              background={'white'}
            >
              <Text
                color="text.medium.grey"
                fontSize={"12px"}
              >No experience so far</Text>
              {rights.can_update && getMenu()}
            </Flex>
          )}
            <Flex
              flexDirection={"column"}
              overflow={"auto"}
            >
              {(!isFetchingExperiences && localList) && localList.map((item, i) => {
                return (
                  <Flex
                    flexDirection={"column"}
                    key={item.id}
                    onDragStart={() => {
                      setDraggingItem(item);
                    }}
                    onDragOver={(e) => {
                      const box = e.target.getBoundingClientRect();
                      const newLocalList = Array.from(localList);
                      newLocalList.forEach((newLocalListItem) => {
                        if (newLocalListItem.id === item.id) {
                          newLocalListItem.hover = true;
                          if (newLocalListItem.type === 'experiences_persona') {
                            if (e.clientY <= box.top + (box.height * 0.5)) {
                              newLocalListItem.area = 'border-top';
                            } else {
                              newLocalListItem.area = 'border-bottom';
                            }
                          } else if (newLocalListItem.type === 'persona_experience_group') {
                            if (e.clientY <= box.top + (box.height * 0.333333)) {
                              newLocalListItem.area = 'border-top';
                            } else if (e.clientY >= box.top + (box.height * 0.6666)) {
                              newLocalListItem.area = 'border-bottom';
                            } else {
                              newLocalListItem.area = 'middle';
                            }
                          }
                        } else {
                          newLocalListItem.hover = false;
                          newLocalListItem.area = null;
                        }
                      })
                      setLocalList(newLocalList);
                    }}
                    onDragEnd={onDragEnd}
                    draggable="true"
                    onClick={() => {
                      if (item.type === 'persona_experience_group') {
                        navigate(`/persona/${persona.id}?group-id=${item.data.id}`);
                      } else {
                        navigate(`/experiences/${item.data.id}`);
                      }
                    }}
                    cursor={"pointer"}
                  //cursor={rights.can_update ? "grab" : "default"}
                  >
                    {(i > 0 && !item.hover) && <Divider />}
                    {(item.hover && item.area === 'border-top') && <Divider bg={"fill.blue"} h={"2px"} />}
                    {item.type === 'experiences_persona' &&
                      <ExperiencesInPersonaListItem
                        personaId={personaId}
                        experience={item.data}
                      />
                    }
                    {item.type === 'persona_experience_group' &&
                      <ExperienceGroupInPersonaListItem
                        dragHover={(item.hover && item.area === 'middle' && (draggingItem && draggingItem.type === 'experiences_persona'))}
                        personaId={personaId}
                        experienceGroup={item.data}
                      />
                    }
                    {(item.hover && item.area === 'border-bottom') && <Divider bg={"fill.blue"} h={"2px"} />}
                  </Flex>
                )
              })}
            </Flex>
        </Flex>
      </Flex >
      <Modal isOpen={modalAddExperience.isOpen} onClose={modalAddExperience.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Add Experience</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <AddExperienceToPersonaForm
                personaId={personaId}
                groupId={group ? group.id : null}
                onCreateNew={(e) => {
                  setObjectiveDefault(e);
                  modalAddExperience.onClose();
                  modalCreateExperience.onOpen();
                }}
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  Promise.all([
                    refreshExperienceList(),
                    refreshProductList()
                  ])
                  modalAddExperience.onClose();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="blueOutline"
              onClick={modalAddExperience.onClose}
            >Cancel</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalCreateExperience.isOpen} onClose={modalCreateExperience.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack />}
              aria-label='New'
              mr={2}
              onClick={() => {
                modalCreateExperience.onClose();
                modalAddExperience.onOpen();
              }}
            />
            New Experience</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <NewExperienceInPersonaForm
                objectiveDefault={objectiveDefault}
                personaId={personaId}
                groupId={group ? group.id : null}
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  modalCreateExperience.onClose();
                  Promise.all([
                    refreshExperienceList(),
                    refreshProductList()
                  ])
                }}
                onValidityChange={setIsValid}
                ref={ref2}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={() => {
                modalCreateExperience.onClose();
                modalAddExperience.onOpen();
              }}
            >Cancel</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref2.current.click();
                modalCreateExperience.onClose();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalAddExperienceGroup.isOpen} onClose={modalAddExperienceGroup.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>New Experience Group</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <NewExperienceGroupInPersonaForm
                personaId={personaId}
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  modalAddExperienceGroup.onClose();
                  Promise.all([
                    refreshExperienceList(),
                    refreshProductList()
                  ])
                }}
                onValidityChange={setIsValid}
                ref={ref3}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalAddExperienceGroup.onClose}
            >Cancel</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref3.current.click();
                modalAddExperienceGroup.onClose();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

