import { Button, Flex, Icon, IconButton, Skeleton, Text, Tooltip, useMultiStyleConfig, useTab } from '@chakra-ui/react';
import InsightProvider from '@/insight/lib/insightProvider';
import ExperienceProvider from '@/experiences/lib/experienceProvider';
import InsightPicto from '@/insight/components/insightPicto';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import InsightCardListItem from './insightCardListItem';
import { forwardRef, useEffect, useState } from 'react';
import { MdOutlineChevronRight, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

export default function InsightTab(props) {

  const {
    lists,
    onSetAsOutdated
  } = props

  const [data, setData] = useState();

  useEffect(() => {
    if (lists) {
      const newData = [];
      if (lists[insightTypeDictionnary.negative] && lists[insightTypeDictionnary.negative].length > 0) {
        newData.push({
          type: insightTypeDictionnary.negative,
          list: lists[insightTypeDictionnary.negative],
          title: 'Negative Feedback',
        })
      }
      if (lists[insightTypeDictionnary.improvement] && lists[insightTypeDictionnary.improvement].length > 0) {
        newData.push({
          type: insightTypeDictionnary.improvement,
          list: lists[insightTypeDictionnary.improvement],
          title: 'Improvement Feedback',
        })
      }
      if (lists[insightTypeDictionnary.positive] && lists[insightTypeDictionnary.positive].length > 0) {
        newData.push({
          type: insightTypeDictionnary.positive,
          list: lists[insightTypeDictionnary.positive],
          title: 'Positive Feedback',
        })
      }
      if (lists[insightTypeDictionnary.behavior] && lists[insightTypeDictionnary.behavior].length > 0) {
        newData.push({
          type: insightTypeDictionnary.behavior,
          list: lists[insightTypeDictionnary.behavior],
          title: 'Behaviors',
        })
      }
      if (lists[insightTypeDictionnary.motivation] && lists[insightTypeDictionnary.motivation].length > 0) {
        newData.push({
          type: insightTypeDictionnary.motivation,
          list: lists[insightTypeDictionnary.motivation],
          title: 'Motivations',
        })
      }
      setData(newData);
    }
  }, [lists])

  return (
    <>
      <Tabs variant='unstyled'>
        <TabList gap={2} >
          {data && data.map((d,i) => {
            return <CustomTab data={d} key={i} />
          })}
        </TabList>
        <TabPanels >
          {data && data.map((d, i) => {
            return (
              <TabPanel key={i}>
                <Flex
                  gap={2}
                  overflowX={"auto"}
                  width={"100%"}
                  pt={2}
                  pb={3}
                >
                  {(d.list && d.list.length > 0)
                    && d.list.map((n) => (
                      <InsightProvider
                        insightId={n.id}
                        key={n.id}
                        originalInsight={n}
                        fetchPersonaList="always"
                        fetchProductList="always"
                      >
                        <ExperienceProvider experienceId={n.experience_id}>
                          <InsightCardListItem onSetAsOutdated={() => {
                            if (onSetAsOutdated) {
                              onSetAsOutdated();
                            }
                          }} />
                        </ExperienceProvider>
                      </InsightProvider>
                    ))
                  }
                </Flex>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </>
  );
}

const CustomTab = forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref })
  const isSelected = !!tabProps['aria-selected']
  const styles = useMultiStyleConfig('Tabs', tabProps)
  const [color, setColor] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [bdColor, setBdColor] = useState('');
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if ((isSelected || hover) && props.data) {
      if (props.data.type === insightTypeDictionnary.negative) {
        setBgColor('background.red')
        setColor('text.medium.red')
        setBdColor('divider.red')
      } else if (props.data.type === insightTypeDictionnary.improvement) {
        setBgColor('background.orange')
        setColor('text.medium.orange')
        setBdColor('divider.orange')
      } else if (props.data.type === insightTypeDictionnary.positive) {
        setBgColor('background.green')
        setColor('text.medium.green')
        setBdColor('divider.green')
      } else if (props.data.type === insightTypeDictionnary.behavior) {
        setBgColor('background.component.grey')
        setColor('text.medium.grey')
        setBdColor('divider.grey')
      } else if (props.data.type === insightTypeDictionnary.motivation) {
        setBgColor('background.purple')
        setColor('text.medium.purple')
        setBdColor('divider.purple')
      }
    } else {
      setBgColor('white')
      setBdColor('divider.grey')
      if (props.data.type === insightTypeDictionnary.negative) {
        setColor('text.medium.red')
      } else if (props.data.type === insightTypeDictionnary.improvement) {
        setColor('text.medium.orange')
      } else if (props.data.type === insightTypeDictionnary.positive) {
        setColor('text.medium.green')
      } else if (props.data.type === insightTypeDictionnary.behavior) {
        setColor('text.medium.grey')
      } else if (props.data.type === insightTypeDictionnary.motivation) {
        setColor('text.medium.purple')
      }
    }
  }, [isSelected, props.data, hover])

  return (
    <Button __css={styles.tab} {...tabProps} px={0} borderBottom={"none"}>
      <Tooltip label={props.data.title}>
        <Flex
          alignItems={"center"}
          gap={1}
          py={1}
          backgroundColor={bgColor}
          px={2}
          borderRadius={"20px"}
          border="1px solid"
          borderColor={bdColor}
          color={color}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        >
          <InsightPicto size={20} type={props.data.type} />
          <Text >{props.data.list.length}</Text>
          {isSelected && <Text >{props.data.title}</Text>}
        </Flex>
      </Tooltip>
    </Button>
  )
})