
import React, {useEffect, useState} from 'react';
import SplashScreenContext from './splashScreenContext';
import AppLoading from '../components/appLoading';
import { Flex } from '@chakra-ui/react';


export default function SplashScreenProvider({children}) {


  const [queue, setQueue] = useState();
  const [fullfilled, setFullfilled] = useState(false);
  
  const displayWhile = (key, condition) => {
    let newQueue = []; 
    if(queue) {
      newQueue = Array.from(queue);
    }
    const alreadyExists = newQueue.some(obj => obj.key === key);
    if(alreadyExists) {
      newQueue.forEach((item, i) => {
        if(item.key === key) {
          newQueue[i].value = condition;
        }
      });
    } else {
      newQueue.push({key, value: condition})
    }
    setQueue(newQueue);
  }

  useEffect(() => {
    if(queue && queue.length > 0) {
      const newFullfilled = queue
      .every(obj => typeof obj['value'] === 'boolean' && obj['value'] === false);
      setFullfilled(newFullfilled)
    }
  }, [queue])

  return (
    <SplashScreenContext.Provider value={{
      displayWhile: displayWhile
    }}>
       <>{children}</>
      { (queue && !fullfilled) &&
        <Flex
          position={"fixed"}
          left="0"
          top="0"
          width="100vw"
          height="100vh"
          zIndex={"10000000000000"}
          backgroundColor={"background.grey"}
          >
          <AppLoading />
        </Flex>
      }
    </SplashScreenContext.Provider>
  );
}




