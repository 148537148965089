'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Flex} from '@chakra-ui/layout';
import {Button, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdAdd, MdCheck, MdClose, MdDelete} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import { OrganisationContext } from '../lib/organisationContext';
import useOrganisationApi from '../lib/useOrganisationApi';

const OrganisationDomainForm = forwardRef((props, ref) => {

  const {
    organisation,
    members,
    organisationId,
    refresh,
  } = useContext(OrganisationContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  });

  const organisationApi = useOrganisationApi();
  const [adminDomain, setAdminDomain] = useState();
  const [loadinAdminDomain, setLoadingAdminDomain] = useState();
  
  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      if(organisation.id && data.domains) {
        await organisationApi.updateDomains(
          data.domains,
          organisation.id
        )
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAdminDomain = async () => {
    try {
      setLoadingAdminDomain(true)
      const newAdminDomain = await organisationApi.fetchAdminDomain(organisationId);
      setAdminDomain(newAdminDomain);
      setLoadingAdminDomain(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(organisation.domains) {
      setValue('domains', organisation.domains);
      fetchAdminDomain();
    }
  }, [organisation]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
    {(organisation && adminDomain) && (
      <Flex alignSelf={"stretch"}  w={"100%"}>
      <form style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)} >
        <Flex
          alignItems={'stretch'}
          flexDirection={'column'}
          gap={2}
        >
          { fields.map((item, i) => (
            <Flex
              key={i}
              gap={2}
              alignItems={"center"}
              >
              <Input
              placeholder='domain'
              {...register(`domains[${i}]`, {
                pattern: {
                  value: /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
                  message: "Invalid domain name"
                }
              })}
              defaultValue={item}
              color={((organisation.domains[i]) !== adminDomain) ? 'text.dark.grey' : 'text.medium.grey'}
              pointerEvents={((organisation.domains[i]) !== adminDomain) ? 'inherit' : 'none'}
            />
            {(organisation.domains[i]) !== adminDomain && 
              <IconButton
              variant="redOutline"
              icon={<MdDelete />}
              onClick={() => {remove(i)}}
              mr={1}
              />
            }
            </Flex>
          ))}
          <Button
            type="button"
            alignSelf={"flex-start"}
            variant="greyTransparent"
            leftIcon={<MdAdd/>}
            onClick={() => append('')}
          >Add Domain</Button>
          <Flex display={"flex"} gap={2}>
            {onCancel && (
              <IconButton
                variant="greyOutline"
                icon={<MdClose />}
                onClick={onCancel}
              />
            )}
            <IconButton
              style={{
                display: hideSave ? 'none' : 'inherit',
              }}
              ref={ref}
              icon={<MdCheck />}
              type={'submit'}
              aria-label='check'
              variant='greyFill'
              isLoading={isSubmitting}
              isDisabled={!isValid}
            />
          </Flex>
        </Flex>
      </form>
    </Flex>
    )}
    </>
  );
});

export default OrganisationDomainForm;


