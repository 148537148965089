import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Text, Flex, Spacer, Divider } from '@chakra-ui/layout';
import { Button, Icon, useDisclosure, useToast } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import ProfilePseudoForm from './profilePseudoForm';
import ProfileThumbnail from './profileThumbnail';
import { MdEdit } from 'react-icons/md';
import ProfileThumbnailForm from './profileThumbnailForm';
import UpdatePasswordForm from '../../auth/components/updatePasswordForm';
import { ProfileContext } from '../lib/profileContext';
import ProfileEmail from './profileEmail';
import ProfilePlan from './profilePlan';

export default function ProfileDetail() {

  const {
    profile,
    refresh,
    profileSystem
  } = useContext(ProfileContext)

  const modalEditPseudo = useDisclosure();
  const modalEditThumbnail = useDisclosure();
  const modalEditPassword = useDisclosure();
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const toast = useToast();

  return (
    <>
      <Flex
        border="1px"
        borderColor="divider.grey"
        backgroundColor="white"
        flex={1}
        flexDirection={"column"}
      >
        <Flex
          p={3}
          alignItems={'center'}
          justifyContent={'center'}
          >
          <Box
            mr={3}
            position={'relative'}
          >
            {profile && (
              <>
                <Flex
                  onClick={modalEditThumbnail.onOpen}
                  cursor={"pointer"}
                >
                  <ProfileThumbnail
                    size={100}
                    hasThumbnail={profile.has_thumbnail}
                    pseudo={profile.pseudo}
                  />
                </Flex>
                <Flex
                  border="1px"
                  borderColor="divider.grey"
                  borderRadius="50%"
                  backgroundColor="white"
                  alignItems="center"
                  justifyContent="center"
                  w={30}
                  h={30}
                  cursor={"pointer"}
                  position={'absolute'}
                  bottom={'0'}
                  right={'0'}
                  onClick={modalEditThumbnail.onOpen}
                >
                  <Icon
                    as={MdEdit}
                    color={'text.medium.grey'}
                    boxSize={'15px'}
                  />
                </Flex>
              </>
            )}
          </Box>
        </Flex>
        <Divider />
        {(profileSystem && profileSystem.role !== 'admin')  && 
          <ProfilePlan />
        }
        <Divider />
        <ProfileEmail />
        <Divider />
        <Box px={3} py={3}>
          <Text textStyle="subtitle">Pseudo</Text>
          <Flex alignItems={'center'}>
            <Text>{profile?.pseudo}</Text>
            <Spacer />
            <Button
              variant={'greyOutline'}
              onClick={modalEditPseudo.onOpen}
            >Edit</Button>
          </Flex>
        </Box>
        <Divider />
        <Box px={3} py={3}>
          <Text textStyle="subtitle" mb={2}>Password</Text>
          <Flex alignItems={'center'}>
            <Button
              variant={'greyOutline'}
              onClick={modalEditPassword.onOpen}
            >Update Password</Button>
          </Flex>
        </Box>
      </Flex>
      <Modal
        isOpen={modalEditPseudo.isOpen}
        onClose={modalEditPseudo.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Pseudo</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {profile && (
                <ProfilePseudoForm
                  hideSave={true}
                  profileId={profile.id}
                  profilePseudo={profile.pseudo}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    modalEditPseudo.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='greyOutline'
              onClick={modalEditPseudo.onClose}
            >Cancel</Button>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditThumbnail.isOpen} onClose={modalEditThumbnail.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Thumbnail</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {profile && (
                <ProfileThumbnailForm
                  hideSave={true}
                  profileId={profile.id}
                  thumbnail={profile.thumbnail}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    modalEditThumbnail.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref2}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='greyOutline'
              onClick={modalEditThumbnail.onClose}
            >Close</Button>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditPassword.isOpen} onClose={modalEditPassword.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Update Password</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {profile && (
                <UpdatePasswordForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    modalEditPassword.onClose()
                    toast({
                      position: 'bottom-right',
                      description: "Password successfuly updated",
                      duration: 1000,
                      isClosable: true,
                      status: 'success'
                    });
                  }}
                  onError={() => {
                    setIsAdding(false);
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref3}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='greyOutline'
              onClick={modalEditPassword.onClose}
            >Close</Button>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref3.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}