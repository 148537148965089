import {supabase} from '../../supabase.client';
import roleDictionnary from '@/lib/roleDictionnary';
import memberStatusDictionnary from '../../lib/memberStatusDictionnary';

const useProductsApi = () => {

  const searchProducts = async (searchTerm) => {
    try {
      let { data, error} = await supabase.rpc('search_product', {
        arg_search_term: searchTerm
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductListByUserId = async (userId) => {
    try {
      let { data, error} = await supabase.rpc('get_product_list_by_user_id', {
        arg_user_id: userId
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductsByInsightId = async (insightId) => {
    try {
      const result = await supabase.from('insight')
        .select(`
          products(*)
        `)
        .eq('id', insightId)
        .single();
      return result.data.products;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductListNotInExperience = async (experienceId) => {
    try {
      let { data, error } = await supabase.rpc('get_products_not_in_experience',
        { arg_experience_id: experienceId});
        if(error) {
          throw error
        } else {
          return data;
        }
    } catch (e) {
      console.error(e)
    }
  };

  const fetchProductsByPersonaId = async (personaId) => {
    try {
      const {data, error} = await supabase.rpc('get_products_by_persona_id', {
        arg_persona_id : personaId
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductsWithExperienceByPersonaId = async (personaId) => {
    try {
      let { data, error } = await supabase.rpc('get_products_with_experiences_by_persona_id',
        {arg_persona_id: personaId});
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };


  const fetchProductsByExperienceId = async (experienceId) => {
    try {
      const {data, error} = await supabase.rpc('get_products_by_experience_id', {
        arg_experience_id: experienceId
      })
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };
  
  const fetchProductsByProductExperienceGroupId = async (groupId) => {
    try {
      const {data, error} = await supabase.rpc('get_products_by_product_experience_group', {
        arg_group_id: groupId,
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductsByPersonaExperienceGroupId = async (groupId) => {
    try {
      const {data, error} = await supabase.rpc('get_product_list_by_persona_experience_group', {
        arg_group_id: groupId,
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductNotInPersonaForUser = async (personaId, userId) => {
    try {
      const {data, error} = await supabase.rpc('get_products_not_in_persona_for_user', {
        arg_persona_id : personaId,
        arg_user_id : userId
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProduct = async (id) => {
    try {
      const { data } = await supabase.from("products")
        .select()
        .eq('id', id)
        .limit(1)
        .single();
      return  data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMemberRole = async (productId, profileId) => {
    try {
      let { data, error} = await supabase.rpc('fetch_user_role_in_product', {
        arg_product_id: productId,
        arg_user_id: profileId
      });
      if(error) {
        throw error
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchProductTypeByProductId = async (productId) => {
    try {
      let {data, error} = await supabase.from("products")
      .select('*, product_type(id, label, parent)')
      .eq('id', productId)
      .limit(1)
      .single();
      if(data && data.product_type) {
        if(Array.isArray(data.product_type)) {
          return data.product_type;
        } else {
          return [data.product_type]
        }
      } else if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchProductTypeList = async (parent = null) => {
    try {
      let result;
      if(!parent) {
        result = await supabase.from("product_type").select()
        .is('parent', null)
      } else {
        result = await supabase.from("product_type").select()
        .eq('parent', parent);
      }
      if(result.data) {
        return result.data
      } else {
        return result.error
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchProductListByOrganisation = async (organisationId) => {
    try {
      let { data, error} = await supabase.rpc('get_product_list_by_organisation_id', {
        arg_organisation_id: organisationId
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUserRights = async (productId, profileId) => {
    try {
      let { data, error} = await supabase.rpc('fetch_user_rights_on_product', {
        arg_product_id: productId,
        arg_user_id: profileId
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchMemberStatus = async (productId, profileId) => {
      try {
        let { data, error} = await supabase.rpc('fetch_user_status_in_product', {
          arg_product_id: productId,
          arg_user_id: profileId
        });
        if(error) {
          throw error
        } else {
          return data
        }
      } catch (error) {
        console.error(error);
      }
  }

  const insertProduct = async (name) => {
    try {
      const {data, error} = await supabase
        .from('products')
        .insert({name})
        .select()
        .single();
        
        if(data) {
          return data; 
        } else if (error) {
          throw error
        }
    } catch (e) {
      console.error(e);
    }
  };

  const updateName = async (name, productId) => {
    try {
      const {data, error} =  await supabase.from('products')
        .update({name})
        .eq('id',productId)
        .select();
      if(error) {
        throw error;
      } else {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateFeatures = async (features, productId) => {
    try {
      const {data, error} =  await supabase.from('products')
        .update({features})
        .eq('id',productId)
        .select();
      if(error) {
        throw error;
      } else {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateValueProposition = async (valueProposition, productId) => {
    try {
      const result =  await supabase.from('products')
        .update({value_proposition: valueProposition})
        .eq('id', productId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateDescription = async (description, productId) => {
    try {
      const result =  await supabase.from('products')
        .update({description})
        .eq('id', productId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateAccessLevel = async (accessLevel, productId) => {
    try {
      const result =  await supabase.from('products')
        .update({access_level: accessLevel})
        .eq('id', productId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const revokeAccess = async (productId, profileId) => {
    try {
      let {error} = await supabase.from("product_members")
        .delete()
        .eq('product_id', productId)
        .eq('profile_id', profileId);
        if(error) {
          throw error;
        }
    } catch (error) {
      console.error(error);
    }
  }

  const declineRequest = async (productId, profileId) => {
    try {
      let {error } = await supabase.rpc('product_decline_request', {
        arg_product_id: productId,
        arg_profile_id: profileId
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const approveRequest = async (productId, profileId) => {
    try {
      let {error } = await supabase.rpc('product_approve_request', {
        arg_product_id: productId,
        arg_profile_id: profileId
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }


  const updateRole = async (productId, profileId, role) => {
    try {
      let {error} = await supabase.rpc('product_update_role', {
        arg_product_id: productId,
        arg_profile_id: profileId,
        arg_role: role
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const deleteProduct = async (id) => {
    try {
      let result = await supabase.rpc('delete_product',
      { arg_product_id: id});
      if(result.error) {
        throw(result.error)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadLogo = async (files, productId, format = 'webp') => {
    try {
      await deleteLogo(productId);
      const promises = [
        await supabase.storage
        .from('products')
        .upload(`logo/${productId}x64.${format}`, files[64], { upsert: true}),
        await supabase.storage
        .from('products')
        .upload(`logo/${productId}x128.${format}`, files[128], { upsert: true}),
        await supabase.storage
        .from('products')
        .upload(`logo/${productId}x256.${format}`, files[256], { upsert: true})
      ];
      await Promise.all(promises);
      await supabase.from('products')
      .update({
        has_logo: true,
        logo_updated_at: new Date().toISOString(),
        logo_format: format
      })
      .eq('id', productId)
      .select();
    } catch (e) {
      console.error(e);
    }
  };


  const deleteLogo = async (productId) => {
    try {
      const productLogoResult = await supabase.from('products')
      .select('logo_format')
      .eq('id', productId)
      .single();
      if(productLogoResult.data) {
        await supabase
        .storage
        .from('products')
        .remove([
          `logo/${productId}x64.${productLogoResult.data.format}`, 
          `logo/${productId}x128.${productLogoResult.data.format}`,
          `logo/${productId}x256.${productLogoResult.data.format}`
       ]);
      await supabase.from('products')
        .update({has_logo: false})
        .eq('id',productId)
        .select();
      } else {
        throw productLogoResult.error
      }
    } catch (e) {
      console.error(e);
    }
  };


  const downloadLogo = async (productId, size = 128) => {
    try {
      const productResult = await supabase.from('products')
      .select()
      .eq('id', productId)
      .select()
      .single();
      if(productResult) {
        const {data, error} = await supabase.storage
        .from('products')
        .download(`/logo/${productId}x${size}.${productResult.data.logo_format}?updated_at=${productResult.data.logo_updated_at}`);
        if(data) {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(data);
        } else {
          throw error;
        }
      } else {
        throw productResult.error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadLogoWithText = async (files, productId, format = 'webp') => {
    try {
      await deleteLogoWithText(productId);
      const promises = [
        await supabase.storage
        .from('products')
        .upload(`logo_with_text/${productId}x64.${format}`, files[64], { upsert: true}),
        await supabase.storage
          .from('products')
          .upload(`logo_with_text/${productId}x128.${format}`, files[128], { upsert: true}),
        await supabase.storage
          .from('products')
          .upload(`logo_with_text/${productId}x256.${format}`, files[256], { upsert: true})
      ]
      await Promise.all(promises);

      await supabase.from('products')
      .update({
        has_logo_with_text: true,
        logo_with_text_updated_at: new Date().toISOString(),
        logo_with_text_format: format
      })
      .eq('id', productId)
      .select();

    } catch (e) {
      console.error(e);
    }
  };


  const deleteLogoWithText = async (productId) => {
    try {
      const productThumbnailResult = await supabase.from('products')
      .select('logo_with_text_format')
      .eq('id', productId)
      .single();
      if(productThumbnailResult.data) {
        await supabase
        .storage
        .from('products')
        .remove([
          `logo_with_text/${productId}x64.${productThumbnailResult.data.logo_with_text_format}`,
          `logo_with_text/${productId}x128.${productThumbnailResult.data.logo_with_text_format}`,
          `logo_with_text/${productId}x256.${productThumbnailResult.data.logo_with_text_format}`
        ]);
        await supabase.from('products')
          .update({has_logo_with_text: false})
          .eq('id', productId)
          .select();
      } else {
        throw productThumbnailResult.error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const downloadLogoWithText = async (productId, size = 128) => {
    try {
      const productResult = await supabase.from('products')
        .select()
        .eq('id', productId)
        .select()
        .single();
      if(productResult.data) {
        const {data, error} = await supabase.storage
        .from('products')
        .download(`/logo_with_text/${productId}x${size}.${productResult.data.logo_with_text_format}?updated_at=${productResult.data.logo_with_text_updated_at}`);
        if(data) {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(data);
        } else {
          throw error;
        }
      } else {
        throw productResult.error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadScreenshots = async (files, productId) => {
    try {
      const promises = files.map((file, i) => {
        return supabase.storage
          .from('product_screenshots')
          .upload(`/${productId}/${i + 1}.png`, file, {upsert: true, contentType: "image/png",});
      });
      await Promise.all([promises]);
      await supabase
        .from('products')
        .update({
          'number_of_screenshots': files.length
        })
        .eq('id', productId);
    } catch (e) {
      console.error(e);
    }
  };


  const downloadScreenshots = async (productId, numberOfScreenshot = 1) => {
    try {
      let result = await supabase.storage
        .from('product_screenshots')
        .download(`/${productId}/0.png`);
      const promises = [];
      for (let i = 0; i < numberOfScreenshot; i++) {
        promises.push(supabase.storage
          .from('product_screenshots')
          .download(`/${productId}/${i + 1}.png`)
        )
      }
      const results = await Promise.all(promises);
      const urlCreator = window.URL || window.webkitURL;
      return results.map((r) => urlCreator.createObjectURL(r.data))
    } catch (e) {
      console.error(e);
    }
  };

  const getScreenshotUrls = async (productId, numberOfScreenshot = 1) => {
    try {
      const promises = [];
      for (let i = 0; i < numberOfScreenshot; i++) {
        promises.push(supabase.storage
          .from('product_screenshots')
          .getPublicUrl(`/${productId}/${i}.png`)
        )
      }
      const results = await Promise.all(promises);
      return results.map((r) => {
        return r.data.publicUrl
      })
    } catch (e) {
      console.error(e);
    }
  };

  const linkManyProductTypeToProduct = async (productId, productTypeIds) => {
    try {
      await supabase
      .from('product_product_type')
      .delete()
      .eq('product_id', productId);
      const productProductType = productTypeIds.map(p => ({
        product_id: productId,
        product_type_id: p
      }));
      await supabase
        .from('product_product_type')
        .insert(productProductType).select();
    } catch (e) {
      console.error(e)
    }
  };

  const linkManyPersonaToProduct = async (productId, personaIds) => {
    try {
      const productsPersona = personaIds.map(p => ({
        product_id: productId,
        persona_id: p
      }));
      await supabase
        .from('products_persona')
        .insert(productsPersona).select();
    } catch (e) {
      console.error(e)
    }
  };

  const linkManyExperiencesToProduct = async (productId, experienceIds, groupId = null) => {
    try {
      let experienceProduct = experienceIds.map(experienceId => ({
        experience_id: experienceId,
        product_id: productId
      }));
      if(groupId) {
        experienceProduct = experienceProduct.map(ep => ({...ep, product_experience_group_id: groupId}) )
      }
      await supabase
        .from('products_experiences')
        .insert(experienceProduct).select();
    } catch (e) {
      console.error(e)
    }
  };

  const unlinkExperienceFromProduct = async (experienceId, productId) => {
    try {
      await supabase
        .from('products_experiences')
        .delete()
        .eq('experience_id', experienceId)
        .eq('product_id', productId);
    } catch (e) {
      console.error(e);
    }
  };

  const unlinkPersonaFromProduct = async (personaId, productId) => {
    try {
      await supabase
        .from('products_persona')
        .delete()
        .eq('persona_id', personaId)
        .eq('product_id', productId);
    } catch (e) {
      console.error(e);
    }
  };

  const addMembers = async (emails, productId, role) => {
    try {
      const promises = emails.map((email) => {
        return new Promise(async (resolve, rejject) => {
          const result = await supabase.rpc('add_product_member',
          { arg_email: email,
            arg_product_id: productId,
            arg_role: role
          });
          resolve(result);
        })
      })
      const result = await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  }

  const requestEdit = async (productId, profileId) => {
    try {
      const {error} = await supabase.rpc('product_request_edit', { 
        arg_product_id : productId,
        arg_profile_id : profileId,
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const hasRequestedEdition = async (productId, profileId) => {
    try {
      let {data, error} = await supabase.from("product_members")
      .select()
      .eq('product_id', productId)
      .eq('profile_id', profileId)
      .eq('role', roleDictionnary.contributor)
      .eq('status', memberStatusDictionnary.requested)
      .limit(1)
      .maybeSingle();
      if(error) {
          throw error
      } else {
        return !!data
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    searchProducts,
    insertProduct,
    fetchProduct,
    fetchProductListByUserId,
    fetchProductsByInsightId,
    fetchProductListByOrganisation,
    fetchProductsByProductExperienceGroupId,
    fetchProductsByPersonaExperienceGroupId,
    fetchProductsByPersonaId,
    fetchProductNotInPersonaForUser,
    fetchProductListNotInExperience,
    fetchProductsByExperienceId,
    fetchProductsWithExperienceByPersonaId,
    fetchMemberRole,
    fetchUserRights,
    fetchMemberStatus,
    fetchProductTypeByProductId,
    fetchProductTypeList,
    revokeAccess,
    updateRole,
    updateName,
    updateFeatures,
    updateValueProposition,
    updateDescription,
    updateAccessLevel,
    deleteProduct,
    deleteLogo,
    uploadLogo,
    downloadLogo,
    uploadLogoWithText,
    downloadLogoWithText,
    deleteLogoWithText,
    downloadScreenshots,
    uploadScreenshots,
    getScreenshotUrls,
    linkManyProductTypeToProduct,
    linkManyPersonaToProduct,
    unlinkPersonaFromProduct,
    unlinkExperienceFromProduct,
    linkManyExperiencesToProduct,
    addMembers,
    requestEdit,
    approveRequest,
    declineRequest,
    hasRequestedEdition
  }
};

export default useProductsApi;
