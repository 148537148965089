import React from 'react';
import { supabase } from '../../supabase.client';


const useOrganisationApi = () => {
 
  const fetchOrganisationById = async (organisationId) => {
    try {
      let { data, error} = await supabase.rpc('get_organisation_by_id', {arg_organisation_id: organisationId});
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchMyOrganisation = async () => {
    try {
      let { data, error} = await supabase.rpc('get_my_organisation');
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchPotentialOrganisationOfUser = async (userId) => {
    try {
      let { data, error} = await supabase.rpc('get_potential_organisation_of_user', {
        arg_user_id: userId
      });
      if(error) {
        throw error
      } else if (data) {
        return (data.id === null ? null : data)
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchUserRights = async (organisationId, profile_id) => {
    try {
      let { data, error} = await supabase.rpc('get_user_rights_on_organisation', {
        arg_organisation_id: organisationId,
        arg_user_id: profile_id
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }


  const fetchMembers = async (organisationId) => {
    try {
      let { data, error} = await supabase.rpc('get_organisation_members', {
        arg_organisation_id: organisationId,
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchProfilesNotAdded = async (organisationId) => {
    try {
      let { data, error} = await supabase.rpc('get_profiles_not_added_in_organisation', {
        arg_organisation_id: organisationId,
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchAdminDomain = async (organisationId) => {
    try {
      let { data, error} = await supabase.rpc('get_organisation_admin_domain', {
        arg_organisation_id: organisationId,
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }


  const updateName = async (name, organisationId) => {
    try {
     const result =  await supabase.from('organisation')
        .update({name})
        .eq('id', organisationId)
        .select();
      if(result.error) {
        throw result.error;
      } else {
        return result.data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateRole = async (organisationId, profileId, role) => {
    try {
      let {error } = await supabase.rpc('update_role_of_organisation_member', {
        arg_organisation_id: organisationId,
        arg_profile_id: profileId,
        arg_role: role
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const updateDomains = async (domains, organisationId) => {
    try {
     const result =  await supabase.from('organisation')
        .update({domains})
        .eq('id', organisationId)
        .select();
      if(result.error) {
        throw result.error;
      } else {
        return result.data;
      }
    } catch (e) {
      console.error(e);
    }
  };


  const uploadLogo = async (files, organisationId, format = 'webp') => {
    try {
      const promises = [];
      promises.push(
        supabase.storage.from('organisation').upload(`/logos/${organisationId}/x64.${format}`, files[64], {
          upsert: true
        }),
        supabase.storage.from('organisation').upload(`/logos/${organisationId}/x128.${format}`, files[128], {
          upsert: true
        }),
        supabase.storage.from('organisation').upload(`/logos/${organisationId}/x256.${format}`, files[256], {
          upsert: true
        }),
      )
      const result = await Promise.all(promises);
      if(result) {
        const organisationResult = await supabase.from('organisation')
        .update({
          has_logo: true,
          logo_updated_at: new Date().toISOString(),
          logo_format: format
        })
        .eq('id', organisationId)
        if (organisationResult.error) {
          throw organisationResult.error;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteLogo = async (organisationId) => {
    try {
      const result = await supabase.from('organisation')
      .select(`logo_format`)
      .eq('id', organisationId)
      .single();
      if(result.error) {
        throw result.error;
      } else {
        const imageFormat = result.data.logo_format;
        await supabase
        .storage
        .from('organisation')
        .remove([
          `/logos/${organisationId}/x64.${imageFormat}`,
          `/logos/${organisationId}/x128.${imageFormat}`,
          `/logos/${organisationId}/x256.${imageFormat}`
       ]);
        await supabase.from('organisation')
        .update({ has_logo: false })
        .eq('id', organisationId)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const downloadLogo = async (organisationId, size = 128) => {
    try {
      const organisationResult = await supabase.from('organisation')
      .select()
      .eq('id', organisationId)
      .select()
      .single();
    if(organisationResult.data) {
      const updatedAt = organisationResult.data.logo_updated_at;
      const format = organisationResult.data.logo_format;
      const { data, error } = await supabase.storage
      .from('organisation')
      .download(`/logos/${organisationId}/x${size}.${format}?updated_at=${updatedAt}`);
      if(data) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(data);
      } else {
        throw error;
      }
    } else {
      throw organisationResult.error;
    }
    } catch (e) {
      console.error(e);
    }
  };

  const addMember = async (profileId, organisationId) => {
    try {
      let {data, error} = await supabase.rpc('add_member_to_organisation',  {
          arg_organisation_id: organisationId,
          arg_profile_id: profileId
        });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }

  const revokeAccess = async (profileId, organisationId) => {
    try {
      let {data, error} = await supabase.rpc('remove_member_from_organisation',  {
        arg_organisation_id: organisationId,
        arg_profile_id: profileId
      });
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  }


  const sendInvitation = async (targetEmail, organisationId, invitedBy) => {
    try {
      const sendInvitationresult = await supabase.functions.invoke('send-invitation-user-organisation',{
        body: {
          invited_by: invitedBy,
          organisation_id: organisationId,
          target_email: targetEmail
        }
      });
      if(sendInvitationresult.error) {
        throw sendInvitationresult.error;
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  return {
    fetchMyOrganisation,
    fetchProfilesNotAdded,
    fetchOrganisationById,
    fetchUserRights,
    fetchAdminDomain,
    fetchMembers,
    fetchPotentialOrganisationOfUser,
    updateName,
    updateDomains,
    updateRole,
    uploadLogo,
    downloadLogo,
    deleteLogo,
    addMember,
    revokeAccess,
    sendInvitation
  }
};

export default useOrganisationApi;
