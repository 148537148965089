'use client'

import React, {useContext, useEffect, useState} from 'react';
import { SettingsContext } from './settingsContext';
import { AuthContext } from '../../auth/lib/authContext';
import { supabase } from '../../supabase.client';

export default function SettingsProvider(props) {

  const {children} = props;
  
  const authContext = useContext(AuthContext);
  const [settings, setSettings] = useState();

  const fetchSettings = async () => {
    try {
      let { data, error } = await supabase.from('user_settings')
        .select()
        .eq('id', authContext.profile.id)
        .limit(1)
        .single();
      if(error) {
        throw error
      } else {
        setSettings(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const hideOnboardingVideo = async () => {
    try {
      let { data, error } = await supabase.from('user_settings')
      .update({
        display_onboarding_video: false
      })
      .eq('id', authContext.profile.id)
      .select()
      .single();
      if(error) {
        throw error
      } else {
        setSettings(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onProfileChange = async () => {
    if(!settings && authContext.profile) {
      await fetchSettings(authContext.profile.id);
    }
  }

  useEffect(() => {
    onProfileChange();
  }, [authContext.profile]);

  return (
    <SettingsContext.Provider value={{
      settings,
      hideOnboardingVideo: hideOnboardingVideo}} >
      {children}
    </SettingsContext.Provider>
  );
}




