'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/layout';
import { FormControl, Textarea, FormErrorMessage } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/button';
import { MdAdd, MdCancel, MdCheck, MdClose, MdOutlineCancel, MdOutlineClose, MdOutlineRemove } from 'react-icons/md';
import { forwardRef } from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';
import ImageInput from '../../components/imageInput';
import { Button, FormLabel, Input, Skeleton, Icon } from '@chakra-ui/react';
import { getValue } from '@testing-library/user-event/dist/utils';

const InsightImageForm = forwardRef((props, ref) => {

  const {
    insight,
    refresh
  } = useContext(InsightContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
  } = props;

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      images: []
    },
    mode: "onChange"
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'images',
  });

  const insightApi = useInsightApi();
  const [downloading, setDownloading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [previousReady, setPreviousReady] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);

  const fetchImages = async () => {
    try {
      if(isReady && !previousReady) {
        setValue('images', [])
        if (insight.image_count) {
          setDownloading(true)
          const images = await insightApi.downloadImages(insight.id, 'original');
          images.forEach((image) => {
            append({value: { originalImage: image }})
          })
          setDownloading(false)
        } else {
          append({ value: '' });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const images = [];
      data.images.forEach((image) => {
        if(image.value) {
          images.push(image.value);
        }
      })
      await insightApi.uploadImages(insight.id, images, 'webp')
      await refresh();
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isReady) {
      setPreviousReady(false);
      setIsReady(true);
    }
    fetchImages();
  }, [isReady]);

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    if (onValidityChange) {
      if(downloading) {
        onValidityChange(false);
      } else {
        onValidityChange(isValid);
      }
    }
  }, [isValid, downloading]);


  const onImageUpdated = () => {
    const values = getValues();
    let newDisplayButton = true;
    values.images.forEach((v) => {
      if(v.value === '') {
        newDisplayButton = false;
      }
    })
    setDisplayButton(newDisplayButton)
  };

  return (
    <>
      <>
        <Flex
          border="1px"
          borderColor="divider.grey"
          borderRadius="2px"
          backgroundColor="white"
          flex="1"
          p={3}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column-reverse',
              gap: '20px'
            }}>
            {downloading && (
              <Skeleton height='100px' />
            )}
            {!downloading && <>
              {fields.map((field, index) => (
                <Controller
                  key={field.id}
                  control={control}
                  name={`images.${index}.value`}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid }
                  }) => {
                    return (
                      <FormControl isInvalid={invalid} >
                        {downloading && (<Skeleton height={"40px"} />)}
                        {!downloading && (
                          <ImageInput
                            maxWidth={'auto'}
                            maxHeight={400}
                            value={value}
                            onChange={(value) => {
                              if (value) {
                                setDisplayButton(true);
                              } else {
                                setDisplayButton(false);
                              }
                              onChange(value);
                            }}
                            onBlur={onBlur}
                            outputWidths={[512, 1024]}
                            outputFormat={"webp"}
                          />
                        )}
                        {(!value && index > 0) && (
                          <Button
                            leftIcon={<Icon as={MdOutlineClose} />}
                            variant="coralTransparent"
                            onClick={() => { remove(index) }}
                          >Cancel</Button>
                        )}
                      </FormControl>
                    )
                  }}
                />
              ))}
              {(displayButton) &&
              <Button
                leftIcon={<Icon as={MdAdd} />}
                variant="coralOutline"
                mb={2}
                w={"200px"}
                onClick={() => {
                append({ value: '' }) 
                setDisplayButton(false);
              }}
              >Add Image</Button>
            }
            </>
            }
            <Button
              style={{
                display: hideSave ? 'none' : 'inherit',
              }}
              ref={ref}
              isLoading={isSubmitting}
              isDisabled={(!isValid || downloading)}
              type='submit'
              my={2}
            >Save</Button>
          </form>
        </Flex>
      </>
    </>
  );
});

export default InsightImageForm;


