import React, {useContext, useEffect, useState } from 'react';
import usePersonaApi from '../lib/usePersonaApi';
import { Button, Icon, IconButton, Skeleton, Tag } from '@chakra-ui/react';
import PersonaListItem from './personaListItem';
import { Divider, Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { MdAdd, MdFace } from 'react-icons/md';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import PersonaForm from './personaForm';
import { useRef } from 'react';
import PersonaProvider from '../lib/personaProvider';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../organisation/lib/organisationContext';
import { AuthContext } from '../../auth/lib/authContext';
import ProfileProvider from '../../profile/lib/profileProvider';

export default function PersonaList(props) {

  const {
    insidePaddings = true,
    emptyHeight,
    maxHeight
  } = props;
  const {
    organisation,
    hasOrganisation
  } = useContext(OrganisationContext)
  const {
    profile,
    hasProfile
  } = useContext(AuthContext)
  const personaApi = usePersonaApi();
  const [personalist, setPersonaList] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [tab, setTab] = useState('mine');
  const [isInit, setIsInit] = useState(false);
  const [isReadyToFetch, setIsReadyToFecth] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const ref = useRef(null);

  async function getPersonaList() {
    setIsFetching(true);
    let newPersonaList;
    if(tab === 'mine') {
      newPersonaList = await personaApi.fetchPersonaListByUserId(profile.id);
    } else {
      newPersonaList = await personaApi.fetchPersonaListByOrganisation(organisation.id);
    }
    setPersonaList(newPersonaList);
    setIsFetching(false);
  }

  const getHeight = () => {
    if(emptyHeight) {
      return emptyHeight
    } else {
      return "100%"
    }
  }

  useEffect(() => {
    if(isReadyToFetch && !isInit) {
      setIsInit(true);
      getPersonaList();
    }
  }, [isReadyToFetch, isInit]);

  useEffect(() => {
    if(!isReadyToFetch && hasProfile) {
      setIsReadyToFecth(true);
    }
  }, [isReadyToFetch, hasProfile]);

  useEffect(() => {
    if(personalist) {
      getPersonaList();
    }
  }, [tab]);

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        borderColor="divider.grey"
        flexDirection={"column"}
      >
        <Flex
          alignItems='center'
          py={2}
          px={3}
          backgroundColor="white"
          borderBottom={"1px solid"}
          borderColor={"divider.grey"}
          >
          <Icon
            as={MdFace}
            color={'fill.blue'}
            boxSize={'18px'}
            mr={2}
          />
          <Text
            variant="secondary"
            mr={3}
            fontSize="16px"
            color="text.medium.blue"
          >
            Personas
          </Text>
          {(!isFetching && personalist && personalist.length > 0) && (
            <IconButton
              icon={<MdAdd />}
              aria-label='New'
              variant='blueOutline'
              onClick={onOpen}
              className="new-persona"
            />
          )}
        </Flex>
        {hasOrganisation && (
            <Flex
              alignItems='center'
              py={1}
              px={3}
              backgroundColor="white"
              borderBottom={"1px solid"}
              borderColor={"divider.grey"}
              gap={1}
            >
              <Tag
                variant={(tab === 'mine' ? "blue" : 'grey')}
                cursor={"pointer"}
                onClick={() => {
                  setTab('mine')
                }}
                >Mine</Tag>
              <Tag
                variant={(tab === 'organisation' ? "blue" : 'grey')}
                cursor={"pointer"}
                onClick={() => {
                  setTab('organisation')
                }}
              >{organisation.name}'s</Tag>
            </Flex>
        )}
        <Flex
          flexDirection={"column"}
          h={(!isFetching && personalist && personalist.length === 0) ? getHeight() : "auto"}
          overflow={"auto"}
          p={insidePaddings ? 3 : 0}
        >
          {isFetching && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
          )}
          <Flex
            flexDirection={"column"}
            border={(insidePaddings && personalist && personalist.length > 0) ? "1px solid" : "none"}
            borderColor="divider.grey"
            w="100%"
            h="100%"
            maxH={maxHeight}
          >
            {(!isFetching && personalist && personalist.length === 0) && (
              <Flex
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent={"center"}
              >
                <Button
                  onClick={onOpen}
                  leftIcon={<MdAdd />}
                  variant="blueOutline"
                  className="new-persona"
                  >New Persona</Button>
              </Flex>
            )}
            {(!isFetching && personalist && personalist.length > 0) && personalist.map((p, i) => {
              return (
                <PersonaProvider
                  key={i}
                  originalPersona={p}
                  needRights={false}
                  needRole={false}
                  personaId={p.id}
                >
                  <ProfileProvider
                    originalProfile={p.owner}
                    profileId={p.owner.id}
                  >
                    <Flex
                      flexDirection={"column"}
                      style={{ 'cursor': 'pointer' }}
                      onClick={() => {
                        navigate(`/persona/${p.id}`)
                      }}
                    >
                      {i > 0 && <Divider />}
                      <PersonaListItem/>
                    </Flex>
                  </ProfileProvider>
                </PersonaProvider>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>New Persona</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <PersonaForm
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={(newPersona) => {
                  setIsAdding(false);
                  navigate(`/persona/${newPersona.id}`);
                  onClose();
                }}
                onValidityChange={setIsValid}
                ref={ref}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={onClose}
            >Cancel</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
