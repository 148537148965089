import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, Flex, Icon, Tooltip, IconButton, Menu, MenuButton, MenuList, MenuItem, useDisclosure, Box, Button, Portal } from '@chakra-ui/react';
import ExperienceInsightTags from '../../../experiences/component/experiencesInsightTags';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import useProductsApi from '../../lib/useProductsApi';
import { MdDevices, MdDragIndicator, MdFace, MdMoreVert, MdOutlineRoute } from 'react-icons/md';
import ProductProvider from '../../lib/productProvider';
import PersonaProvider from '@/persona/lib/personaProvider';
import PersonaThumbnail from '@/persona/components/personaThumbnail';
import ProductLogo from '../../components/productLogo';
import MoveExperienceInProductToGroupForm from './moveExperienceInProductToGroupForm';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { ProductPageContext } from '../lib/productPageContext';
import useExperienceApi from '@/experiences/lib/useExperienceApi';
import { ProductContext } from '../../lib/productContext';
import { Link, useNavigate } from 'react-router-dom';

export default function ExperiencesInProductListItem(props) {

  const {
    refreshExperienceList,
    refreshPersonaList,
    experienceGroupList,
  } = useContext(ProductPageContext);

  const {
    productId,
    rights,
  } = useContext(ProductContext);

  const {
    experience
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);
  const [personaList, setPersonaList] = useState(false);
  const [productList, setProductList] = useState(false);
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const navigate = useNavigate();

  const modalMoveToGroup = useDisclosure();
  const ref1 = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const experienceApi = useExperienceApi();

  const fetchData = async () => {
    try {
      const newPersonaList = await personaApi.fetchManyPersonaByExperienceId(experience.id);
      setPersonaList(newPersonaList);
      const newProductList = await productApi.fetchProductsByExperienceId(experience.id);
      setProductList(newProductList);
    } catch (e) {
      console.error(e)
    }
  };

  const leaveTheGroup = async () => {
    try {
      await experienceApi.moveExperienceToGroupInProduct(
        productId,
        experience.id,
        null
      );
      await Promise.all[
        refreshExperienceList(),
        refreshPersonaList()
      ]
      navigate(`/products/${productId}`);
    } catch (error) {
      console.error(error);
    }
  }

  const removeExperienceFromProduct = async () =>  {
    try {
      setIsDeleting(true);
      await experienceApi.removeExperienceFromProduct(experience.products_experiences_id);
      await refreshExperienceList()
      setIsDeleting(false);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (experience) {
      fetchData()
    }
  }, [experience]);

  return (
    <>
      <Flex
        px={3}
        py={2}
        backgroundColor="white"
        role={'group'}
        _hover={{
          background: "background.grey",
        }}
        position={"relative"}
      >
        {rights.can_update && (
          <Flex
            position={"absolute"}
            top="0"
            left={"0"}
            height={"100%"}
            opacity={0}
            _groupHover={{ opacity: '1' }}
            cursor={"grab"}
            py={2}
            pr={3}
          >
            <Icon
              as={MdDragIndicator}
              boxSize={'20px'}
              color={"text.light.grey"}
              mr={2}
            />
          </Flex>
        )}
        <Flex
          flex={1}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex
              alignItems={"center"}
              mb={1}
            >
              <Icon
                as={MdOutlineRoute}
                boxSize={'15px'}
                mr={2}
              />
              <Text noOfLines={2} >{experience.objective}</Text>
            </Flex>
            <ExperienceInsightTags
              experienceId={experience.id}
              productId={productId}
            />
          </Flex>
          <Flex
            mt={3}
            alignSelf={"flex-end"}
          >
            {(personaList && personaList.length > 0) && (
              <Flex
                alignItems={"center"}
                mr={3}
              >
                <Icon
                  as={MdFace}
                  size="20"
                  color="text.medium.grey"
                />
                {personaList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Link to={`/persona/${p.id}`} onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <Flex
                        ml={2}
                      >
                        <PersonaProvider
                          personaId={p.id}
                          originalPersona={p}
                          needRights={false}
                          needRole={false}
                          needEditionRequest={false}
                        >
                          <PersonaThumbnail
                            size={20}
                            hasAcronym={true}
                          />
                        </PersonaProvider>
                      </Flex>
                    </Link>
                  </Tooltip>
                ))}
              </Flex>
            )}
            {(productList && productList.length > 0) && (
              <Flex alignItems={"center"}>
                <Icon
                  as={MdDevices}
                  size="20"
                  color="text.medium.grey"
                />
                {productList && productList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Link to={`/products/${p.id}`} onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <Flex
                        ml={2}
                      >
                        <ProductProvider
                          productId={p.id}
                          orignalProduct={p}
                          needRights={false}
                          needRole={false}
                          needEditionRequest={false}
                        >
                          <ProductLogo
                            size={20}
                            hasAcronym={true}
                          />
                        </ProductProvider>
                      </Flex>
                    </Link>
                  </Tooltip>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
        {rights.can_update && (
          <Flex
            height={"100%"}
            pl={3}
            alignItems={"center"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Menu >
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={IconButton}
                    icon={<MdMoreVert />}
                    fontSize={"18px"}
                    size="md"
                    variant='greyTransparent'
                  />
                  <Portal>
                    <MenuList zIndex="popover">
                      <MenuItem onClick={(e) => {
                        navigate(`/experiences/${experience.id}`);
                      }} >Go to Experience</MenuItem>
                      {(experienceGroupList && experienceGroupList.length > 0) &&
                        <MenuItem onClick={modalMoveToGroup.onOpen} >Move to Group</MenuItem>
                      }
                      {experience.product_experience_group_id &&
                        <MenuItem
                          onClick={leaveTheGroup}
                        >Leave the group</MenuItem>
                      }
                      <MenuItem onClick={removeExperienceFromProduct} >Remove from Product</MenuItem>
                    </MenuList>
                  </Portal>
                </>
              )}
            </Menu>
          </Flex>
        )}
      </Flex>
      <Modal isOpen={modalMoveToGroup.isOpen} onClose={modalMoveToGroup.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Move to Group</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <MoveExperienceInProductToGroupForm
                productId={productId}
                experienceId={experience.id}
                hideSave={true}
                onValidityChange={setIsValid}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  Promise.all([
                    refreshExperienceList(),
                    refreshPersonaList()
                  ])
                  modalMoveToGroup.onClose();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="purpleOutline"
              onClick={modalMoveToGroup.onClose}
            >Cancel</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
