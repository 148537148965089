import React, { useContext, useEffect, useState } from 'react';
import useProfileApi from './useProfileApi';
import { ProfileContext } from './profileContext';
import { supabase } from '../../supabase.client';
import { AuthContext } from '../../auth/lib/authContext';

export default function ProfileProvider(props) {

  let {
    profileId,
    originalProfile,
    children,
    needProfileSystem = false,
    needSubscription = false,
    needPayments = false
  } = props;
  const authContext = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const [profileSystem, setProfileSystem] = useState();
  const [email, setEmail] = useState();
  const [subscription, setSubscription] = useState();
  const [payments, setPayments] = useState();
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);

  const profileApi = useProfileApi();

  const refresh = async (options = { skipProfile: false }) => {
    try {
      const promises = [];
      let nbRequest = 0;
      let orders = {};
      if (!options.skipProfile) {
        promises.push(profileApi.fetchProfile(profileId))
        orders.profile = nbRequest;
        nbRequest++;
      }
      if (needProfileSystem) {
        promises.push(profileApi.fetchProfileSystem(profileId))
        orders.profileSystem = nbRequest;
        nbRequest++;
      }
      if (needSubscription) {
        promises.push(profileApi.fetchSubscription(profileId))
        orders.subscription = nbRequest;
        nbRequest++;
      }
      if (needPayments) {
        promises.push(profileApi.fetchPayments(profileId))
        orders.payments = nbRequest;
        nbRequest++;
      }
      const promisesResult = await Promise.all(promises);
      if (!options.skipProfile) {
        setProfile(promisesResult[orders.profile]);
        setEmail(promisesResult[orders.profile].email)
      }
      if (needProfileSystem) {
        setProfileSystem(promisesResult[orders.profileSystem]);
      }
      if (needSubscription) {
        setSubscription(promisesResult[orders.subscription]);
      }
      if (needPayments) {
        setPayments(promisesResult[orders.payments]);
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      if (!originalProfile) {
        refresh()
      } 
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

useEffect(() => {
    if(profileId && authContext.hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
    }
  }, [profileId, authContext.hasProfile]);
  
  useEffect(() => {
    if (originalProfile && authContext.hasProfile) {
      setProfile(originalProfile);
      setEmail(originalProfile.email)
      refresh({skipProfile: true})
    }
  }, [originalProfile, authContext.hasProfile]);

  useEffect(() => {
    if (profileId) {
      const channel = supabase
        .channel('user_system')
        .on('postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'user_system',
            filter: 'id=eq.' + profileId
          }, (payload) => {
            refresh();
          }
        )
        .subscribe()
      return () => {
        supabase.removeChannel(channel)
      }
    }
  }, []);

  return (
    <ProfileContext.Provider value={{
      profile,
      profileSystem,
      profileId,
      refresh,
      email,
      subscription,
      payments
    }} >
      {profile && (
        <>
          {children}
        </>
      )}
    </ProfileContext.Provider>
  );
}




