import React, { useContext, useRef, useState } from 'react';
import useInsightApi from '../lib/useInsightApi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
  Icon,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import InsightQuoteList from './insightQuoteList';

export default function InsightEditQuoteModal(props) {

  const {
    insight,
    rights
  } = useContext(InsightContext)

  const {
    disclosure
  } = props;

  const [displayForm, setDisplayForm] = useState(false);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            {(rights.can_update && insight.quotes.length === 0) ? 'Add Quote' : ''}
            {(rights.can_update && insight.quotes.length !== 0) ? 'Edit Quotes' : ''}
            {(!rights.can_update) ? 'Quotes' : ''}
            </ModalHeader>
          <ModalBody p={3}>
            <InsightQuoteList onFormDisplayChange={setDisplayForm} />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="coralFill"
              ml={2}
              isDisabled={displayForm}
              onClick={disclosure.onClose} >Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
