'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Skeleton } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/layout';
import ImageInput from '../../../components/imageInput';
import { forwardRef } from '@chakra-ui/system';
import { Controller, useForm } from 'react-hook-form';
import usePersonaApi from '../../lib/usePersonaApi';
import { PersonaContext } from '../../lib/personaContext';

const PersonaThumbnailForm = forwardRef((props, ref) => {

  const { persona, refresh} = useContext(PersonaContext);
  const [downloading, setDownloading] = useState(false);

  const {
    hideSave,
    onSubmitting,
    onSubmitted,
    onValidityChange,
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const personaApi = usePersonaApi();

  const fetchThumbnail = async () => {
    try {
      if(persona.has_thumbnail) {
        setDownloading(true)
        const newThumbnail = await personaApi.downloadThumbnail(persona.id, 256);
        setValue('thumbnail', { originalImage: newThumbnail })
        setDownloading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const images = data.thumbnail;
      if (images && persona.id) {
        await personaApi.uploadThumbnail({
          '64': images[64],
          '128': images[128],
          '256': images[256],
        }, persona.id)
      } else if(!images && persona.id) {
        await personaApi.deleteThumbnail(persona.id)
      }
      await refresh();
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);


  useEffect(() => {
    if (persona) {
      fetchThumbnail();
    }
  }, [persona]);

  return (
    <>
      <Box
        border="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        backgroundColor="white"
        p={3}
      >
        <form onSubmit={handleSubmit(onSubmit)} >
          <Controller
            control={control}
            name="thumbnail"
            rules={{}}
            render={({
              field: { onChange, onBlur, value },
            }) => (
              <FormControl isInvalid={errors && errors.experience}>
                <FormLabel>Upload an image</FormLabel>
                {downloading && (<Skeleton height={"40px"} />)}
                {!downloading && (
                <ImageInput
                  maxWidth={100}
                  value={value}
                  crop={{
                    aspect: 1,
                    zoom: 1,
                    x: 0,
                    y: 0
                  }}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  onBlur={onBlur}
                  outputWidths={[64, 128, 256]}
                  outputFormat={"webp"}
                />
                )}
                <FormErrorMessage>{errors && errors.experience}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid && downloading}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default PersonaThumbnailForm;


