import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
export default function AuthLayout() {

  const [images, setImages] = useState(null);
  const [planBannerDisplay, setPlanBannerDisplay] = useState(false);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');
  const arg = searchParams.get('arg');

  useEffect(() => {
    setPlanBannerDisplay(redirectUrl === "/profile" && arg === 'modal=upgrade-plan')
  }, [redirectUrl]);

  useEffect(() => {
    if (!images) {
      const newImages = [];
      for (let i = 1; i <= 71; i++) {
        const newImage = 'image' + i;
        newImages.push(newImage);
      }
      for (let i = newImages.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newImages[i], newImages[j]] = [newImages[j], newImages[i]];
      }
      setImages(newImages);
    }
  }, []);

  return (
    <Flex
      flexDirection="row"
      h="100%"
      backgroundColor="background.white"
    >
      <Flex
        w={415}
        flexShrink={0}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        {planBannerDisplay && (
          <Flex
            px={3}
            py={3}
            borderBottom={"1px solid"}
            borderColor={"divider.coral"}
            backgroundColor={"background.coral"}
            fontWeight={"600"}
            w={"100%"}
            >
              <Text>You need to sign in before upgrading your plan.</Text>
          </Flex>
        )}
        <Flex
         alignItems={'center'}
         justifyContent={'center'}
         flexDirection={'column'}
         flex="1"
        >
          <Outlet />
        </Flex>
        <Flex p={3}>
          <Link to="/legal/terms-of-use" target="_blank" >
            <Text color="text.medium.coral" fontSize="12px">Terms of Use</Text>
          </Link>
          <Text to="/legal/privacy-policy" fontSize="12px" px={1}  color="text.medium.coral">•</Text>
          <Link to="/legal/privacy-policy" target="_blank" >
            <Text to="/legal/privacy-policy" fontSize="12px" target="_blank" color="text.medium.coral">Privacy Policy</Text>
          </Link>
          <Text to="/legal/privacy-policy" fontSize="12px" px={1}  color="text.medium.coral">•</Text>
          <Link to="https://bibliona.com" target="_blank" >
            <Text to="/legal/privacy-policy" fontSize="12px" target="_blank" color="text.medium.coral">Info</Text>
          </Link>
        </Flex>
        <Flex
          px={3}
          py={3}
          borderTop={"1px solid"}
          borderColor={"divider.coral"}
          backgroundColor={"background.coral"}
          fontWeight={"600"}
          w={"100%"}
          display={["inherit", "none"]}
          >
            <Text>The experience is optimized for larger screen.</Text>
        </Flex>
      </Flex>
      <Flex
        display={["none", "inherit"]}
        background={"background.grey"}
        h="100%"
        justifyContent={'center'}
        flexWrap={'wrap'}
        overflow={"hidden"}
        borderLeft="1px solid"
        borderColor="divider.grey"
        p={3}
        gap={2}>
        {images && images.map((image, i) => (
          <img
            key={i}
            src={('/sign-in/' + image + '.jpg')}
            alt="illustration"
            style={
              {
                'width': '100px',
                'height': '100px',
              }}
          />
        ))}
      </Flex>
    </Flex>
  )
}