import { Center, Divider, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdOutlineAddPhotoAlternate, MdOutlineChevronLeft, MdOutlineChevronRight, MdOutlineDelete, MdOutlineEdit, MdOutlineFormatQuote } from "react-icons/md";
import { InsightContext } from "../lib/insightContext";
import useInsightApi from "../lib/useInsightApi";
import { SlideshowContext } from "../lib/slideshowContext";

export default function InsightFigures(props) {

  const {
    insight,
    rights
  } = useContext(InsightContext)

  const slideshowContext = useContext(SlideshowContext)

  const insightApi = useInsightApi();
  const [thumbnails, setThumbnails] = useState();
  const [downloading, setDownloading] = useState(false);
  const [slideItems, setSlideItems] = useState();
  const [slideNumber, setSlideNumber] = useState(0);

  const createSlideshow = async () => {
    const newSlideItems = [];
    if (thumbnails) {
      thumbnails.forEach((thumbnail, i) => {
        newSlideItems.push({
          type: 'thumbnail',
          value: thumbnail,
          index: i
        })
      })
    }
    const startFrom = newSlideItems.length;
    if (insight.quotes) {
      insight.quotes.forEach((q, i) => {
        newSlideItems.push({
          type: 'quote',
          value: q,
          index: startFrom + i
        })
      });
    }
    setSlideItems(newSlideItems)
    setSlideNumber(0)
  };

  const fetchThumbnails = async () => {
    try {
      setDownloading(true);
      const newThumbnails = await insightApi.downloadImages(insight.id, 512);
      setThumbnails(newThumbnails);
      setDownloading(false);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if ((insight.quotes && insight.quotes.length > 0) ||
      (thumbnails && thumbnails.length > 0)) {
      createSlideshow()
    }
  }, [thumbnails, insight.quotes]);

  useEffect(() => {
    if (insight.id && insight.image_count) {
      fetchThumbnails()
    } else if (insight.id && insight.image_count === 0) {
      setThumbnails()
    }
  }, [insight.id, insight.image_count]);

  return (
    <>
      {(rights && rights.can_update && insight.quotes.length === 0 && insight.image_count === 0) && (
        <Flex
          width="350px"
          minH={"220px"}
          h={"100%"}
          flexShrink={"0"}
          bg="background.grey"
          borderLeft="1px solid"
          borderColor="divider.grey"
          borderRadius="2px"
          ml="3"
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          position={"relative"}
          role="group"
          onClick={() => {
            slideshowContext.playFromInsight(insight.id)
          }}
        >
          <Flex
            flexDirection={"column"}
            flex={1} alignItems={"center"} p={3} gap={3}>
            <Icon as={MdOutlineAddPhotoAlternate} boxSize={"45px"} color={"#ededed"} />
            <Flex alignItems={"center"} gap={2}>
              <Flex height={"1px"} bg={"#ededed"} w={"50px"} />
              <Text color="#ededed">OR</Text>
              <Flex height={"1px"} bg={"#ededed"} w={"50px"} />
            </Flex>
            <Icon as={MdOutlineFormatQuote} boxSize={"45px"} color={"#ededed"} />
          </Flex>
        </Flex>
      )}
      {(insight.quotes.length !== 0 || insight.image_count !== 0) && (
        <Flex
          width="350px"
          flexShrink={"0"}
          minH={"220px"}
          h={"100%"}
          bg="background.grey"
          borderLeft="1px solid"
          borderColor="divider.grey"
          borderRadius="2px"
          ml="3"
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          position={"relative"}
          role="group"
          onClick={() => {
            slideshowContext.playFromInsight(insight.id)
          }}
        >
          {downloading && <Skeleton height='100%' width="100%" />}
          {(slideItems && slideItems.length > 0) && (
            <>
              {slideItems.map((slideItem, i) => (
                <Flex
                  key={i}
                >
                  {(slideItem.type === 'thumbnail' && slideItem.index === slideNumber) &&
                    <Flex
                      w="100%"
                      height={"100%"}
                      bg="#dadada"
                      position={"absolute"}
                      top="0"
                      left="0"
                    >
                      <img
                        src={slideItem.value}
                        alt="thumbnail"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit:
                            'contain'
                        }}
                      />
                    </Flex>
                  }
                  {(slideItem.type === 'quote' && slideItem.index === slideNumber) &&
                    <Flex
                      w="100%"
                      height={"100%"}
                      bg="white"
                      position={"absolute"}
                      p={3}
                      alignItems={"center"}
                      overflowY={"auto"}
                      top="0"
                      left="0"
                    >
                      <Flex
                        pl={3}
                        position={"relative"}
                        borderLeft={"1px solid"}
                        borderColor={"text.light.grey"}
                      >
                        <Flex
                          position={"absolute"}
                          backgroundColor={"white"}
                          color={"text.light.grey"}
                          top="0"
                          left="-10px"
                        >
                          <Icon as={MdOutlineFormatQuote} boxSize={"15px"} />
                        </Flex>
                        <Text fontSize={"16px"}>
                          {slideItem.value}
                        </Text>
                      </Flex>
                    </Flex>
                  }
                </Flex>
              ))}
              {(slideNumber < slideItems.length - 1 && slideItems.length > 1) &&
                <Flex
                  position={"absolute"}
                  zIndex={slideItems.length + 1}
                  bg={"background.coral"}
                  border="1px solid"
                  borderColor="divider.coral"
                  borderRadius={"50%"}
                  p={"4px"}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                  right={1}
                  bottom={1}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setSlideNumber(slideNumber + 1)
                  }}
                >
                  <Icon color="text.medium.coral" boxSize={"25px"} as={MdOutlineChevronRight} />
                </Flex>
              }
              {slideNumber >= 1 &&
                <Flex
                  position={"absolute"}
                  zIndex={slideItems.length + 1}
                  bg={"background.coral"}
                  border="1px solid"
                  borderColor="divider.coral"
                  borderRadius={"50%"}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                  p={"4px"}
                  left={1}
                  bottom={1}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setSlideNumber(slideNumber - 1)
                  }}
                >
                  <Icon color="text.medium.coral" boxSize={"25px"} as={MdOutlineChevronLeft} />
                </Flex>
              }
              <Flex
                position={"absolute"}
                zIndex={slideItems.length + 1}
                p={"4px"}
                opacity={0}
                _groupHover={{ opacity: 1 }}
                bottom={1}
                gap={2}
                right="50px"
                w="calc(100% - 100px) "
                flexWrap={"wrap"}
                justifyContent={"flex-end"}
              >
                {slideItems.length > 1 && slideItems.map((item, i) => (
                  <Flex
                    key={i}
                    w={"10px"}
                    h={"10px"}
                    flexShrink={0}
                    borderRadius={"50%"}
                    border={"1px solid"}
                    borderColor={"fill.coral"}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setSlideNumber(item.index);
                    }}
                    background={slideNumber === item.index ? "fill.coral" : "background.coral"} />
                ))}
              </Flex>
            </>
          )}
        </Flex>
      )}
    </>
  );
}
