import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@/auth/lib/authContext';
import { OrganisationContext } from './organisationContext.js';
import useOrganisationApi from './useOrganisationApi.js';

export default function OrganisationProvider(props) {

  const {
    organisationId,
    originalOrganisation,
    needMembers=false,
    needProfilesNotAdded=false,
    children,
    onRefreshOrganisation
  } = props;

  const [isFetchingOrganisation, setIsFetchingOrganisation] = useState(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [isFetchingProfilesNotAdded, setIsFetchingProfilesNotAdded] = useState(false);
  const [organisation, setOrganisation] = useState(false);
  const [hasOrganisation, setHasOrganisation] = useState(false);
  const [members, setMembers] = useState();
  const [profilesNotAdded, setProfilesNotAdded] = useState();
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);
  const organisationApi = useOrganisationApi();
  const { profile, hasProfile } = useContext(AuthContext);
  const [rights, setRights] = useState({
    can_update: false,
    can_select: false,
    can_delete: false
  });

  const refreshMembers = async () => {
    try {
      if(needMembers) {
        setIsFetchingMembers(true)
        const newMembers = await organisationApi.fetchMembers(organisationId)
        setMembers(newMembers)
        setIsFetchingMembers(false)
      }
    } catch (error) {
      console.error(error)
    }
  };

  const refreshProfilesNotAdded = async () => {
    try {
      if(needProfilesNotAdded) {
        setIsFetchingProfilesNotAdded(true)
        const newProfilsNotAdded = await organisationApi.fetchProfilesNotAdded(organisationId)
        setProfilesNotAdded(newProfilsNotAdded);
        setIsFetchingProfilesNotAdded(false);
      }
    } catch (error) {
      console.error(error)
    }
  };

  const refresh = async (options = {skipOrganisation: false}) => {
    try {
      setIsFetchingOrganisation(true);
      const promises = [];
      let nbRequest = 0;
      let orders = {};
      if(!options.skipOrganisation) {
        promises.push(organisationApi.fetchOrganisationById(organisationId))
        orders.organisation = nbRequest;
        nbRequest++;
      }
      promises.push(organisationApi.fetchUserRights(organisationId, profile.id))
      orders.rights = nbRequest;
      nbRequest++;
      const promisesResult = await Promise.all(promises)
      if(!options.skipOrganisation) {
        setOrganisation(promisesResult[orders.organisation]);
        if(onRefreshOrganisation) {
          onRefreshOrganisation(promisesResult[orders.organisation])
        }
      }
      setRights(promisesResult[orders.rights])
      refreshMembers();
      refreshProfilesNotAdded();
      setIsFetchingOrganisation(false);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    setHasOrganisation(!!organisation);
  }, [organisation]);

  useEffect(() => {
    if (isReadyToFetch && !previouslyReadyToFetch) {
      setPreviouslyReadyToFetch(true)
      if (originalOrganisation) {
        refresh({skipOrganisation: true})
      } else {
        refresh({skipOrganisation: false})
      }
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

  useEffect(() => {
    if (organisationId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
    } else {
      if(organisation) {
        setMembers(null);
        setProfilesNotAdded(null);
        setOrganisation(null)
        setPreviouslyReadyToFetch(false)
        setIsReadyToFetch(false);
      }
    }
  }, [organisationId, hasProfile]);

  useEffect(() => {
    if (originalOrganisation && hasProfile) {
      setOrganisation(originalOrganisation);
    }
  }, [originalOrganisation, hasProfile]);

  return (
    <OrganisationContext.Provider value={{
      organisationId,
      organisation,
      hasOrganisation,
      refresh,
      members,
      profilesNotAdded,
      isFetchingOrganisation,
      rights,
      isFetchingMembers,
      isFetchingProfilesNotAdded,
      refreshMembers,
      refreshProfilesNotAdded
    }} >
      {children}
    </OrganisationContext.Provider>
  );
}