'use client'

import React, {createContext} from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './theme/chakra.theme';
import MenuProvider from './menuProvider';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const ConfigContext = createContext({});

dayjs.extend(relativeTime);

export default function ConfigProvider({children}) {

  return (
    <ConfigContext.Provider value={''}>
        <MenuProvider>
          <ChakraProvider theme={theme}>
            {children}
          </ChakraProvider>
        </MenuProvider>
    </ConfigContext.Provider>
  );
}




