'use client'

import React, { useContext } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import ProfileThumbnail from '../profile/component/profileThumbnail';
import { Button, Icon, IconButton, Tag, Tooltip, useDisclosure, } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import Logo from './logo';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, } from '@chakra-ui/react'
import { MdOutlineFeedback, MdSupportAgent } from 'react-icons/md';
import { LuPanelLeft } from "react-icons/lu";
import { MenuContext } from '../lib/menuContext';
import { AuthContext } from '../auth/lib/authContext';
import FeedbackModal from '../feedback/components/feedbackModal';
import SearchBar from './searchBar';

export default function Header() {

  const {
    profile,
    email,
    signOut
  } = useContext(AuthContext)
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure()
  const menuContext = useContext(MenuContext)
  const feedbackModal = useDisclosure();

  return (
    <>
      <Flex
        borderBottom="1px"
        borderColor="divider.grey"
        px={3}
        py={2}
        height={"55px"}
        backgroundColor="white"
        alignItems={'center'}
        justifyContent={"space-between"}
        flex={"0 0 auto"}
      >
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex
            flexDirection={"row"}
            alignItems={"center"}
            width={"100%"}
            maxW={"180px"}
          >
            {!menuContext.visibility && (
              <Tooltip label='Open Menu' >
                <Flex mr={2}>
                  <IconButton
                    variant="greyTransparent"
                    color="text.medium.grey"
                    fontSize='16px'
                    icon={<LuPanelLeft />}
                    onClick={menuContext.open}
                  />
                </Flex>
              </Tooltip>
            )}
            <Link to="/" >
              <Logo />
            </Link>
          </Flex>
          {
            <SearchBar />
          }
        </Flex>
        <Flex>
          {profile && (
            <Popover
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            >
              <PopoverTrigger>
                <Button
                  cursor={"pointer"}
                  variant={"greyTransparent"}
                  p={"0"}
                  borderRadius={"50%"}
                  className="profile-popover-button"
                >
                  <ProfileThumbnail size={40} />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                w="250px"
              >
                <PopoverArrow />
                <PopoverBody>
                  <Flex
                    alignItems={"center"}
                    flexDirection={"column"}
                    flex="1"
                    alignSelf={"stretch"}
                  >
                    {profile && (
                      <Flex
                        py={3}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={2}
                        cursor={"pointer"}
                        onClick={() => {
                          navigate('/profile')
                        }}
                      >
                        <ProfileThumbnail size={70} />
                        <Flex
                          flexDirection={"column"}
                          alignSelf={"stretch"}
                          alignItems={"center"}
                        >
                          <Text>{profile.pseudo}</Text>
                          {email && (
                            <Text fontSize={"10px"} color="text.medium.grey">{email}</Text>
                          )}
                        </Flex>
                      </Flex>
                    )}
                    <Flex
                      borderTop="1px solid"
                      borderColor="divider.grey"
                      flexDirection={"column"}
                      alignSelf={"stretch"}
                      alignItems={"stretch"}
                      p={2}
                      gap={1}
                    >
                      <Button
                        variant="greyTransparent"
                        onClick={() => {
                          navigate('/profile')
                          onClose();
                        }}
                      >Profile</Button>
                      <Button
                        variant="greyTransparent"
                        onClick={() => {
                          signOut();
                          onClose();
                        }}
                      >Sign Out</Button>
                    </Flex>
                    <Flex
                      borderTop="1px solid"
                      borderColor="divider.grey"
                      flexDirection={"column"}
                      alignSelf={"stretch"}
                      alignItems={"stretch"}
                      p={2}
                    >
                      <Button
                        leftIcon={<Icon as={MdOutlineFeedback} />}
                        variant="greyTransparent"
                        onClick={feedbackModal.onOpen}>
                        Give Feedback
                      </Button>
                      <FeedbackModal disclosure={feedbackModal} />
                      <Link
                      w="100%"
                      style={{
                        display: "flex",
                        alignItems:"center",
                        justifyContent:"center",
                      }}
                      to={"https://www.support.bibliona.com"} target='_blank'>
                        <Button
                          w={"100%"}
                          variant="greyTransparent"
                          leftIcon={<Icon
                           as={MdSupportAgent}
                          />}
                        >
                          Support
                        </Button>
                      </Link>
                    </Flex>
                    <Flex
                      borderTop="1px solid"
                      borderColor="divider.grey"
                      flexDirection={"column"}
                      alignSelf={"stretch"}
                      alignItems={"stretch"}
                      p={2}
                    >
                      <Button
                        variant="greyTransparent"
                        fontSize={"10px"}
                        onClick={() => {
                          navigate('/legal/terms-of-use')
                          onClose();
                        }}
                      >Terms of Use</Button>
                      <Button
                        fontSize={"10px"}
                        variant="greyTransparent"
                        onClick={() => {
                          navigate('/legal/privacy-policy')
                          onClose();
                        }}
                      >Privacy Policy</Button>
                    </Flex>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </Flex>
    </>
  );
}
