import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignInPage from './auth/sign-in/SignInPage';
import SignUpPage from './auth/sign-up/SignUpPage';
import AuthLayout from './auth/AuthLayout';
import EmailConfirmedPage from './auth/email-confirmed/EmailConfirmedPage';
import ResetPasswordPage from './auth/reset-password/ResetPasswordPage';
import UpdatePasswordPage from './auth/update-password/UpdatePasswordPage';
import ExperiencePage from './experiences/[experienceId]/ExperiencePage';
import ProfilePage from './profile/ProfilePage';
import HomePage from './home/HomePage';
import AuthProvider from './auth/lib/authProvider';
import RootLayout from './RootLayout';
import PersonaDetailPage from './persona/[id]/PersonaDetailPage';
import PersonaPage from './persona/PersonaPage';
import ProductDetailPage from './products/[id]/ProductDetailPage';
import ProductPage from './products/ProductPage';
import NotFoundPage from './not-found/NotFoundPage';
import PrivacyPolicyPage from './legal/privacy-policy/page';
import TermsOfUsePage from './legal/terms-of-use/page';
import SplashScreenProvider from './auth/splash-screen/splashScreenProvider';
import StripeProvider from './subscriptions/lib/stripeProvider';
import SuccessPage from './payment/success/SuccessPage';
import CancelPage from './payment/cancel/CancelPage';
import OrganisationPage from './organisation/OrganisationPage';
import OrganisationProvider from './organisation/lib/organisationProvider';
import UserOrganisationProvider from './organisation/lib/userOrganisationProvider';

function AppRoutes({ children }) {
  return (
    <Router>
      <SplashScreenProvider>
        <AuthProvider>
          <UserOrganisationProvider>
            <StripeProvider>
              <RootLayout>
                <Routes>
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="/auth" element={<AuthLayout />}>
                    <Route path="sign-in" element={<SignInPage />} />
                    <Route path="sign-up" element={<SignUpPage />} />
                    <Route path="email-confirmed" element={<EmailConfirmedPage />} />
                    <Route path="update-password" element={<UpdatePasswordPage />} />
                    <Route path="reset-password" element={<ResetPasswordPage />} />
                  </Route>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/persona" element={<PersonaPage />} />
                  <Route path="/persona/:id" element={<PersonaDetailPage />} />
                  <Route path="/products" element={<ProductPage />} />
                  <Route path="/products/:id" element={<ProductDetailPage />} />
                  <Route path="/experiences/:id" element={<ExperiencePage />} />
                  <Route path="/organisation/" element={<OrganisationPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/payment/success" element={<SuccessPage />} />
                  <Route path="/payment/cancel" element={<CancelPage />} />
                  <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="/legal/terms-of-use" element={<TermsOfUsePage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </RootLayout>
            </StripeProvider>
          </UserOrganisationProvider>
        </AuthProvider>
      </SplashScreenProvider>
    </Router>
  );
}

export default AppRoutes;