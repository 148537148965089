'use client';

import React, {useState } from 'react';
import SignUpForm from '../components/signUpForm';
import { Button, Text, Flex } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import Logo from '../../components/logo';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function SignUpPage() {

  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');
  const arg = searchParams.get('arg');

  return (
    <>
      <Logo />
      < Text
        my={3}
        fontSize="18px"
        >Sign up</Text>
        {!submitted && (
            <SignUpForm 
              onSubmitted={() => {
              setSubmitted(true);
              if(process.env && process.env.NODE_ENV === 'development') {
                navigate('/');
              }
            }}
          />
        )}
        {submitted && (
          <Flex
            alignItems="center"
            flexDirection="column"
            >
            <Text mb={2}>You will receive an email to confirm your email address.</Text>
            <Button
              variant="greyOutline"
              leftIcon={MdArrowBack}
              onClick={() => {
                navigate('/auth/sign-in' + (redirectUrl ? '?redirect_url=' + redirectUrl : '') + (arg ? '&arg=' + arg : ''))
              }}
              >
                Go back to sign in</Button>
          </Flex>
        )}
    </>
  )
}
