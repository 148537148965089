import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Skeleton,
  Textarea,
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { MdOutlineSend } from 'react-icons/md';
import ResizeTextarea from "react-textarea-autosize";
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';
import { AuthContext } from '../../auth/lib/authContext';
import Comment from '../../components/comment';

export default function InsightsCommentsDrawer(props) {

  const {
    disclosure
  } = props;

  const { insight , refresh} = useContext(InsightContext)
  const btnRef = useRef()
  const insightApi = useInsightApi();
  const [comments, setComments] = useState();
  const [message, setMessage] = useState();
  const [addingComment, setAddingComment] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const authContext = useContext(AuthContext);

  const fetchComments = async () => {
    try {
      setLoadingComments(true)
      const newComments = await insightApi.fetchAllCommentsByInsightId(insight.id);
      setComments(newComments);
      setLoadingComments(false);
    } catch (error) {
      console.error(error);
    }
  };

  const addCommentToInsight = async () => {
    try {
      setAddingComment(true);
      await insightApi.addCommentToInsight(authContext.profile.id, insight.id, message);
      setMessage('')
      await fetchComments();
      await refresh();
      setAddingComment(false)
    } catch (error) {
      console.error(error);
    }
  }

  const deleteComment = async (commentId) => {
    try {
      await insightApi.deleteComment(commentId);
      await fetchComments();
      await refresh();
    } catch (error) {
      console.error(error);
    }
  }

  const editComment = async (newMessage, commentId) => {
    try {
      await insightApi.updateMessage(newMessage, commentId);
      await fetchComments();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!comments && disclosure.isOpen) {
      fetchComments();
    }
  }, [disclosure.isOpen])

  return (
    <>
      <Drawer
        isOpen={disclosure.isOpen}
        placement='right'
        onClose={disclosure.onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Comments</DrawerHeader>
          <DrawerBody
            px={0}
            py={0}
          >
            <Flex
              height={"100%"}
              flexDirection={"column"}
            >
              <InputGroup
                borderRadius={0}
              >
                <Textarea
                  placeholder='Add a comment'
                  as={ResizeTextarea}
                  resize="none"
                  minH={"42px"}
                  maxH={"200px"}
                  value={message}
                  borderTop={'none'}
                  borderLeft={'none'}
                  borderRight={'none'}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
                <InputRightElement>
                  <IconButton
                    icon={<MdOutlineSend />}
                    fontSize='16px'
                    variant={"greyTransparent"}
                    isLoading={addingComment}
                    onClick={addCommentToInsight}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex
                flex={1}
                flexDirection={"column"}
                overflow={"auto"}
              >
                {loadingComments &&
                  <Flex
                    flexDirection={"column"}
                    gap={2}
                    p={3}
                    flexShrink={"0"}
                  >
                    <Skeleton w={"100%"} h={"90px"} flexShrink={"0"} />
                    <Skeleton w={"100%"} h={"90px"} flexShrink={"0"} />
                    <Skeleton w={"100%"} h={"90px"} flexShrink={"0"} />
                  </Flex>
                }
                {!loadingComments && comments && comments.map((comment, i) => (
                  <Flex
                    flexDirection={"column"}
                    key={i}
                    borderBottom={"1px solid"}
                    borderColor={"divider.grey"}
                  >
                    <Comment
                      comment={comment}
                      editComment={editComment}
                      deleteComment={deleteComment}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
