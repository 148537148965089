import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';
import { ProfileContext } from '../../profile/lib/profileContext';
import { Button, Tag } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import StripeProvider from '../../subscriptions/lib/stripeProvider';
import StripeContext from '../../subscriptions/lib/stripeContext';

export default function PaymentsModal(props) {

  const {
    payments
  } = useContext(ProfileContext)

  const stripeContext = useContext(StripeContext)
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const {
    disclosure,
  } = props;

  const fetchInvoice = async (paymentId) => {
    setLoadingInvoice(true);
    const invoice = await stripeContext.fetchInvoice(paymentId);
    window.open(invoice.hosted_invoice_url);
    setLoadingInvoice(false);
  };

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent maxW={700} >
          <ModalHeader>Payments</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Box p={3}>
              <Flex
                flexDirection={"column"}
                border={"1px solid"}
                borderColor={"divider.grey"}
              >
                {payments && payments.map((p, i) => (
                  <Flex
                    key={i}
                    backgroundColor={"white"}
                    px={3}
                    py={2}
                    justifyContent={"space-between"}
                    borderTop={ i !== 0 ? "1px solid": 'none'}
                    borderColor={"divider.grey"}
                    alignItems={"center"}
                  >
                    <Flex gap={2}>
                      <Flex width={"200px"}>
                        <Text fontWeight={"500"}>
                          {p.currency === 'usd' ? '$' : ''}
                          {p.amount}
                          {p.currency === 'eur' ? '€' : ''}
                          </Text>
                      </Flex>
                      <Flex>
                        <Text>{dayjs(p.created_at).format('DD-MM-YY HH:00')}</Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      {p.status === 'succeeded' &&
                        <Tag variant={"green"} >Success</Tag>
                      }
                      {p.status === 'failed' &&
                        <Tag variant={"red"} >Fail</Tag>
                      }
                    </Flex>
                    <Flex>
                     <Button
                     isLoading={loadingInvoice}
                     onClick={() => {
                      fetchInvoice(p.stripe_payment_id)
                     }} >See Invoice</Button>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
              <Flex py={3} fontSize={"12px"}>
                <Text mr={2} >Any Problem ?</Text>
                <Text color="text.medium.coral" ><Link to="https://www.support.bibliona.com" target='_blank'>Contact Support</Link> </Text>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}