import React, { useContext, useRef, useState } from 'react';
import { OrganisationContext } from '../lib/organisationContext';
import {  Flex } from '@chakra-ui/react';
import OrganisationMembers from './organisationMembers';

export default function OrganisationDetail(props) {

  const { organisation, rights } = useContext(OrganisationContext);
  
  return (
    <>
      {(organisation && rights) &&
      <Flex
        flexDirection={"column"}
        gap={3}
        w={"100%"}
        >
          <OrganisationMembers />
        </Flex>
      }
    </>
  );
}