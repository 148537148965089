import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Icon, IconButton, Skeleton, useDisclosure} from '@chakra-ui/react';
import ProductInPersonaListItem from './productInPersonaListItem';
import {Box, Flex, Text, Stack} from '@chakra-ui/layout';
import {MdAdd, MdArrowBack, MdDevices} from 'react-icons/md';
import useProductsApi from '../../../products/lib/useProductsApi';
import { useNavigate } from 'react-router-dom';
import { PersonaContext } from '../../lib/personaContext';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import AddProductToPersonaForm from '@/persona/[id]/components/addProductToPersonaForm';
import ProductForm from '@/products/components/productForm';
import ProductProvider from '@/products/lib/productProvider';
import { PersonaPageContext } from '../lib/personaPageContext';
export default function ProductInPersonaList(props) {

  const {
    rights,
  } = useContext(PersonaContext);

  const {
    isFetchingProducts,
    productList,
    refreshProductList
  } = useContext(PersonaPageContext);

  const productApi = useProductsApi();
  const {personaId} = props;
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const modalAddProduct = useDisclosure();
  const modalCreateProduct= useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const linkNewProductToPersona = async (newProduct) => {
    try {
      await productApi.linkManyPersonaToProduct(newProduct.id, [personaId])
      await refreshProductList();
      setIsAdding(false);
      modalCreateProduct.onClose();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Box w="100%">
        <Flex 
        alignItems='center'
        mb={2}
        justifyContent={"space-between"}
        px={3}
        >
          <Flex
            alignItems='center'
          >
            <Icon
              as={MdDevices}
              color={'text.primary'}
              boxSize={'14px'}
              mr={2}
            />
            <Text fontSize={14} >Products</Text>
          </Flex>
          {rights.can_update && (
            <IconButton
              ml={2}
              variant="blueOutline"
              icon={<MdAdd />}
              size="sm"
              onClick={modalAddProduct.onOpen}
            />
          )}
        </Flex>
        <Box maxH={300} overflowY={'scroll'}>
          { isFetchingProducts && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
               <Skeleton height='30px' />
               <Skeleton height='30px' />
               <Skeleton height='30px' />
             </Stack>
          )}
          { (!isFetchingProducts && productList && productList.length === 0) && (
              <Box px={3}>
                <Text color="text.light.grey">No product so far.</Text>
              </Box>
          )}
           { (!isFetchingProducts && productList) && productList.map((p, i) => {
            return (
              <ProductProvider
                productId={p.id}
                orignalProduct={p}
                needRights={false}
                needRole={false}
                key={i}>
                  <Flex
                    style={{'cursor' : 'pointer'}}
                    onClick={() => {
                      navigate( `/products/${p.id}`)
                    }}
                  >
                    <ProductInPersonaListItem
                      product={p}
                    />
                </Flex>
              </ProductProvider>
            );
          })}
        </Box>
      </Box>
      <Modal isOpen={modalAddProduct.isOpen} onClose={modalAddProduct.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Add Product to Persona</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <AddProductToPersonaForm
                personaId={personaId}
                hideSave={true}
                onCreateNew={() => {
                  modalAddProduct.onClose();
                  modalCreateProduct.onOpen();
                }}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  modalAddProduct.onClose();
                  refreshProductList();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalAddProduct.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalCreateProduct.isOpen} onClose={modalCreateProduct.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>
            <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack/>}
              aria-label='New'
              mr={2}
              onClick={() => {
                modalCreateProduct.onClose();
                modalAddProduct.onOpen();
              }}
            />
            New Product</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <ProductForm
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={(newProduct) => {
                  linkNewProductToPersona(newProduct)
                }}
                ref={ref2}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={() => {
                modalCreateProduct.onClose();
                modalAddProduct.onOpen();
              }}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
