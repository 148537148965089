import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, Flex, Icon, Tooltip, useDisclosure, Menu, MenuButton, Portal, MenuList, MenuItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Box, IconButton } from '@chakra-ui/react';
import ExperienceInsightTags from '../../../experiences/component/experiencesInsightTags';
import useProductApi from '../../../products/lib/useProductsApi';
import usePersonaApi from '../../lib/usePersonaApi';
import { MdDevices, MdDragIndicator, MdFace, MdMoreVert, MdOutlineRoute } from 'react-icons/md';
import ProductProvider from '@/products/lib/productProvider';
import PersonaProvider from '../../lib/personaProvider';
import ProductLogo from '@/products/components/productLogo';
import PersonaThumbnail from '../../components/personaThumbnail';
import useExperienceApi from '@/experiences/lib/useExperienceApi';
import { PersonaPageContext } from '../lib/personaPageContext';
import MoveExperienceInPersonaToGroupForm from './moveExperienceInPersonaToGroupForm';
import { PersonaContext } from '../../lib/personaContext';
import { Link, useNavigate } from 'react-router-dom';

export default function ExperiencesInPersonaListItem(props) {

  const {
    refreshExperienceList,
    refreshProductList,
    experienceGroupList,
  } = useContext(PersonaPageContext);

  const {
    experience,
  } = props;

  const {
    personaId,
    rights,
  } = useContext(PersonaContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [productList, setProductList] = useState(false);
  const [personaList, setPersonaList] = useState(false);
  const productApi = useProductApi();
  const personaApi = usePersonaApi();

  const navigate = useNavigate();

  const modalMoveToGroup = useDisclosure();
  const ref1 = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const experienceApi = useExperienceApi();

  const fetchData = async () => {
    try {
      const promises = [
        productApi.fetchProductsByExperienceId(experience.id),
        personaApi.fetchManyPersonaByExperienceId(experience.id)
      ];
      const result = await Promise.all(promises);
      setProductList(result[0]);
      setPersonaList(result[1]);
    } catch (e) {
      console.error(e)
    }
  };

  const leaveTheGroup = async () => {
    try {
      await experienceApi.moveExperienceToGroupInPersona(
        personaId,
        experience.id,
        null
      );
      await Promise.all[
        refreshExperienceList(),
        refreshProductList()
      ]
      navigate(`/persona/${personaId}`);
    } catch (error) {
      console.error(error);
    }
  }

  const removeExperienceFromPersona = async () =>  {
    try {
      setIsDeleting(true);
      await experienceApi.removeExperienceFromPersona(experience.experiences_persona_id);
      await refreshExperienceList()
      setIsDeleting(false);
    } catch (e) {
      console.error(e);
    }
  }


  useEffect(() => {
    if (experience) {
      fetchData()
    }
  }, [experience]);

  return (
    <>
      <Flex
        px={3}
        py={2}
        backgroundColor="white"
        role={'group'}
        _hover={{
          background: "background.grey",
        }}
        position={"relative"}
      >
        {rights.can_update && (
          <Flex
            position={"absolute"}
            top="0"
            left={"0"}
            height={"100%"}
            opacity={0}
            _groupHover={{ opacity: '1' }}
            cursor={"grab"}
            py={2}
            pr={3}
          >
            <Icon
              as={MdDragIndicator}
              boxSize={'20px'}
              color={"text.light.grey"}
              mr={2}
            />
          </Flex>
        )}
        <Flex
          flex={1}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex
              alignItems={"center"}
              mb={1}
            >
              <Icon
                as={MdOutlineRoute}
                boxSize={'15px'}
                mr={2}
              />
              <Text noOfLines={2} >{experience.objective}</Text>
            </Flex>
            <ExperienceInsightTags
              experienceId={experience.id}
              personaId={personaId}
            />
          </Flex>
          <Flex
            mt={3}
            alignSelf={"flex-end"}
          >
            {(personaList && personaList.length > 0) && (
              <Flex
                alignItems={"center"}
                mr={3}
              >
                <Icon
                  as={MdFace}
                  size="20"
                  color="text.medium.grey"
                />
                {personaList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                      <Flex
                        ml={2}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault();
                          navigate(`/persona/${p.id}`);
                        }}
                      >
                        <PersonaProvider
                          personaId={p.id}
                          originalPersona={p}
                          needRights={false}
                          needRole={false}
                          needEditionRequest={false}
                        >
                          <PersonaThumbnail
                            size={20}
                            hasAcronym={true}
                          />
                        </PersonaProvider>
                      </Flex>
                  </Tooltip>
                ))}
              </Flex>
            )}
            {(productList && productList.length > 0) && (
              <Flex alignItems={"center"}>
                <Icon
                  as={MdDevices}
                  size="20"
                  color="text.medium.grey"
                />
                {productList && productList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                      <Flex
                        ml={2}
                        onClick={(e)=> {
                          e.stopPropagation()
                          e.preventDefault()
                          navigate(`/products/${p.id}`)
                        }}
                      >
                        <ProductProvider
                          productId={p.id}
                          orignalProduct={p}
                          needRights={false}
                          needRole={false}
                          needEditionRequest={false}
                        >
                          <ProductLogo
                            size={20}
                            hasAcronym={true}
                          />
                        </ProductProvider>
                      </Flex>
                  </Tooltip>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
        {rights.can_update && (
          <Flex
            height={"100%"}
            pl={3}
            alignItems={"center"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Menu >
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={IconButton}
                    icon={<MdMoreVert />}
                    fontSize={"18px"}
                    size="md"
                    variant='greyTransparent'
                  />
                  <Portal>
                    <MenuList zIndex="popover">
                      <MenuItem onClick={(e) => {
                        navigate(`/experiences/${experience.id}`);
                      }} >Go to Experience</MenuItem>
                      {(experienceGroupList && experienceGroupList.length > 0) &&
                        <MenuItem onClick={modalMoveToGroup.onOpen} >Move to Group</MenuItem>
                      }
                      {experience.persona_experience_group_id &&
                        <MenuItem
                          onClick={leaveTheGroup}
                        >Leave the group</MenuItem>
                      }
                      <MenuItem onClick={removeExperienceFromPersona} >Remove from Persona</MenuItem>
                    </MenuList>
                  </Portal>
                </>
              )}
            </Menu>
          </Flex>
        )}
      </Flex>
      <Modal isOpen={modalMoveToGroup.isOpen} onClose={modalMoveToGroup.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Move to Group</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <MoveExperienceInPersonaToGroupForm
                personaId={personaId}
                experienceId={experience.id}
                hideSave={true}
                onValidityChange={setIsValid}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  Promise.all([
                    refreshExperienceList(),
                    refreshProductList()
                  ])
                  modalMoveToGroup.onClose();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="blueOutline"
              onClick={modalMoveToGroup.onClose}
            >Cancel</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

}
