'use client'

import React, {useContext, useEffect} from 'react';
import {Button, FormControl, FormErrorMessage, FormLabel, Input, Skeleton} from '@chakra-ui/react';
import useOrganisationApi from '../lib/useOrganisationApi';
import {Box} from '@chakra-ui/layout';
import ImageInput from '../../components/imageInput';
import {forwardRef} from '@chakra-ui/system';
import {Controller, useForm} from 'react-hook-form';
import { useState } from 'react';
import { OrganisationContext } from '../lib/organisationContext';

const OrganisationLogoForm = forwardRef((props, ref) => {

  const { organisation } = useContext(OrganisationContext);
  const [downloading, setDownloading] = useState(false);

  const {
    hideSave,
    onSubmitting,
    onSubmitted,
    onValidityChange,
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const organisationApi = useOrganisationApi();

  const fetchLogo = async () => {
    try {
      if(organisation.has_logo) {
        setDownloading(true)
        const newLogo = await organisationApi.downloadLogo(organisation.id, 256);
        setValue('logo', { originalImage: newLogo })
        setDownloading(false)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const image = data.logo;
      if(image && organisation) {
        await organisationApi.uploadLogo(image, organisation.id)
      } else if (!image && organisation) {
        await organisationApi.deleteLogo(organisation.id)
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (organisation) {
      fetchLogo();
    }
  }, [organisation]);

  return (
    <>
      <Box
        border="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        backgroundColor="white"
        p={3}
      >
        <form onSubmit={handleSubmit(onSubmit)} >
          <Controller
            control={control}
            name="logo"
            rules={{}}
            render={({
                       field: { onChange, onBlur, value, name, ref },
                       fieldState: { invalid, error }
                     }) => (
              <FormControl isInvalid={errors && errors.experience}>
                <FormLabel>Upload an image</FormLabel>
                {downloading && (<Skeleton height={"40px"} />)}
                {!downloading && (
                  <ImageInput
                    maxWidth={100}
                    value={value}
                    crop={{
                      aspect: 1,
                      zoom: 1,
                      x: 0,
                      y: 0
                    }}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    onBlur={onBlur}
                    outputWidths={[64, 128, 256]}
                    outputFormat="webp"
                  />
                )}
                <FormErrorMessage>{errors && errors.experience}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid && downloading}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default OrganisationLogoForm;


