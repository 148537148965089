import React, { useContext, useState } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import ProfileThumbnail from '../../profile/component/profileThumbnail';
import { ProfileContext } from '../../profile/lib/profileContext';
import { OrganisationContext } from '../lib/organisationContext';
import { MdArrowDropDown } from 'react-icons/md';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Button} from '@chakra-ui/react';
import useOrganisationApi from '../lib/useOrganisationApi';
import StripeContext from '../../subscriptions/lib/stripeContext';
import { AuthContext } from '../../auth/lib/authContext';
import { UserOrganisationContext } from '../lib/userOrganisationContext';

const OrganisationMember = (props) => {
  const { member } = props;
  const { profile} = useContext(ProfileContext)
  const authContext = useContext(AuthContext)
  const { updateQuantity } = useContext(StripeContext)
  const {rights,
    refreshProfilesNotAdded,
    refreshMembers,
    organisation} = useContext(OrganisationContext)
  const userOrganisationContext = useContext(UserOrganisationContext)
  const organisationApi = useOrganisationApi()
  const [isRevoking, setIsRevoking] = useState(false)

  const updateRole = async (role) => {
    try {
      await organisationApi.updateRole(
        userOrganisationContext.organisation.id,
        profile.id,
        role);
       refreshMembers();
    } catch (error) {
      console.error(error);
    }
  }

  const revokeAccess = async () => {
    try {
      setIsRevoking(true);
      await organisationApi.revokeAccess(profile.id, organisation.id);
      setIsRevoking(false);
      await refreshProfilesNotAdded();
      await refreshMembers();
      updateQuantity();
    } catch (error) {
      console.error(error)
    }
  }

  const leave = async () => {
    try {
      setIsRevoking(true);
      await organisationApi.revokeAccess(authContext.profile.id, organisation.id);
      setIsRevoking(false);
      await userOrganisationContext.refreshOrganisation();
      await refreshMembers();
      await authContext.refresh();
      updateQuantity();
    } catch (error) {
      console.error(error)
    }
  }

  return <Flex
    flex={"1"}
    alignItems={"center"}
    justifyContent={"space-between"}
    backgroundColor={"white"}
    px={3}
    py={2}
  >
    <Flex gap={2}>
      <ProfileThumbnail size={45} />
      <Flex
        flexDirection={"column"}
      >
        <Text>{profile.pseudo}</Text>
        <Text
          fontSize={"12px"}
          color={"text.medium.grey"}>{profile.email}</Text>
      </Flex>
    </Flex>
    <Flex>
      {((rights.can_update && member.role === 'admin') ||
      (!rights.can_update &&  member.profile_id !== authContext?.profile?.id)
      ) && (
        <Text>{member.role}</Text>
      )}
      {(rights.can_update && member.role !== 'admin'
        &&  member.profile_id !== authContext?.profile?.id
      ) && (
         <Menu ml={2} >
         {({ isOpen }) => (
           <>
             <MenuButton
               isActive={isOpen}
               as={Button}
               rightIcon={<MdArrowDropDown />}
               variant='greyTransparent'
               px={2}
               isLoading={isRevoking}
               textTransform={'capitalize'}
             >{member.role}</MenuButton>
             <MenuList>
             {member.role === 'manager' && 
                <MenuItem
                  onClick={() => { updateRole('regular') }}
                 >
                  Regular
                </MenuItem>
              }
              {member.role === 'regular' && 
                <MenuItem 
                onClick={() => { updateRole('manager') }}  >
                  Manager
                </MenuItem>
              }
               <MenuItem onClick={revokeAccess} >
                 <Text color='text.medium.red' >Revoke Access</Text>
               </MenuItem>
             </MenuList>
           </>
         )}
       </Menu>
      )}
      {(member.profile_id === authContext?.profile?.id && member.role !== 'admin') && (
         <Menu ml={2} >
         {({ isOpen }) => (
           <>
             <MenuButton
               isActive={isOpen}
               as={Button}
               rightIcon={<MdArrowDropDown />}
               variant='greyTransparent'
               px={2}
               isLoading={isRevoking}
               textTransform={'capitalize'}
             >{member.role}</MenuButton>
             <MenuList>
               <MenuItem onClick={leave} >
                 <Text color='text.medium.red' >Leave</Text>
               </MenuItem>
             </MenuList>
           </>
         )}
       </Menu>
      )}
    </Flex>
  </Flex>;
}


export default OrganisationMember;