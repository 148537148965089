import React, {useContext, useEffect, useState} from 'react';
import {Text, Flex, Box, Spacer, Tooltip} from '@chakra-ui/react';
import ProductLogo from './productLogo';
import ProductInsightTags from './productInsightTags';
import { ProductContext } from '../lib/productContext';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../profile/lib/profileContext';
import ProfileThumbnail from '../../profile/component/profileThumbnail';
import { AuthContext } from '../../auth/lib/authContext';

export default function ProductListItem() {

  const { product } = useContext(ProductContext);
  const {profile} = useContext(ProfileContext);
  const authContext = useContext(AuthContext);

  return (
    <Link to={`/products/${product.id}`} >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={3}
        backgroundColor="white"
        _hover={{
          background: "background.grey",
        }}
      >
        <Flex
        alignItems={'center'}
        width={"100%"}
        maxWidth={"250px"}
        >
          <ProductLogo
            size={40}
            productId={product.id}
            hasLogo={product.has_logo}
            productName={product.name}
          />
          <Box ml={2} width={"100%"} >
            <Text
              w={"100%"}
              fontSize={16}
              noOfLines={"1"}
            >{product.name}</Text>
          </Box>
        </Flex>
        <Tooltip label="Owner">
          <Flex
            alignItems={"center"}
            gap={1}
            width={"150px"}
            >
            <ProfileThumbnail size={25}/>
            {profile.id === authContext.profile.id && 
              <Text width={"100%"} fontSize={"12px"} noOfLines={1}>Me</Text>
            }
            {profile.id !== authContext.profile.id && 
              <Text width={"100%"} fontSize={"12px"} noOfLines={1}>{profile.pseudo}</Text>
            }
          </Flex>
        </Tooltip>
        <ProductInsightTags
          productId={product.id}
          insightMetadata={product.insight_metadata}
        />
      </Flex>
    </Link>
  );
}
