import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Heading, Icon, IconButton, Skeleton, Spinner, Tooltip } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/layout';
import { MdOutlinePlayCircle } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';
import { ExperienceContext } from '../../lib/experienceContext';

export default function Prototype(props) {

  const experienceContext = useContext(ExperienceContext)
  const disclosure = useDisclosure();
  const [isLoading, setIsLoading] = useState(true)
  const [link, setLink] = useState('')

  
  const onIframeLoaded = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if(experienceContext?.experience?.prototype_link) {
      let newLink = experienceContext?.experience?.prototype_link.replace('www.figma.com', 'embed.figma.com')
      newLink = newLink.replace('&embed-host=share', '');
      newLink = newLink + '&embed-host=share';
      setLink(newLink);
    }
  }, [experienceContext])
  return (
     <>
     <Flex borderRadius={"2px"} overflow={"hidden"}>
      <Tooltip label={"Open Prototype"}>
        <IconButton
          icon={<Icon as={MdOutlinePlayCircle} fontSize={"18px"}/>}
          onClick={disclosure.onOpen}
          variant="coralOutline"
        />
      </Tooltip>
     </Flex>
     <Modal
        isOpen={disclosure.isOpen}
        onClose={() => {
          disclosure.onClose();
          setIsLoading(true);
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"calc(100% - 20px)"} maxH={"calc(100vh - 20px)"} h={"100%"} py={0} >
          <ModalHeader py={1} px={3}>
            <Flex>
              <Icon alignSelf={"center"} as={MdOutlinePlayCircle} marginRight={1} />
              <Text>Prototype</Text>
            </Flex>
           </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading && (
              <Flex alignItems={"center"} justifyContent={"center"} w="100%" h={"100%"} >
                <Spinner />
              </Flex>
              )
            }
            {link && (
            <iframe
              onLoad={onIframeLoaded}
              width="100%" style={{height: "calc(100% + 48px)"}} src={link} allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
     </>
  );
}
