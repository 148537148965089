import React, {useContext, useEffect, useRef, useState} from 'react';
import useProductApi from '../lib/useProductsApi';
import {Button, Divider, Icon, IconButton, Skeleton, Tag} from '@chakra-ui/react';
import ProductListItem from './productListItem';
import {Box, Flex, Stack, Text} from '@chakra-ui/layout';
import {MdAdd, MdDevices} from 'react-icons/md';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import { useNavigate } from 'react-router-dom';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import ProductForm from './productForm';
import ProductProvider from '../lib/productProvider';
import { OrganisationContext } from '../../organisation/lib/organisationContext';
import { AuthContext } from '../../auth/lib/authContext';
import ProfileProvider from '../../profile/lib/profileProvider';

export default function ProductList(props) {

  const {
    insidePaddings = true,
    emptyHeight,
    maxHeight } = props;
  const {
    organisation,
    hasOrganisation
  } = useContext(OrganisationContext)
  const {
    profile
  } = useContext(AuthContext)
  const [products, setProducts] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const productApi = useProductApi();
  const [tab, setTab] = useState('mine');
  const [isInit, setIsInit] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();
  const ref = useRef(null);

  async function getProducts() {
    setIsFetching(true);
    let newProducts;
    if(tab === 'mine') {
      newProducts = await productApi.fetchProductListByUserId(profile.id);
    } else {
      newProducts = await productApi.fetchProductListByOrganisation(organisation.id);
    }
    setProducts(newProducts);
    setIsFetching(false);
  }

  const getHeight = () => {
    if(emptyHeight) {
      return emptyHeight
    } else {
      return "100%"
    }
  }

  useEffect(() => {
    if(!isInit) {
      getProducts();
      setIsInit(true);
    }
  }, [isInit]);

  useEffect(() => {
    if(products) {
      getProducts();
    }
  }, [tab]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        w={"100%"}
        h={"100%"}
        >
        <Flex
        alignItems='center'
        py={2}
        px={3}
        backgroundColor="white"
        borderBottom={"1px solid"}
        borderColor={"divider.grey"}
        >
          <Icon
            as={MdDevices}
            boxSize={'18px'}
            color={'fill.purple'}
            mr={2}
            />
          <Text
            fontSize="16px"
            color="text.medium.purple"
            mr={3}
          >Products</Text>
          {(!isFetching && products && products.length > 0) && (
            <IconButton
              icon={<MdAdd />}
              aria-label='New'
              variant='purpleOutline'
              onClick={onOpen}
            />
          )}
        </Flex>
        {hasOrganisation && (
            <Flex
              alignItems='center'
              py={1}
              px={3}
              backgroundColor="white"
              borderBottom={"1px solid"}
              borderColor={"divider.grey"}
              gap={1}
            >
              <Tag
                variant={(tab === 'mine' ? "purple" : 'grey')}
                cursor={"pointer"}
                onClick={() => {
                  setTab('mine')
                }}
                >Mine</Tag>
              <Tag
                variant={(tab === 'organisation' ? "purple" : 'grey')}
                cursor={"pointer"}
                onClick={() => {
                  setTab('organisation')
                }}
              >{organisation.name}'s</Tag>
            </Flex>
        )}
        <Flex
          overflow={"auto"}
          flexDirection={"column"}
          h={(!isFetching && products && products.length === 0) ? getHeight() : "auto"}
          p={insidePaddings ? 3 : 0}
        >
          { isFetching && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
          )}
          <Flex
           flexDirection={"column"}
           border={(insidePaddings && products && products.length > 0) ? "1px solid" : "none"}
           borderColor="divider.grey"
           w="100%"
           h="100%"
           maxH={maxHeight}
          >
          {(!isFetching && products && products.length === 0) && (
            <Flex
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent={"center"}
            >
              <Button
                onClick={onOpen}
                leftIcon={<MdAdd />}
                variant="purpleOutline"
                >New Product</Button>
            </Flex>
          )}
          { (!isFetching && products) && products.map((product, i) => {
          return (
            <ProductProvider
              key={i}
              productId={product.id}
              originalProduct={product}
              >
              <ProfileProvider
                profileId={product.owner.id}
                originalProfile={product.owner}
              >
                <Box >
                  { i > 0 && <Divider />}
                  <ProductListItem />
                </Box>
              </ProfileProvider>
            </ProductProvider>
          );
        })}
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>New Product</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <ProductForm
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={(newProduct) => {
                  setIsAdding(false);
                  navigate( `/products/${newProduct.id}`);
                  onClose();
                }}
                onValidityChange={setIsValid}
                ref={ref}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={onClose}
            >Cancel</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
  );
}
