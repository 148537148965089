import React, { useContext, useEffect } from 'react';
import { Spacer } from '@chakra-ui/layout';
import { Button, Flex, IconButton, Tooltip, Divider, Text } from '@chakra-ui/react';
import { MdDevices, MdFace, MdHome, MdOutlineArrowCircleLeft, MdOutlineBusiness } from 'react-icons/md';
import { MenuContext } from '../lib/menuContext';
import { Link } from 'react-router-dom';
import { UserOrganisationContext } from '../organisation/lib/userOrganisationContext';
import { OrganisationContext } from '../organisation/lib/organisationContext';
import OrganisationLogo from '../organisation/component/organisationLogo';

export default function SideMenu() {

  const menuContext = useContext(MenuContext)
  const userOrganisationContext = useContext(UserOrganisationContext)
  const organisationContext = useContext(OrganisationContext)

  return (
    <>
      {menuContext.visibility && (
        <Flex
          w={200}
          flexShrink={0}
          borderRight="1px"
          borderColor="divider.grey"
          backgroundColor="white"
          flexDirection={'column'}
          py={3}
          px={3}
          gap={2}
        >
          <Flex
            flexDirection={"column"}
            gap={2}
            alignItems={"stretch"}
          >
            <Link to="/"  >
              <Button
                boxSizing='borderBox'
                display="flex"
                leftIcon={<MdHome size={'15px'} />}
                justifyContent={'start'}
                height="auto"
                w={'100%'}
                px={3}
                py={2}
                variant={
                  (window.location.href.includes('/home') ? 'coralOutline' : 'greyTransparent')
                }
              >Home</Button>
            </Link>
            <Link to="/persona">
              <Button
                leftIcon={<MdFace size={'15px'} />}
                boxSizing='borderBox'
                display="flex"
                justifyContent={'start'}
                height="auto"
                w={'100%'}
                px={3}
                py={2}
                variant={
                  (window.location.href.includes('/persona') ? 'blueOutline' : 'greyTransparent')
                }
              >Personas</Button>
            </Link>
            <Link to="/products">
              <Button
                leftIcon={<MdDevices size={'15px'} />}
                boxSizing='borderBox'
                display="flex"
                justifyContent={'start'}
                height="auto"
                w={'100%'}
                px={3}
                py={2}
                variant={
                  (window.location.href.includes('/products') ? 'purpleOutline' : 'greyTransparent')
                }
              >Products</Button>
            </Link>
          </Flex>
          {(userOrganisationContext.hasOrganisation 
            || userOrganisationContext.hasPotentialOrganisation)
          && (
            <>
              <Divider />
              <Flex
                gap={2}
                width={"100%"}
                flexDirection={"column"}
                alignItems={"stretch"}
              >
                <Link to="/organisation">
                  <Button
                    leftIcon={<OrganisationLogo
                      size={25}
                    />}
                    boxSizing='borderBox'
                    display="flex"
                    justifyContent={'start'}
                    height="auto"
                    w={'100%'}
                    px={3}
                    py={2}
                    variant={
                      (window.location.href.includes('/organisation') ? 'greyOutline' : 'greyTransparent')
                    }
                  >
                    <Text
                      textOverflow="ellipsis"
                      overflow={"hidden"}
                      >
                      {organisationContext?.organisation?.name}
                    </Text>
                  </Button>
                </Link>
              </Flex>
            </>
          )}
          <Spacer />
          <Tooltip label='Hide Menu' >
            <Flex>
              <IconButton
                variant="greyTransparent"
                fontSize='20px'
                color="text.medium.grey"
                icon={<MdOutlineArrowCircleLeft />}
                onClick={menuContext.close}
              />
            </Flex>
          </Tooltip>
        </Flex>
      )}
    </>
  );
}
