'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/layout';
import { ExperienceContext } from '../../lib/experienceContext';
import { MdArrowBack, MdOutlineRoute } from 'react-icons/md';
import { Icon, IconButton, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import PersonaInExperienceList from './personaInExperienceList';
import ProductInExperienceList from './productInExperienceList';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import ExperienceObjectiveForm from './experienceObjectiveForm';
import ExperienceAsideEditMenu from './experienceAsideEditMenu';
import { useTheme } from '@emotion/react';
import Prototype from './prototype';

export default function ExperienceAside(props) {

  const theme = useTheme();

  const {
    experience,
    refresh,
    rights,
    isFetchingExperience
  } = useContext(ExperienceContext);
  const navigate = useNavigate();
  const [space, setSpace] = useState()

  useEffect(() => {
    if (theme) {
      const spaceCurrent = parseInt(theme.space[3].replace('px', ''));
      setSpace((spaceCurrent * 2))
    }
  }, [theme])

  return (
    <>
      <Flex
        borderRight="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        backgroundColor="white"
        alignItems={'stretch'}
        flexDirection={'column'}
        height={"100%"}
        py={3}
        position="relative"
        pt="60px"
      >
        <IconButton
          left={1}
          top={1}
          position="absolute"
          variant="greyTransparent"
          icon={<MdArrowBack />}
          size="lg"
          onClick={() => {
            navigate(-1)
          }}
        />
        {isFetchingExperience && (
          <Flex
            flexDirection={"column"}
            width={"100%"}
            gap={3}
            px={3}>
            <Skeleton w={"100%"} height={"100px"} />
            <Skeleton w={"100%"} height={"100px"} />
            <Skeleton w={"100%"} height={"100px"}  />
          </Flex>
        )}
        {experience && (
          <>
            <Flex
              pb="3"
              flexDirection={"column"}
              alignItems={"flex-start"}
              px={3}
            >
              <Flex
                alignItems={'center'}
                >
                <Icon
                  as={MdOutlineRoute}
                  color="text.medium.grey"
                  boxSize="12px"
                  mr={1}
                />
                <Text
                  fontSize={12}
                  color="text.medium.grey"
                >
                  Experience
                </Text>
              </Flex>
              <InlineFormWrapper
                width={"100%"}
                canEdit={rights.can_update}
                value={
                  <Text
                    fontSize={18}
                    color="fill.coral"
                  >
                    {experience.objective}
                  </Text>
                }
              >
                {(close) => (
                  <Flex w={"100%"} pb={2}>
                    <ExperienceObjectiveForm
                      onSubmitted={close}
                      onCancel={close}
                    />
                  </Flex>
                 
                )}
              </InlineFormWrapper>
              {rights.can_update && (
                <Flex alignSelf={"center"} mt={2} gap={2}>
                  {experience.prototype_link && <Prototype />}
                  <ExperienceAsideEditMenu />
                </Flex>
              )}
            </Flex>
            <Divider ml={3} variant="coral" width={"calc(100% - " + space + "px)"} />
            <Box py={3}>
              <PersonaInExperienceList experienceId={experience.id}></PersonaInExperienceList>
            </Box>
            <Divider ml={3} variant="coral" width={"calc(100% - " + space + "px)"} />
            <Box py={3}>
              <ProductInExperienceList experienceId={experience.id}></ProductInExperienceList>
            </Box>
          </>
        )}
      </Flex>
    </>
  )
}




