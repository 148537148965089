'use client'

import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/layout';
import ExperiencesInProductList from '@/products/[id]/components/experiencesInProductList';
import ProductAside from './components/productAside';
import ProductProvider from '../lib/productProvider';
import ProductMembers from './components/productMembers';
import ProductValueProposition from './components/productValueProposition';
import ProductPageProvider from './lib/productPageProvider';
import InsightCardList from '@/insight/components/insightCardList';
import useInsightApi from '@/insight/lib/useInsightApi';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import { useParams } from 'react-router-dom';
import LoadingProduct from './LoadingProduct';
import InsightTab from '../../insight/components/insightTab';

export default function ProductDetailPage() {

  const [fetched, setFetched] = useState(false);
  const [previouslyFetched, setPreviouslyFetched] = useState(false);
  const insightApi = useInsightApi();
  const [lists, setLists] = useState();
  const [loadingProduct, setLoadingProduct] = useState(true);

  const params = useParams();

  const fetchData = async () => {
    try {
      const result = await Promise.all([
        insightApi.fetchInsightListByProductId(params.id, { type: insightTypeDictionnary.negative }),
        insightApi.fetchInsightListByProductId(params.id, { type: insightTypeDictionnary.improvement }),
        insightApi.fetchInsightListByProductId(params.id, { type: insightTypeDictionnary.positive }),
        insightApi.fetchInsightListByProductId(params.id, { type: insightTypeDictionnary.motivation }),
        insightApi.fetchInsightListByProductId(params.id, { type: insightTypeDictionnary.behavior }),
      ])
      setLists({
        [insightTypeDictionnary.negative]: result[0],
        [insightTypeDictionnary.improvement]: result[1],
        [insightTypeDictionnary.positive]: result[2],
        [insightTypeDictionnary.motivation]: result[3],
        [insightTypeDictionnary.behavior]: result[4],
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (fetched && !previouslyFetched) {
      setPreviouslyFetched(true);
      fetchData();
    }
  }, [fetched]);

  useEffect(() => {
    if (params.id && !fetched) {
      setFetched(true);
    }
  }, [params.id])

  return (
    <>
      {params.id && (
        <>
          {loadingProduct && (
            <LoadingProduct />
          )}
          <ProductProvider
            productId={params.id}
            onInit={() => {
              setLoadingProduct(false);
            }}
          >
            <ProductPageProvider>
              {!loadingProduct && (
                <Flex
                  flex="1"
                  flexDirection="row"
                  alignItems={"stretch"}
                  overflow={"auto"}
                >
                  <ProductAside />
                  <Flex
                    flex="1"
                    flexDirection={"column"}
                    overflow={"auto"}
                  >
                    <Flex
                      px={3}
                      py={2}
                      bg="white"
                      borderBottom="1px"
                      borderColor="divider.grey"
                      borderStyle="solid"
                      justifyContent="flex-end"
                    >
                      <ProductMembers />
                    </Flex>
                    <Flex
                      p={3}
                      flex="1"
                      overflow={"auto"}
                      flexDirection={'column'}
                    >
                      <ProductValueProposition />
                      <InsightTab
                        lists={lists}
                        onSetAsOutdated={fetchData}
                      />
                      <ExperiencesInProductList
                        emptyHeight={"300px"}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </ProductPageProvider>
          </ProductProvider>
        </>)}
    </>
  )
}




