import React, { useContext } from 'react';
import {Box, Tooltip} from '@chakra-ui/react';
import {Flex, Text} from '@chakra-ui/layout';
import PersonaThumbnail from './personaThumbnail';
import PersonaInsightTags from '../[id]/components/personaInsightTags';
import { PersonaContext } from '../lib/personaContext';
import { ProfileContext } from '../../profile/lib/profileContext';
import ProfileThumbnail from '../../profile/component/profileThumbnail';
import { AuthContext } from '../../auth/lib/authContext';

export default function PersonaListItem() {

  const {persona} = useContext(PersonaContext);
  const {profile} = useContext(ProfileContext);
  const authContext = useContext(AuthContext);

  return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={3}
        backgroundColor="white"
        role="group"
        _hover={{
          background: "background.grey",
        }}
      >
        <Flex
            alignItems='center' 
            width={"100%"}
            maxWidth={"250px"}
          >
          <PersonaThumbnail size={40}/>
          <Box
            ml={2}
            width={"100%"}
            >
            <Text
              fontSize={16}
              w={"100%"}
              noOfLines={"1"}
              >{persona.name}</Text>
          </Box>
        </Flex>
        <Tooltip label="Owner">
          <Flex
            alignItems={"center"}
            gap={1}
            width={"150px"}
            >
            <ProfileThumbnail size={25}/>
            {profile.id === authContext.profile.id && 
              <Text width={"100%"} fontSize={"12px"} noOfLines={1}>Me</Text>
            }
            {profile.id !== authContext.profile.id && 
              <Text width={"100%"} fontSize={"12px"} noOfLines={1}>{profile.pseudo}</Text>
            }
          </Flex>
        </Tooltip>
        <PersonaInsightTags 
          personaId={persona.id}
          insightMetadata={persona.insight_metadata}
        />
      </Flex>
  );
}
