import React, { useContext, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { MdOutlineWarning } from 'react-icons/md';
import { useToast } from '@chakra-ui/react'
import useInsightApi from '../lib/useInsightApi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
  Icon,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';

export default function InsightDeleteModal(props) {

  const {
    insight,
  } = useContext(InsightContext)

  const {
    onDelete,
    disclosure
  } = props;
  const insightApi = useInsightApi();
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteInsight = async () => {
    try {
      setIsDeleting(true)
      await insightApi.deleteInsight(insight.id);
      toast({
        position: 'bottom-right',
        description: "Insight Deleted",
        duration: 1000,
        isClosable: true,
      });
      setIsDeleting(false);
      if (onDelete) {
        onDelete()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Delete Insight</ModalHeader>
          <ModalBody p={3}>
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
            >
              <Icon
                as={MdOutlineWarning}
                color={'fill.red'}
                boxSize={'18px'}
                mr={3}
              />
              <Text textStyle="body" color={'fill.red'} >Are you sure to delete this insight?</Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={disclosure.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isDeleting}
              onClick={deleteInsight}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
