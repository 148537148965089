'use client'

import React, {useEffect, useState} from 'react';
import {Skeleton, Tag, TagLabel, TagRightIcon, Tooltip} from '@chakra-ui/react'
import { MdClose} from 'react-icons/md';
import useProfileApi from '@/profile/lib/useProfileApi';
import ProfileThumbnail from '@/profile/component/profileThumbnail';
import ProfileProvider from '@/profile/lib/profileProvider';

export default function PersonaSelectedMembersListItem(props) {
  
  const {invited, onFetchProfileStatus} = props;

  const profileApi = useProfileApi();
  const [profile, setProfile] = useState();
  const [hasFetched, setHasFetched] = useState(false);

  const checkUsersProfile = async () => {
    try {
      const newProfile = await profileApi.fetchProfileByUserEmail(invited);
      setProfile(newProfile);
      if(onFetchProfileStatus) {
        onFetchProfileStatus({
          email: invited,
          profile: !!newProfile
        });
      }
      setHasFetched(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(invited) {
      checkUsersProfile();
    }
  }, [invited]);
  
  return (
    <>
      {(hasFetched &&  profile) && (
        <Tooltip label={invited}>
          <Tag
            variant="neutral"
            cursor="pointer">
              <ProfileProvider
                profileId={profile.id}
                originalProfile={profile}
                >
                <ProfileThumbnail size={15} />  
              </ProfileProvider>
            <TagLabel ml={2}>{profile.pseudo}</TagLabel>
            <TagRightIcon boxSize='12px' as={MdClose}></TagRightIcon>
          </Tag>
        </Tooltip>
      )}
      {(hasFetched && !profile) && (
        <Tag
          variant="orange"
          cursor="pointer">
          <TagLabel>{invited}</TagLabel>
          <TagRightIcon boxSize='12px' as={MdClose}></TagRightIcon>
        </Tag>
      )}
      {(!hasFetched) && (
        <Tag
          cursor="pointer">
            <Skeleton height={"14px"} width={"100px"} />
        </Tag>
      )}
    </>
  )
}




