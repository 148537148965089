import React, { useContext, useEffect, useState } from 'react';
import { Flex, Box, Text } from '@chakra-ui/layout';
import { MdPerson } from 'react-icons/md';
import { Icon } from '@chakra-ui/icon';
import { OrganisationContext } from '../lib/organisationContext';
import { SkeletonCircle } from '@chakra-ui/react';
import useOrganisationApi from '../lib/useOrganisationApi';

export default function OrganisationLogo(props) {

  const {
    size
  } = props;

  const [logo, setLogo] = useState(null);
  const [acronym, setAcronym] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const organisationApi = useOrganisationApi();
  const { organisation } = useContext(OrganisationContext);

  const fetchLogo = async () => {
    let imageSize;
    if (size > 128) {
      imageSize = 256;
    } else if (size > 64 && size <= 128) {
      imageSize = 128;
    } else if (size <= 64) {
      imageSize = 64;
    }
    setDownloading(true)
    const newLogo = await organisationApi.downloadLogo(organisation.id, imageSize);
    setDownloading(false);
    setLogo(newLogo);
  };

  const updateAcronym = async () => {
    const nameArray = organisation.name.split(' ');
    if (nameArray.length > 1) {
      setAcronym(nameArray[0][0] + (nameArray[1] ? nameArray[1][0] : ''));
    } else {
      setAcronym(nameArray[0][0]);
    }
  };

  useEffect(() => {
    if (organisation && organisation.has_logo) {
      fetchLogo()
    } else {
      setLogo(null)
      setAcronym(null);
    }
  }, [organisation]);

  useEffect(() => {
    if (organisation?.name) {
      updateAcronym();
    }
  }, [organisation]);

  return (
    <>
      {organisation && (
        <Flex
          border="1px"
          borderRadius="50%"
          backgroundColor="background.grey"
          borderColor="divider.grey"
          alignItems='center'
          justifyContent='center'
          w={size + 'px'}
          h={size + 'px'}
        >
          {((downloading && !logo) && organisation.has_logo) && (
            <SkeletonCircle size={size + 'px'} />
          )}
          {(organisation.has_logo && logo) && (
            <Box
              w={size + 'px'}
              h={size + 'px'}
              borderRadius="50%"
              style={{ overflow: 'hidden' }}
            >
              <img src={logo} alt="logo" style={{ width: '100%', height: '100%' }} />
            </Box>
          )}
          {!organisation.has_logo && !acronym && (
            <Icon
              as={MdPerson}
              color={'text.secondaryGrey'}
              boxSize={((size * 0.6) + 'px')}
            />
          )}
          {!organisation.has_logo && acronym && (
            <Text
              textTransform={"uppercase"}
              fontWeight={"600"}
              fontSize={((size / 2.14) + 'px')}
            >{acronym}</Text>
          )}
        </Flex>
      )}
    </>
  );
}