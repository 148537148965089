'use client'

import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input, Textarea} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';

const InsightNameForm = forwardRef((props, ref) => {

  const {
    insightId,
    insight,
    refresh,
  } = useContext(InsightContext);
  
  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel,
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const insightApi = useInsightApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if(name && insightId) {
        await insightApi.updateName(name, insightId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(insight.name) {
      setValue('name', insight.name);
    }
  }, [insight.name]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex
        flex="1"
        w="100%"
        >
        <form
          display="flex"
          style={{
            width:"100%"
          }}
          onSubmit={handleSubmit(onSubmit)}
          >
          <Flex
            alignItems={'center'}
            w="100%"
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.name}>
              <Textarea
                flex={"1"}
                placeholder='Name'
                {...register('name', {
                  required: true,
                })}
                height={"100px"}
              />
              <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
            </FormControl>
            <Flex gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
                )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                variant="coralOutline"
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default InsightNameForm;


