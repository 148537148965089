import { CloseButton } from "@chakra-ui/react";

export const drawer = {
  baseStyle: {
    header: {
      minHeight: '55px',
      px: 3,
      py: 2,
      alignItems: 'center',
      display: 'flex',
      borderBottom: '1px solid',
      borderColor: 'divider.grey',
    },
    closeButton: {
      fontSize: '10px'
    }
  }
};