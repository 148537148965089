import React, { useContext, useState } from 'react';
import { Box } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, ModalFooter } from '@chakra-ui/modal';
import { Link, useNavigate } from 'react-router-dom';
import { Flex, Button, Icon, ListItem, Text, UnorderedList, Input, useToast, useDisclosure } from "@chakra-ui/react";
import { MdOutlineWorkspacePremium } from 'react-icons/md';
import SubscriptionModal from '../../subscriptions/components/subscriptionModal';
import { OrganisationContext } from '../../organisation/lib/organisationContext';
import StripeContext from '../../subscriptions/lib/stripeContext';
import useOrganisationApi from '../../organisation/lib/useOrganisationApi';
import { AuthContext } from '../../auth/lib/authContext';
import { ProfileContext } from '../lib/profileContext';
import { UserOrganisationContext } from '../../organisation/lib/userOrganisationContext';

export default function UnlockSharingFeatures(props) {

  const {
    organisation,
    refresh,
  } = useContext(OrganisationContext);
  const stripeContext = useContext(StripeContext)
  const userOrganisationContext = useContext(UserOrganisationContext);
  const {profile} = useContext(ProfileContext)
  const authContext = useContext(AuthContext)
  const organisationApi = useOrganisationApi();
  const { disclosure, item = 'persona' } = props;
  const subscriptionModal = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [isJoining, setIsjoining] = useState(false);

  const join = async () => {
    try {
        setIsjoining(true)
        await organisationApi.addMember(profile.id, organisation.id)
        await userOrganisationContext.refreshOrganisation();
        await refresh();
        await authContext.refresh();
        stripeContext.updateQuantity();
        navigate('/organisation')
        toast({
          position: 'bottom-right',
          description: "You joined the organisation",
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        setIsjoining(false)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upgrade Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Flex
              flexDirection={"column"}
              p={3}
              gap={2}>
              <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="2px"
                backgroundColor="white"
                p={3}
                flexDirection={"column"}
              >
                <Text>
                  The sharing features are not available on the free plan. If you want to share personas and products with your teams please upgrade your plan.
                </Text>
              </Flex>
              {organisation && (
                <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="2px"
                backgroundColor="white"
                p={3}
                flexDirection={"column"}
                gap={2}
              >
                <Text>
                  An organization ({organisation.name}) already exists for your email address so you can have access to the enterprise plan.
                </Text>
                <Button
                  onClick={join}
                  isLoading={isJoining}
                  width={"fit-content"}
                  variant={item === 'persona' ? 'blueOutline' : 'purpleOutline'}
                >Join</Button>
              </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={item === 'persona' ? 'blueOutline' : 'purpleOutline'}
              onClick={disclosure.onClose}
            >Close</Button>
            <Button
              variant={item === 'persona' ? 'blueFill' : 'purpleFill'}
              ml={2}
              leftIcon={<Icon boxSize={"18px"} as={MdOutlineWorkspacePremium} />}
              onClick={() => {
                disclosure.onClose();
                subscriptionModal.onOpen();
              }}>Upgrade Plan</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <SubscriptionModal disclosure={subscriptionModal} />
    </>
  );
}