import React, { useContext, useEffect, useRef, useState } from 'react';
import { OrganisationContext } from '../lib/organisationContext';
import { Box, Button, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import OrganisationLogo from './organisationLogo';
import { MdArrowBack, MdEdit, MdLink, MdMoreHoriz, MdOutlineInfo, MdOutlinePayments } from 'react-icons/md';
import OrganisationNameForm from './organisationNameForm';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import OrganisationDomainForm from './organisationDomainsForm';
import OrganisationLogoForm from './organisationLogoForm';
import { useNavigate } from 'react-router-dom';
import StripeContext from '../../subscriptions/lib/stripeContext';
import dayjs from 'dayjs';
import { UserOrganisationContext } from '../lib/userOrganisationContext';
import useOrganisationApi from '../lib/useOrganisationApi';
import { ProfileContext } from '../../profile/lib/profileContext';
import PaymentsModal from '../../payment/components/paymentsModal';
import CancelSubscriptionModal from '../../subscriptions/components/cancelSubscriptionModal';
import { AuthContext } from '../../auth/lib/authContext';

export default function OrganisationAside(props) {

  const {
    organisation,
    rights,
    refresh,
    members } = useContext(OrganisationContext);
    const {
      unsubscribe
    } = useContext(StripeContext)
  const userOrganisationContext = useContext(UserOrganisationContext);
  const { fetchUpcomingInvoice, updateQuantity } = useContext(StripeContext);
  const { profile,
    subscription,
    profileSystem } = useContext(ProfileContext)
  const authContext = useContext(AuthContext)
  const paymentsModal = useDisclosure();
  const cancelSubscriptionModal = useDisclosure();
  const organisationApi = useOrganisationApi();
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [invoice, setInvoice] = useState();
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [previousMembers, setPreviousMembers] = useState(false);
  const navigate = useNavigate();
  const modalEditLogo = useDisclosure();
  const modalSubscriptionWarning = useDisclosure();
  const ref1 = useRef();
  const toast = useToast();
  const organisationRef = useRef();

  const cancelSubscriptionAndJoin = async () => {
    try {
      setIsCancelling(true);
      await unsubscribe(subscription.id)
      const interval = setInterval(async () => {
        if(!organisationRef.current) {
          modalSubscriptionWarning.onClose();
          await join();
          toast({
            position: 'bottom-right',
            description: "Your subscription is upgraded",
            status: 'success',
            duration: 1000,
            isClosable: true,
          });
          await refresh();
          setIsCancelling(false);
          clearInterval(interval);
        } else {
          await userOrganisationContext.refreshOrganisation();
        }
      }, 500)
    } catch (error) {
      console.error(error)
    }
  };

  const join = async () => {
    try {
        setIsAddingMember(true)
        await organisationApi.addMember(profile.id, organisation.id)
        await userOrganisationContext.refreshOrganisation();
        await refresh();
        await authContext.refresh();
        updateQuantity();
        setIsAddingMember(false)
    } catch (error) {
      console.error(error);
    }
  };

  const updateUpcomingInvoice = async () => {
    try {
      setLoadingInvoice(true)
      const newUpcomingInvoice = await fetchUpcomingInvoice();
      setInvoice(newUpcomingInvoice);
      setLoadingInvoice(false)
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    if (organisation &&  members && members.length !== previousMembers.length) {
      setPreviousMembers(members)
      setTimeout(() => {
        updateUpcomingInvoice()
      }, 1000);
    }
  }, [members, organisation])

  useEffect(() => {
    organisationRef.current = userOrganisationContext.organisation;
  }, [userOrganisationContext.organisation]);

  return (
    <>
      <Flex
        flexDirection="column"
        backgroundColor={"white"}
        borderRight={"1px solid"}
        borderColor={"divider.grey"}
        w="275px"
        position="relative"
        flexShrink={"0"}
      >
        <IconButton
          left={1}
          top={1}
          position="absolute"
          variant="greyTransparent"
          icon={<MdArrowBack />}
          onClick={() => {
            navigate(-1)
          }}
        />
        <Flex
          p={3}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box
            position={'relative'}
            mb={3}
          >
            <Box
              onClick={modalEditLogo.onOpen}
              pointerEvents={rights.can_update ? 'all' : 'none'}
              cursor={"pointer"}
            >
              <OrganisationLogo
                size={100}
              />
            </Box>
            {rights.can_update && (
              <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="50%"
                backgroundColor="white"
                alignItems="center"
                justifyContent="center"
                w={30}
                h={30}
                cursor={"pointer"}
                position={'absolute'}
                bottom={'0'}
                right={'0'}
                onClick={modalEditLogo.onOpen}
              >
                <Icon
                  as={MdEdit}
                  color={'text.medium.grey'}
                  boxSize={'15px'}
                />
              </Flex>
            )}
          </Box>
          <InlineFormWrapper
            canEdit={rights.can_update}
            value={
              <Text fontSize={"18px"}>{organisation.name}</Text>
            }
          >
            {(close) => (
              <OrganisationNameForm
                onSubmitted={close}
                onCancel={close}
              />
            )}
          </InlineFormWrapper>
          {userOrganisationContext.hasPotentialOrganisation && (
            <>
              {profileSystem.role === 'standard' && (
                <Button
                  variant="greyFill"
                  mt={2}
                  onClick={join}
                  isLoading={isAddingMember}
                >Join</Button>
              )}
               {profileSystem.role === 'premium' && (
                <Button
                  variant="greyFill"
                  mt={2}
                  onClick={modalSubscriptionWarning.onOpen}
                  isLoading={isAddingMember}
                >Join</Button>
              )}
            </>
          )}
          {rights.can_update && (
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    mt={2}
                    isActive={isOpen}
                    as={IconButton}
                    icon={<MdMoreHoriz />}
                    variant='greyOutline'
                  />
                  <MenuList>
                    <MenuItem
                      onClick={paymentsModal.onOpen}
                    >See Payments</MenuItem>
                    {rights.can_delete && (
                      <MenuItem onClick={() => {
                      }}>
                        <Text color='text.medium.red'
                          onClick={cancelSubscriptionModal.onOpen}
                        >Cancel Subscription</Text>
                      </MenuItem>
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </Flex>
        <Flex
          width={"100%"}
          p={3}
          borderTop={"1px solid"}
          borderColor={"divider.grey"}
          flexDirection={"column"}
          gap={2}
        >
          <Text>Domains</Text>
          {organisation.domains && (
            <InlineFormWrapper
              width={"100%"}
              canEdit={rights.can_update}
              value={
                <Flex
                  flexDirection={"column"}
                  gap={2}
                  width={"100%"}
                >
                  {organisation.domains.map((d, i) => {
                    return (
                      <Text color={"text.medium.grey"} key={i}>{d}</Text>
                    )
                  })}
                </Flex>
              }
            >
              {(close) => (
                <OrganisationDomainForm
                  onSubmitted={close}
                  onCancel={close}
                />
              )}
            </InlineFormWrapper>
          )}
        </Flex>
        {rights.can_update && (
          <Flex
            width={"100%"}
            p={3}
            borderTop={"1px solid"}
            borderColor={"divider.grey"}
            flexDirection={"column"}
            gap={2}
          >
            <Text>Upcoming Invoice</Text>
            {(!loadingInvoice && invoice) &&
              <Flex flexDirection={"column"} gap={0}>
                <Flex alignItems={"baseline"}>
                  <Text mr={1} fontSize={"14px"}>$</Text>
                  <Text fontSize={"18px"}>{(invoice.amount_due / 100)}</Text>
                </Flex>
                <Tooltip label="Includes previous month pro rata if new users added.">
                  <Flex color={"text.medium.grey"} fontSize={"12px"} alignItems={"center"} gap={1}>
                    <Text>{dayjs.unix(invoice.period_end).format('MMMM, DD')}</Text>
                    <Icon as={MdOutlineInfo} />
                  </Flex>
                </Tooltip>
              </Flex>
            }
            {(loadingInvoice) &&
              <Skeleton width={"100%"} height={"30px"} />
            }
          </Flex>
        )}
      </Flex>
      <Modal
        isOpen={modalEditLogo.isOpen}
        onClose={modalEditLogo.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Logo</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {organisation && (
                <OrganisationLogoForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    modalEditLogo.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='greyOutline'
              onClick={modalEditLogo.onClose}
            >Close</Button>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <PaymentsModal disclosure={paymentsModal} />
      <CancelSubscriptionModal disclosure={cancelSubscriptionModal} />
      <Modal
        isOpen={modalSubscriptionWarning.isOpen}
        onClose={modalSubscriptionWarning.onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Upgrade to Enterprise Plan</ModalHeader>
          <ModalBody p={3} >
            <Box
              border={"1px solid"}
              borderColor={"divider.grey"}
              borderRadius={"2px"}
              backgroundColor={"white"}
              p={3}
              >
              <Text>
                By joining the enterprise plan, your pro subscription will be canceled, and you will be upgraded to the enterprise plan. Your license will then be managed by your organization's admin.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
          <Button
              variant="greyOutline"
              ml={2}
              onClick={modalSubscriptionWarning.onClose}>Cancel</Button>
            <Button
              variant="yellowFill"
              ml={2}
              isLoading={isCancelling}
              onClick={cancelSubscriptionAndJoin}>Join</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}