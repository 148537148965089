'use client';

import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import ResetPasswordForm from '../components/resetPasswordForm';

export default function ResetPasswordPage() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <>
     <Flex
        p={3}
        maxW={350}
        flexDirection={"column"}
      >
        <Text
          fontSize={"18px"}
          >Reset Password</Text>
        <Text mb={2} textStyle="subtitle">A link will be send to you email address for updating your password.</Text>
        <ResetPasswordForm email={searchParams.get('email')} />
        <Link to="/auth/sign-in">
              <Button
                variant="coralTransparent"
                mt={3}
                textAlign={'center'}
                p={0}
              >
             Go Back to Sign In
            </Button>
          </Link>
     </Flex>
    </>
  )
}
