import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Heading, Icon, IconButton, Skeleton } from '@chakra-ui/react';
import ProductInExperienceListItem from './productInExperienceListItem';
import { Box, Flex, Spacer, Text, Stack } from '@chakra-ui/layout';
import { MdAdd, MdArrowBack, MdDevices } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { useNavigate } from 'react-router-dom';
import ProductForm from '../../../products/components/productForm';
import useExperienceApi from '../../lib/useExperienceApi';
import AddProductToExperienceForm from './addProductToExperienceForm';
import { ExperienceContext } from '../../lib/experienceContext';
import ProductProvider from '@/products/lib/productProvider';
import { ExperiencePageContext } from '../lib/experiencePageContext';

export default function ProductInExperienceList(props) {

  const {
    rights,
  } = useContext(ExperienceContext);

  const {
    productList,
    isFetchingProductList,
    refreshProductList
  } = useContext(ExperiencePageContext);

  const { experienceId } = props;
  const [isValid, setIsValid] = useState(false);
  const experienceApi = useExperienceApi();
  const modalAddProduct = useDisclosure();
  const modalCreateProduct = useDisclosure();
  const [isAdding, setIsAdding] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const navigate = useNavigate();

  const linkNewProductToExperience = async (newProduct) => {
    try {
      await experienceApi.linkProductsToExperience(experienceId, [newProduct.id]);
      await refreshProductList();
      setIsAdding(false);
      modalCreateProduct.onClose();
      modalAddProduct.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Box>
        <Flex
          alignItems='center'
          mb="1"
          mx={3}
        >
          <Icon
            as={MdDevices}
            color={'text.primary'}
            boxSize={'14px'}
            mr={2}
          />
          <Text fontSize={14} >Products</Text>
          <Spacer />
          {rights.can_update && (
            <IconButton
              ml={2}
              variant="coralOutline"
              icon={<MdAdd />}
              onClick={modalAddProduct.onOpen}
              size="sm"
            />
          )}
        </Flex>
        <Box>
          {isFetchingProductList && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
          )}
          {(!isFetchingProductList && productList) && productList.map((product, i) => {
            return (
              <Box
                style={{ 'cursor': 'pointer' }}
                key={i}
                onClick={() => {
                  navigate(`/products/${product.id}`);
                }}>
                <ProductProvider
                  productId={product.id}
                  originalProduct={product}
                  needEditionRequest={false}
                >
                  <ProductInExperienceListItem
                    product={product}
                    onRemove={refreshProductList}
                  />
                </ProductProvider>
              </Box>
            );
          })}
          {(!isFetchingProductList && productList && productList.length === 0) && (
            <Flex px={3}>
              <Text color="text.light.grey" fontSize="12px" >No product so far</Text>
            </Flex>
          )}
        </Box>
      </Box>
      <Modal isOpen={modalAddProduct.isOpen} onClose={modalAddProduct.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Add Product</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <AddProductToExperienceForm
                experienceId={experienceId}
                hideSave={true}
                onCreateNew={() => {
                  modalAddProduct.onClose();
                  modalCreateProduct.onOpen();
                }}
                onSubmitted={() => {
                  refreshProductList();
                  modalAddProduct.onClose();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='coralOutline'
              onClick={modalAddProduct.onClose}
            >Cancel</Button>
            <Button
              variant="coralFill"
              ml={2}
              isLoading={isFetchingProductList}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalCreateProduct.isOpen} onClose={modalCreateProduct.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack />}
              aria-label='New'
              mr={2}
              onClick={() => {
                modalCreateProduct.onClose();
                modalAddProduct.onOpen();
              }}
            />
            New Product</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <ProductForm
                hideSave={true}
                onSubmitted={linkNewProductToExperience}
                onValidityChange={setIsValid}
                ref={ref2}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='coralOutline'
              onClick={() => {
                modalCreateProduct.onClose();
                modalAddProduct.onOpen();
              }}
            >Cancel</Button>
            <Button
              variant="coralFill"
              ml={2}
              isDisabled={!isValid}
              isLoading={isAdding}
              onClick={() => {
                setIsAdding(true)
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
