import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Flex,
  Progress,
  Text,
  Box,
  Tag,
  TagLabel,
  TagLeftIcon
} from "@chakra-ui/react";
import { MdFace, MdOutlineDevices, MdOutlineFace, MdOutlineLayers, MdOutlineRoute, MdOutlineSentimentSatisfied, MdOutlineSentimentVerySatisfied, MdSearch } from "react-icons/md";
import useExperienceApi from "../experiences/lib/useExperienceApi";
import useInsightApi from "../insight/lib/useInsightApi";
import usePersonaApi from "../persona/lib/usePersonaApi";
import useProductsApi from "../products/lib/useProductsApi";
import { Link, useNavigate } from "react-router-dom";
import InsightPicto from "../insight/components/insightPicto";
import PersonaThumbnail from "../persona/components/personaThumbnail";
import PersonaProvider from "../persona/lib/personaProvider";
import ProductProvider from "../products/lib/productProvider";
import ProductLogo from "../products/components/productLogo";
import ProfileProvider from "../profile/lib/profileProvider";
import ProfileThumbnail from "../profile/component/profileThumbnail";
import { AuthContext } from "../auth/lib/authContext";

const SearchBar = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const experienceApi = useExperienceApi();
  const insightApi = useInsightApi();
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [menuHover, setMenuHover] = useState(false);
  const [inputHover, setInputHover] = useState(false);
  const [filter, setFilter] = useState('all');

  const handleSearch = async (event) => {
    let value;
    if(event) {
      value = event.target.value;
      setSearchTerm(value);
    } else {
      value = searchTerm;
    }
    setIsLoading(true);
    if (value && value.length >= 2) {
      let newSuggestions;
      if(filter === 'all') {
        const promises = [
          insightApi.searchInsights(value),
          experienceApi.searchExperiences(value),
          experienceApi.searchPersonaExperienceGroups(value),
          experienceApi.searchProductExperienceGroups(value),
          personaApi.searchManyPersona(value),
          productApi.searchProducts(value),
        ]
        const result = await Promise.all(promises);
        newSuggestions =
          result[0].map((r) => ({
            type: 'insight',
            data: r
          })).concat(
            result[1].map((r) => ({
              type: 'experience',
              data: r
            }))).concat(
              result[2].map((r) => ({
                type: 'persona_experience_group',
                data: r
              }))).concat(
                result[3].map((r) => ({
                  type: 'product_experience_group',
                  data: r
                }))).concat(
                  result[4].map((r) => ({
                    type: 'persona',
                    data: r
                  }))).concat(
                    result[5].map((r) => ({
                      type: 'product',
                      data: r
                    })))
      } else if (filter === 'persona') {
        const personas = await personaApi.searchManyPersona(value);
        newSuggestions = personas.map((p) => {
          return {
            type: 'persona',
            data: p
          }
        })
      } else if (filter === 'product') {
        const products = await productApi.searchProducts(value)
        newSuggestions = products.map((p) => {
          return {
            type: 'product',
            data: p
          }
        })
      } else if (filter === 'insight') {
        const insights = await insightApi.searchInsights(value);
        newSuggestions = insights.map((i) => {
          return {
            type: 'insight',
            data: i
          }
        })
      }  else if (filter === 'experience') {
        const promises = [
          experienceApi.searchExperiences(value),
          experienceApi.searchPersonaExperienceGroups(value),
          experienceApi.searchProductExperienceGroups(value),
        ]
        const result = await Promise.all(promises);
        newSuggestions = result[0].map((r) => ({
          type: 'experience',
          data: r
        })).concat(
          result[1].map((r) => ({
            type: 'persona_experience_group',
            data: r
          }))).concat(
            result[2].map((r) => ({
              type: 'product_experience_group',
              data: r
            })));
      }
      setSuggestions(newSuggestions)
    } else {
      setSuggestions([]);
    }
    setIsLoading(false);
  };

  const handleClick= useCallback(() => {
    const newIsFocus = document.activeElement === inputRef.current;
    setIsFocus(newIsFocus);
    if (!menuHover && !inputHover && !newIsFocus) {
      setIsOpen(false);
    }
 }, []);

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const clickEvent = window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', clickEvent);
    }
  }, [])

  useEffect(() => {
    handleSearch();
  }, [filter])

  return (
    <Flex
      maxW={'500px'}
      width={(isFocus? "100%": '300px')}
      mx={3}
      position={"relative"}>
      <InputGroup
        onMouseEnter={() => {
          setInputHover(true)
        }}
        onMouseLeave={() => {
          setInputHover(false)
        }}
      >
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} color="text.medium.grey" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search in Bibliona…"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </InputGroup>
      {isOpen && (
        <Flex
          position={"absolute"}
          top={"calc(100% + 10px)"}
          backgroundColor={"white"}
          width={'100%'}
          flexDirection={"column"}
          zIndex={"1000"}
          maxHeight={"calc(100vh - 100px)"}
          overflow={"auto"}
          borderRadius={"2px"}
          border={"1px solid"}
          borderColor={"divider.grey"}
          boxShadow='0px 0px 6px 0px rgba(163,163,163,0.15)'
        >
          {isLoading && (
            <Progress isIndeterminate size='xs' opacity={isLoading ? '1' : '0'} />
          )}
         { (searchTerm && searchTerm.length >= 2) && (
              <Flex
              gap={1}
              px={3}
              py={1}
              flexWrap={"wrap"}
              borderBottom={"1px solid"}
              borderColor={"divider.grey"}
              >
              <Tag
                variant={(filter === 'all' ? 'greyDark': 'grey')}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  setFilter('all')
                }}
              >All
              </Tag>
              <Tag 
                variant={(filter === 'persona' ? 'greyDark': 'grey')}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  setFilter('persona')
                }}
              >
                <TagLeftIcon boxSize='12px' as={MdOutlineFace} />
                <TagLabel>Persona</TagLabel>
              </Tag>
              <Tag
                variant={(filter === 'product' ? 'greyDark': 'grey')}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  setFilter('product')
                }}
              >
              <TagLeftIcon boxSize='12px' as={MdOutlineDevices} />
              <TagLabel>Product</TagLabel>
              </Tag>
              <Tag
               variant={(filter === 'experience' ? 'greyDark': 'grey')}
               cursor={"pointer"}
               onClick={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setFilter('experience')
               }}
               >
                <TagLeftIcon boxSize='12px' as={MdOutlineRoute} />
                <TagLabel>Experience/Group</TagLabel>
              </Tag>
              <Tag
               variant={(filter === 'insight' ? 'greyDark': 'grey')}
               cursor={"pointer"}
               onClick={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setFilter('insight')
               }}
              >
                <TagLeftIcon boxSize='12px' as={MdOutlineSentimentVerySatisfied} />
                <TagLabel>Insight</TagLabel>
              </Tag>
            </Flex>
         )}
          <Flex
            flexDirection={"column"}
            py={2}
            onMouseEnter={() => {
              setMenuHover(true)
            }}
            onMouseLeave={() => {
              setMenuHover(false)
            }}
          >
            {suggestions.length > 0 ? suggestions.map((suggestion, i) => (
              <Box
                key={i}
                borderBottom={"1px solid"}
                borderColor={"divider.grey"}
                onClick={() => { setIsOpen(false) }}
              >
                {suggestion.type === 'insight' && <SearchListItemInsight insight={suggestion.data} />}
                {suggestion.type === 'experience' && <SearchListItemExperience experience={suggestion.data} />}
                {suggestion.type === 'persona_experience_group' && <SearchListItemPersonaExperienceGoup group={suggestion.data} />}
                {suggestion.type === 'product_experience_group' && <SearchListItemProductExperienceGoup group={suggestion.data} />}
                {suggestion.type === 'persona' && <SearchListItemPersona persona={suggestion.data} />}
                {suggestion.type === 'product' && <SearchListItemProduct product={suggestion.data} />}
              </Box>
            ))
              :
              <Flex px={3} py={2}>
                <Text>No result found</Text>
              </Flex>
            }
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const SearchListItemProduct = (props) => {
  const { product } = props;
  const authContext = useContext(AuthContext);

  return (
    <ProductProvider
      originalProduct={product}
      productId={product.id}
      needRights={false}
      needRole={false}
      needEditionRequest={false}
    >
      <Link to={`/products/${product.id}`}>
        <Flex
          px={3}
          py={2}
          cursor={"pointer"}
          _hover={{ bg: "background.grey" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex
            flexDirection={"column"}
            flex={1}
            >
            <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
              <ProductLogo size={20} />
              <Text>Product</Text>
            </Flex>
            <Text>{product.name}</Text>
          </Flex>
          {(product.owner &&  authContext.profile.id !== product.owner.id) &&
            <ProfileProvider
              profileId={product.owner.id}
              originalProfile={product.owner}
            >
              <Flex gap={2} flexDirection={"column"} alignItems={"flex-end"}>
                <ProfileThumbnail size={25} />
                <Text fontSize={"12px"} color={"text.medium.grey"}>{product.owner.pseudo}</Text>
              </Flex>
            </ProfileProvider>
          }
        </Flex>
      </Link>
    </ProductProvider>
  );
};

const SearchListItemPersona = (props) => {
  const { persona } = props;
  const authContext = useContext(AuthContext);
  return (
    <PersonaProvider
      originalPersona={persona}
      personaId={persona.id}
      needRights={false}
      needRole={false}
      needEditionRequest={false}
    >
      <Link to={`/persona/${persona.id}`}>
        <Flex
          px={3}
          py={2}
          cursor={"pointer"}
          _hover={{ bg: "background.grey" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex
          flexDirection={"column"}
          flex={1}
          >
            <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
              <PersonaThumbnail size={20} />
              <Text>Persona</Text>
            </Flex>
            <Text>{persona.name}</Text>
          </Flex>
          {(persona.owner &&  authContext.profile.id !== persona.owner.id) &&
            <ProfileProvider
              profileId={persona.owner.id}
              originalProfile={persona.owner}
            >
              <Flex gap={2} flexDirection={"column"} alignItems={"flex-end"}>
                <ProfileThumbnail size={25} />
                <Text fontSize={"12px"} color={"text.medium.grey"}>{persona.owner.pseudo}</Text>
              </Flex>
            </ProfileProvider>
          }
        </Flex>
      </Link>
    </PersonaProvider>
  )
};

const SearchListItemProductExperienceGoup = (props) => {
  const { group } = props;
  return (
    <Link to={`/products/${group.product_id}?group-id=${group.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <Icon
            as={MdOutlineLayers}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text>Experience Group</Text>
        </Flex>
        <Text>{group.name}</Text>
      </Flex>
    </Link>
  )
};

const SearchListItemPersonaExperienceGoup = (props) => {
  const { group } = props;

  return (
    <Link to={`/persona/${group.persona_id}?group-id=${group.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <Icon
            as={MdOutlineLayers}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text>Experience Group</Text>
        </Flex>
        <Text>{group.name}</Text>
      </Flex>
    </Link>
  )
};


const SearchListItemExperience = (props) => {
  const { experience } = props;
  return (
    <Link to={`/experiences/${experience.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex
          gap={1}
          alignItems={"center"}
          mb={1}
          color="text.medium.grey">
          <Icon
            as={MdOutlineRoute}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text >Experience</Text>
        </Flex>
        <Text>{experience.objective}</Text>
      </Flex>
    </Link>
  )
};

const SearchListItemInsight = (props) => {
  const { insight } = props;
  return (
    <Link to={`/experiences/${insight.experience_id}#${insight.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <InsightPicto size={20} type={insight.type} />
          <Text>Insight</Text>
        </Flex>
        <Text>{insight.name}</Text>
      </Flex>
    </Link>
  )
};

export default SearchBar;
