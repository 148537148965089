import React, { useContext, useEffect, useState } from 'react';
import useProductsApi from '../lib/useProductsApi';
import { ProductContext } from './productContext.js';
import { AuthContext } from '@/auth/lib/authContext';

export default function ProductProvider(props) {

  let {
    productId,
    originalProduct,
    children,
    onFetchingData,
    onDataFetched,
    onInit,
    needRights = true,
    needRole = true,
    needEditionRequest = true,
    needTypes = true,
  } = props;
  const { profile, hasProfile } = useContext(AuthContext);
  const [role, setRole] = useState();
  const [product, setProduct] = useState();
  const [types, setTypes] = useState();
  const [hasRequestedEdition, setHasRequestedEdition] = useState(false);
  const [rights, setRights] = useState({
    can_update: false,
    can_insert: false,
    can_delete: false
  });
  const productApi = useProductsApi();
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const refresh = async (options = {skipProduct: false}) => {
    try {
      if(onFetchingData) {
        onFetchingData()
      }
      const promises = [];
      let nbRequest = 0;
      let orders = {};
      if(!options.skipProduct) {
        promises.push(productApi.fetchProduct(productId))
        orders.product = nbRequest;
        nbRequest++;
      }
      if(needRights) {
        promises.push(productApi.fetchUserRights(productId, profile.id))
        orders.rights = nbRequest;
        nbRequest++;
      }
      if(needRole) {
        promises.push(productApi.fetchMemberRole(productId, profile.id))
        orders.role = nbRequest;
        nbRequest++;
      }
      if(needEditionRequest) {
        promises.push(productApi.hasRequestedEdition(productId, profile.id))
        orders.editionRequest = nbRequest;
        nbRequest++;
      }
      if(needTypes) {
        promises.push(productApi.fetchProductTypeByProductId(productId));
        orders.types = nbRequest;
        nbRequest++;
      }
      const promisesResult = await Promise.all(promises);
      if(!options.skipProduct) {
        setProduct(promisesResult[orders.product]);
      }
      if(needRights) {
        setRights(promisesResult[orders.rights]);
      }
      if(needRole) {
        setRole(promisesResult[orders.role]);
      }
      if(needEditionRequest) {
        setHasRequestedEdition(promisesResult[orders.editionRequest]);
      }
      if(needTypes) {
        setTypes(promisesResult[orders.types]);
      }
      if(!isInit) {
        setIsInit(true);
        if(onInit) {
          onInit(true);
        }
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      if (originalProduct) {
        refresh({skipProduct: true})
      } else {
        refresh()
      }
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

useEffect(() => {
    if(productId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
      setIsInit(false);
    }
  }, [productId, hasProfile]);
  
  useEffect(() => {
    if (originalProduct && hasProfile) {
      setProduct(originalProduct);
    }
  }, [originalProduct, hasProfile]);

  
  return (
    <ProductContext.Provider value={{
      product,
      productId,
      role,
      rights,
      refresh,
      hasRequestedEdition,
      types
    }} >
      {product && <>{children}</>}
    </ProductContext.Provider>
  );
}