
import React, { useContext, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/layout';
import { Icon, Skeleton, Text } from '@chakra-ui/react';
import { MdOutlineBusiness } from 'react-icons/md';
import useOrganisationApi from './lib/useOrganisationApi';
import OrganisationProvider from './lib/organisationProvider';
import OrganisationDetail from './component/organisationDetail';
import { OrganisationContext } from './lib/organisationContext';
import { AuthContext } from '../auth/lib/authContext';
import OrganisationAside from './component/organisationAside';

export default function OrganisationPage() {

  const { organisation, refresh } = useContext(OrganisationContext);
  const { loadingOrganisation } = useContext(AuthContext);

  return (
   <>
    {loadingOrganisation && 
    <>
      <Skeleton w={"100%"} height={"100%"} />
    </>
    }
    {(!loadingOrganisation && organisation) &&
    <Flex width={"100%"}>
      <OrganisationProvider
        organisationId={organisation.id}
        organisation={organisation}
        needMembers={true}
        onRefreshOrganisation={() => {
          refresh()
        }}
      >
        <OrganisationAside />
        <OrganisationDetail />
      </OrganisationProvider>
    </Flex>
    }
   </>
  )
}