import { Flex, Icon, IconButton, Skeleton, Text } from '@chakra-ui/react';
import InsightProvider from '@/insight/lib/insightProvider';
import ExperienceProvider from '@/experiences/lib/experienceProvider';
import InsightPicto from '@/insight/components/insightPicto';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';
import InsightCardListItem from './insightCardListItem';
import { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

export default function InsightCardList(props) {

  const {
    lists,
    onSetAsOutdated
  } = props

  const [data, setData] = useState();
  const [refreshCounter, setRefreshCounter] = useState(1);
  const location = useLocation()

  const setDisplay = (type, value) => {
    localStorage.setItem(type + location.pathname, value);
    setRefreshCounter(refreshCounter + 1);
  };

  const getDisplay = (type) => {
    if (localStorage.getItem(type + location.pathname)) {
      return localStorage.getItem(type + location.pathname) === 'true'
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (lists) {
      const newData = [];
      if (lists[insightTypeDictionnary.negative] && lists[insightTypeDictionnary.negative].length > 0) {
        newData.push({
          type: insightTypeDictionnary.negative,
          list: lists[insightTypeDictionnary.negative],
          title: 'Negative Feedback',
        })
      }
      if (lists[insightTypeDictionnary.improvement] && lists[insightTypeDictionnary.improvement].length > 0) {
        newData.push({
          type: insightTypeDictionnary.improvement,
          list: lists[insightTypeDictionnary.improvement],
          title: 'Improvement Feedback',
        })
      }
      if (lists[insightTypeDictionnary.positive] && lists[insightTypeDictionnary.positive].length > 0) {
        newData.push({
          type: insightTypeDictionnary.positive,
          list: lists[insightTypeDictionnary.positive],
          title: 'Positive Feedback',
        })
      }
      if (lists[insightTypeDictionnary.behavior] && lists[insightTypeDictionnary.behavior].length > 0) {
        newData.push({
          type: insightTypeDictionnary.behavior,
          list: lists[insightTypeDictionnary.behavior],
          title: 'Behaviors',
        })
      }
      if (lists[insightTypeDictionnary.motivation] && lists[insightTypeDictionnary.motivation].length > 0) {
        newData.push({
          type: insightTypeDictionnary.motivation,
          list: lists[insightTypeDictionnary.motivation],
          title: 'Motivations',
        })
      }
      setData(newData);
    }
  }, [lists])

  return (
    <>
      {!data && (
      <Flex pb={3} w={"100%"}>
        <Skeleton height={"50px"} w={"100%"} />
      </Flex>
    )}
      {(data && data.length > 0) && (
        <Flex
          flexDirection={"column"}
          width={"100%"}
        >
          {data.map((d, i) => (
            <Flex
              flexShrink={"0"}
              w={"100%"}
              flexDirection={"column"}
              gap={2}
              key={i}
              role="group"
            >
              <Flex
                alignItems={"center"}
                gap={1}
              >
                <InsightPicto size={20} type={d.type} />
                <Text >{d.title}</Text>
                {(getDisplay(d.type) && refreshCounter) && (
                  <IconButton
                    variant="greyTransparent"
                    color="text.medium.grey"
                    fontSize='16px'
                    style={{ opacity: '0' }}
                    _groupHover={{ opacity: '1 !important' }}
                    icon={<MdOutlineKeyboardArrowUp />}
                    onClick={() => { setDisplay(d.type, false) }}
                  />
                )}
                {(!getDisplay(d.type) && refreshCounter) && (
                  <IconButton
                    variant="greyTransparent"
                    color="text.medium.grey"
                    fontSize='16px'
                    style={{ opacity: '0' }}
                    _groupHover={{ opacity: '1 !important' }}
                    icon={<MdOutlineKeyboardArrowDown />}
                    onClick={() => { setDisplay(d.type, true) }}
                  />
                )}
              </Flex>
              <Flex
                gap={2}
                overflowX={"auto"}
                width={"100%"}
                borderTop={"1px solid"}
                pb={3}
                borderColor={getDisplay(d.type) === true ? "transparent" : "divider.grey"}
              >
                {(getDisplay(d.type) && d.list && d.list.length > 0)
                  && d.list.map((n) => (
                    <InsightProvider
                      insightId={n.id}
                      key={n.id}
                      originalInsight={n}
                      fetchPersonaList="always"
                      fetchProductList="always"
                    >
                      <ExperienceProvider experienceId={n.experience_id}>
                        <InsightCardListItem onSetAsOutdated={() => {
                          if (onSetAsOutdated) {
                            onSetAsOutdated();
                          }
                        }} />
                      </ExperienceProvider>
                    </InsightProvider>
                  ))
                }
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
}
