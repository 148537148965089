
import React, {useContext, useEffect, useState} from 'react';
import StripeContext from './stripeContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SplashScreenContext from '../../auth/splash-screen/splashScreenContext';
import { supabase } from '../../supabase.client';
import { ProfileContext } from '../../profile/lib/profileContext';
import { UserOrganisationContext } from '../../organisation/lib/userOrganisationContext';

let stripePromise;
stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function StripeProvider({children}) {

  const [isInit, setIsInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPrices, setLoadingPrices] = useState(false)
  const splashScreenContext = useContext(SplashScreenContext)
  const [stripe, setStripe] = useState();
  const profileContext = useContext(ProfileContext);
  const userOrganisationContext = useContext(UserOrganisationContext);

  const fetchUpcomingInvoice = async () => {
    const upcomingInvoiceResult = await supabase.functions.invoke('stripe-get-upcoming-invoice',{
      body: {
        organisation_id: (userOrganisationContext.hasPotentialOrganisation ?
          userOrganisationContext.potentialOrganisation.id
          :  userOrganisationContext.organisation.id
        ),
      }
    });
    if(upcomingInvoiceResult.error) {
      throw upcomingInvoiceResult.error;
    } else {
      return JSON.parse(upcomingInvoiceResult.data);
    }
  };

  const updateQuantity = async () => {
    try {
      const updateQuantityResult = await supabase.functions.invoke('stripe-update-quantity',{
        body: {
          organisation_id: (userOrganisationContext.hasPotentialOrganisation ?
            userOrganisationContext.potentialOrganisation.id
            :  userOrganisationContext.organisation.id
          ),
        }
      });
      if(updateQuantityResult.error) {
        throw updateQuantityResult.error;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const subscribe = async (priceId) => {
    try { 
      const createCheckoutResult = await supabase.functions.invoke('stripe-create-checkout-session',{
        body: {
          price_id: priceId,
          user_id: profileContext.profile.id,
        }
      });

      if(createCheckoutResult.error) {
        throw createCheckoutResult.error;
      } else {
        const data = JSON.parse(createCheckoutResult.data)
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.id,
        });
        if(error) {
          throw error;
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const unsubscribe = async (subscriptionId) => {
    try {
      const result = await supabase.functions.invoke('stripe-delete-subscription',{
        body: {subscriptionId}
      });
      if(result.error) {
        throw result.error;
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getPrices = async () => {
    try { 
      setLoadingPrices(true)
      const proPricesResult = await supabase.functions.invoke('stripe-get-prices',{
        body: {productId: process.env.REACT_APP_STRIPE_PRO_PLAN_PRODUCT_ID}
      });
      if(proPricesResult.error) {
        throw error;
      } else {
        const enterprisePricesResult = await supabase.functions.invoke('stripe-get-prices',{
          body: {productId: process.env.REACT_APP_STRIPE_ENTERPRISE_PLAN_PRODUCT_ID}
        });
        if(enterprisePricesResult.error) {
          throw error;
        } else {
          setLoadingPrices(false);
          return {
            pro: (JSON.parse(proPricesResult.data)).data,
            enterprise: (JSON.parse(enterprisePricesResult.data)).data
          }
        }
      }
    } catch (e) {
      console.error(e);
      setLoadingPrices(false);
    }
  }
  
  const fetchInvoice = async (paymentId) => {
    try {
      const result = await supabase.functions.invoke('stripe-get-invoice', {
        body: {paymentId}
      });
      if(result.error) {
        throw result.error;
      } else {
        return JSON.parse(result.data);
      }
    } catch(error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(!isInit && stripePromise) {
      setIsInit(true);
      setLoading(true)
        stripePromise
        .then((result) => {
          setStripe(result);
          setLoading(false);
        })
      }
  }, [stripePromise])

  useEffect(() => {
    splashScreenContext.displayWhile('stripe', loading)
  }, [loading])

  return (
    <StripeContext.Provider value={{
      subscribe,
      unsubscribe,
      updateQuantity,
      fetchUpcomingInvoice,
      getPrices,
      loadingPrices,
      fetchInvoice
      }}>
      {stripePromise &&
       <Elements stripe={stripePromise}>
       {children}
      </Elements> 
      }
      {!stripePromise &&
       <>{children}</>
      }
    </StripeContext.Provider>
  );
}




