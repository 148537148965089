'use client'

import React, { useRef, useState, useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Button, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz, MdOutlineWarning } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import ExperienceObjectiveForm from './experienceObjectiveForm';
import { ExperienceContext } from '../../lib/experienceContext';
import useExperienceApi from '../../lib/useExperienceApi';
import { useNavigate } from 'react-router-dom';
import PrototypeForm from './prototypeForm';

export default function ExperienceAsideEditMenu(props) {

  const {
    experience,
    rights,
    experienceId
  } = useContext(ExperienceContext);
  const experienceApi = useExperienceApi();
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const modalEditObjective = useDisclosure();
  const modalDelete = useDisclosure();
  const modalEditPrototype = useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const navigate = useNavigate();

  const deleteExperience = async () => {
    try {
      setIsDeleting(true);
      await experienceApi.deleteExperience(experienceId);
      setIsDeleting(false);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {experience && (
        <>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={IconButton}
                  icon={<MdMoreHoriz />}
                  variant='coralOutline'
                />
                <MenuList>
                  <MenuItem onClick={() => {
                    modalEditObjective.onOpen();
                  }}>Edit Objective</MenuItem>
                  <MenuItem onClick={() => {
                    modalEditPrototype.onOpen();
                  }}>{experience.prototype_link ?  'Edit Prototype' : 'Add Prototype'}</MenuItem>
                  {rights.can_delete && (
                    <MenuItem onClick={() => {
                      modalDelete.onOpen();
                    }}>
                      <Text color='text.medium.red' >Delete</Text>
                    </MenuItem>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        </>
      )}
      <Modal isOpen={modalEditObjective.isOpen} onClose={modalEditObjective.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Objective</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {experience && (
                <ExperienceObjectiveForm
                  hideSave={true}
                  experienceId={experience.id}
                  experienceObjective={experience.objective}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditObjective.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='coralOutline'
              onClick={modalEditObjective.onClose}
            >Close</Button>
            <Button
              variant="coralFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditPrototype.isOpen} onClose={modalEditPrototype.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Prototype</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {experience && (
                <PrototypeForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditPrototype.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref2}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='coralOutline'
              onClick={modalEditPrototype.onClose}
            >Close</Button>
            <Button
              variant="coralFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Delete experience {experience && experience.objective}</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {(experience && experience.objective) && (
                <Flex
                  border="1px"
                  borderColor="divider.grey"
                  borderRadius="2px"
                  backgroundColor="white"
                  alignItems={'center'}
                  p={3}
                >
                  <Icon
                    as={MdOutlineWarning}
                    color={'text.medium.red'}
                    boxSize={'18px'}
                    mr={3}
                  />
                  <Text textStyle="body" color={'text.medium.red'} >Are you sure to delete experience {experience.objective}?</Text>
                </Flex>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={modalDelete.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isDeleting}
              onClick={deleteExperience}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}




