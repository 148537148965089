'use client';

import React, { useContext } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import ProfileDetail from './component/profileDetail';
import {AuthContext} from '@/auth/lib/authContext'
export default function ProfilePage() {

  const {isAuthenticated, profile} = useContext(AuthContext) 

  return (
    <Flex
      flexDirection={"column"}
      flex="1"
      alignSelf={'stretch'}
      alignItems={'stretch'}
      >
      <Flex
       borderBottom="1px"
       borderColor="divider.grey"
       backgroundColor="white"
       px={3}
       py={2}
      >
        <Text fontSize="16px">Profile</Text>
      </Flex>
      <Flex p={3}>
        {(isAuthenticated && profile) && <ProfileDetail/> }
      </Flex>
    </Flex>
  )
}




