import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Text, Flex, Spacer } from '@chakra-ui/layout';
import { Button, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag, useDisclosure } from '@chakra-ui/react';
import { ProfileContext } from '../lib/profileContext';
import SubscriptionModal from '../../subscriptions/components/subscriptionModal';
import { MdClose, MdMoreHoriz, MdMoreVert, MdOutlineCancel, MdOutlinePayments, MdPayments } from 'react-icons/md';
import PaymentsModal from '../../payment/components/paymentsModal';
import CancelSubscriptionModal from '../../subscriptions/components/cancelSubscriptionModal';
import { UserOrganisationContext } from '../../organisation/lib/userOrganisationContext';
import { Link, useSearchParams } from 'react-router-dom';

export default function ProfilePlan(props) {

  const {
    profileSystem,
    subscription
  } = useContext(ProfileContext)

  const {
    organisation
  } = useContext(UserOrganisationContext)

  const [searchParams] = useSearchParams();
  const upgradePlanParams = searchParams.get('modal');

  const subscriptionModal = useDisclosure();
  const paymentsModal = useDisclosure();
  const cancelSubscriptionModal = useDisclosure();

  useEffect(() => {
    if(upgradePlanParams) {
      subscriptionModal.onOpen();
    }
  }, [upgradePlanParams]);

  return (
    <Box px={3} py={3}>
      {profileSystem && (
        <>
          <Text textStyle="subtitle">Plan</Text>
          <Flex alignItems={'center'}>
            {profileSystem.role === 'standard' &&
              <>
                <Tag variant="coral">Basic</Tag>
                <Button
                  variant={'coralOutline'}
                  onClick={subscriptionModal.onOpen}
                  ml={3}
                >Upgrade Plan</Button>
                <SubscriptionModal disclosure={subscriptionModal} />
              </>
            }
            {profileSystem.role === 'premium' &&
              <>
                {(subscription && subscription.plan_name === 'pro') &&
                  <>
                    <Tag variant="blue" mr={3}>Pro</Tag>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            isActive={isOpen}
                            as={IconButton}
                            icon={<MdMoreHoriz />}
                            variant='blueOutline'
                          />
                          <MenuList>
                            <MenuItem
                              icon={<MdOutlinePayments size="16px" />}
                              onClick={paymentsModal.onOpen}
                            >See Payments</MenuItem>
                            <MenuItem
                              icon={<MdClose size="16px" />}
                              color="text.medium.red"
                              onClick={cancelSubscriptionModal.onOpen}>
                              Cancel Subscription
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                    <PaymentsModal disclosure={paymentsModal} />
                    <CancelSubscriptionModal disclosure={cancelSubscriptionModal} />
                  </>
                }
                {(organisation) &&
                  <>
                    <Flex
                      alignItems={"center"}
                      gap={3}
                      justifyContent={"space-between"}
                      w={"100%"}
                      >
                      <Tag variant="purple">Enterprise</Tag>
                      {organisation && 
                      <Link to={"/organisation"}>
                        <Button variant={"purpleOutline"}>Go to {organisation.name}</Button>
                      </Link>
                      }
                    </Flex>
                  </>
                }
              </>
            }
          </Flex>
        </>
      )}
    </Box>
  );
}