'use client'

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import ProfilePseudoForm from '@/profile/component/profilePseudoForm';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { AuthContext } from './authContext';
import ProfileProvider from '@/profile/lib/profileProvider';
import useProfileApi from '../../profile/lib/useProfileApi';
import { supabase } from '../../supabase.client';
import SettingsProvider from '../../settings/lib/settingsProvider';
import Logo from '../../components/logo';

export default function AuthProvider({ children }) {

  const modalEditPseudo = useDisclosure();
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState(false);
  const toast = useToast();
  const ref1 = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [needAuth, setNeedAuth] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [session, setSession] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [needPseudo, setNeedPseudo] = useState(false);
  const [profile, setProfile] = useState();
  const [event, setEvent] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const profileApi = useProfileApi();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');
  const arg = searchParams.get('arg');

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      resetAuth();
      navigate('/auth/sign-in')
    } catch (e) {
      console.error(e);
    }
  };

  const signIn = async (email, password) => {
    try {
      storeRedirections();
      const result = await supabase.auth.signInWithPassword({ email, password })
      if (result.error) {
        throw result.error;
      }
    } catch (e) {
      throw e;
    }
  };

  const signInWithGoogle = async () => {
    try {
      storeRedirections();
      await supabase.auth.signInWithOAuth({
        provider: 'google',
        redirectTo: window.location.origin + (redirectUrl + ( arg ? ('?' + arg) : ''))
      });
    } catch (e) {
      throw e;
    }
  };

  const signUp = async (email, password) => {
    try {
      storeRedirections();
      const result = await supabase.auth.signUp(
        {
          email, password, options: {
            emailRedirectTo: window.location.origin + '/home'
          }
        }
      )
      if (result.error) {
        throw result.error;
      }
    } catch (e) {
      throw e;
    }
  };

  const resetPasswordForEmail = async (email, redirectTo) => {
    try {
      await supabase.auth.resetPasswordForEmail(email, { redirectTo })
    } catch (error) {
      console.error(error);
    }
  }

  const updatePassword = async (password) => {
    try {
      const hasSession = await checkSession();
      if(hasSession) {
        const result = await supabase.auth.updateUser({ password })
        if (result.error) {
          throw result.error;
        }
      } else {
        throw new Error('You need to be authenticated to reset your password')
      }
    } catch (error) {
      throw error;
    }
  }

  async function refreshSession() {
    const { data, error } = await supabase.auth.refreshSession()
    if (error) {
      console.error('Error refreshing session:', error)
    }
  }

  const disableAccount = async () => {
    try {
      await profileApi.disableAccount(profile.id)
    } catch (e) {
      console.error(e)
    }
  };

  const getSession = () => {
    return  supabase.auth.getSession();
  }

  const onAuthStateChange =  (callback) => {
    return supabase.auth.onAuthStateChange(callback);
  }

  const checkSession = async () => {
    try {
      const {data} = await getSession();
      if(data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error)
    }
  };

  const refreshProfile = async () => {
    try {
      const profile = await profileApi.fetchProfile(session.user.id)
      if(profile) {
        if (profile.pseudo === null) {
          setNeedPseudo(true);
        } else {
          setNeedPseudo(false);
        }
        setProfile(profile);
        setHasProfile(true);
      } else {
        setHasProfile(false);
      }
    } catch (e) {
      console.error(e)
    }
  };

  const resetAuth = async () => {
    try {
      setNeedAuth(true);
      setIsAuthenticated(false);
      setSession(null);
      setProfile(null);
      setHasProfile(false);
      setLoadingSession(false);
    } catch (error) {
      console.error(error)
    }
  }
  const onAuthenticated = async () => {
    try {
      setIsAuthenticated(true);
      setNeedAuth(false);
      setLoadingSession(false);
    } catch (e) {
      console.error(e)
    }
  };

  const refresh = async () => {
    try {
      refreshProfile();
    } catch (error) {
      console.error(error)
    }
  }

  const manageRedirection = () => {
    if(redirectUrl) {
      navigate((redirectUrl + ( arg ? ('?' + arg) : '')));
    } else {
      const redirectUrlStorage = localStorage.getItem('redirect_url');
      const redirectArgStorage = localStorage.getItem('arg');
      if(redirectUrlStorage) {
        localStorage.removeItem('redirect_url');
        localStorage.removeItem('arg');
        navigate((redirectUrlStorage + ( redirectArgStorage ? ('?' + redirectArgStorage) : '')));
      }
    }
  }

  const storeRedirections = () => {
    if(redirectUrl) {
      localStorage.setItem('redirect_url', redirectUrl)
    }
    if(arg) {
      localStorage.setItem('arg', arg)
    }
  };

  const fetchAppSettings = async () => {
    try {
      const result = await supabase.from('app_settings')
      .select()
      .limit(1)
      .single();
      if(result.error) {
        console.error(error);
      } else {
        setIsMaintenance(result.data.is_maintenance)
      }
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    if (session) {
      setEmail(session.user.email)
      refreshProfile();
    }
  }, [session]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      setLoadingSession(true);
      fetchAppSettings();
      getSession()
      .then(({ data: { _session } }) => {
        setSession(_session)
      })
      const {
        data: { subscription },
      } = onAuthStateChange((_event, _session) => {
        setEvent(_event);
        setSession(_session);
      })
      const refreshInterval = 10 * 60 * 1000
      if (session) {
        refreshSession();
      }
      const interval = setInterval(() => {
        refreshSession();
      }, refreshInterval)
      return () => {
        clearInterval(interval);
        subscription.unsubscribe()
      }
    }
  }, []);


  useEffect(() => {
    if (event === 'PASSWORD_RECOVERY') {
      navigate('/auth/update-password')
    } else if (event === 'SIGNED_IN') {
      setIsSignedIn(true);
      setSession(session)
      onAuthenticated();
    } else if (event === 'INITIAL_SESSION') {
      if (!isSignedIn) {
        resetAuth();
      }
    }
  }, [event, session]);

  useEffect(() => {
    if (isAuthenticated && needPseudo && profile) {
      modalEditPseudo.onOpen();
    }
  }, [isAuthenticated, needAuth, profile]);

  useEffect(() => {
    if (needAuth
      && !location.pathname.includes('/auth')
      && !location.pathname.includes('/legal')
    ) {
      navigate('/auth/sign-in' + (redirectUrl ? '?redirect_url=' + redirectUrl : '') + (arg ? '&arg=' + arg : ''));
    }
  }, [needAuth, location]);

  useEffect(() => {
    if (isAuthenticated
      && !location.pathname.includes('/auth')
      && !location.pathname.includes('/legal')
    ) {
      manageRedirection();
    }
  }, [isAuthenticated, location]);

  return (
    <AuthContext.Provider value={{
      profile,
      email,
      isAuthenticated,
      hasProfile,
      signOut,
      signIn,
      signInWithGoogle,
      signUp,
      resetPasswordForEmail,
      updatePassword,
      disableAccount,
      refresh: refresh
    }}>
      {(!loadingSession && !profile && location.pathname.includes('auth')) && <>{children}</>}
      {(!loadingSession && profile) && (
        <ProfileProvider
          profileId={profile.id}
          originalProfile={profile}
          needProfileSystem={true}
          needSubscription={true}
          needPayments={true}
        >
          <SettingsProvider >
              {children}
          </SettingsProvider>
        </ProfileProvider>
      )}
      <Modal
        isOpen={modalEditPseudo.isOpen}
        onClose={modalEditPseudo.onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Name</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <Box
                border="1px"
                borderColor="divider.grey"
                backgroundColor="white"
                py={2}
                px={3}
                mb={2}
              >
                <Text >The name is mandatory. This is the name that the other users will see.</Text>
              </Box>
              {profile && (
                <ProfilePseudoForm
                  hideSave={true}
                  profileId={profile.id}
                  profilePseudo={profile.pseudo}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    toast({
                      position: 'bottom-right',
                      description: "Name successfuly updated",
                      status: 'success',
                      duration: 1000,
                      isClosable: true,
                    });
                    modalEditPseudo.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isMaintenance && (
        <Flex
          position="fixed"
          left="0"
          top="0"
          w="100%"
          h="100%"
          backgroundColor="background.grey"
          zIndex={"1000"}
          alignItems={"center"}
          justifyContent={"center"}
          p={3}
          flexDirection={"column"}
        >
          <Logo size="30" />
          <Text
            maxW={"400px"}
            mt={3}
            textAlign={"center"}
            >Bibliona is currently under maintenance. We may be resolving a technical issue or releasing a new feature. The service will be back shortly. We apologize for the inconvenience.</Text>
        </Flex>
      )}
    </AuthContext.Provider>
  );
}



