import React, { useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
} from '@chakra-ui/react'
import useFeedbackApi from '../lib/useFeedbackApi';
import AddFeedbackForm from './addFeedbackForm';

export default function FeedbackModal(props) {

  const {
    onAdded,
    disclosure
  } = props;


  const toast = useToast();
  const [isAdding, setIsAdding] = useState(false);
  const [formValidity, setFormValidity] = useState(false);
  const ref = useRef();

  const _onFeedbackAdded = async () => {
    try {
      setIsAdding(true)
      disclosure.onClose();
      toast({
        position: 'bottom-right',
        description: "Thank you for sharing your feedback",
        duration: 3000,
        isClosable: true,
      });
      setIsAdding(false);
      if (onAdded) {
        onAdded()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Give Feedback</ModalHeader>
          <ModalBody p={3}>
            <AddFeedbackForm
              hideSave={true}
              onValidityChange={(validityState) => {
                setFormValidity(validityState);
              }}
              onSubmitting={() => {
                setIsAdding(true);
              }}
              onSubmitted={() => {
                setIsAdding(false);
                _onFeedbackAdded();
                if (onChange) {
                  onChange();
                }
              }}
              ref={ref}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant='coralOutline'
              onClick={disclosure.onClose}
            >Close</Button>
            <Button
              variant="coralFill"
              ml={2}
              isDisabled={!formValidity}
              isLoading={isAdding}
              onClick={() => {
                ref.current.click();
              }}>Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
