'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, FormLabel, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import usePersonaApi from '../../lib/usePersonaApi';
import {forwardRef} from '@chakra-ui/system';
import { PersonaContext } from '../../lib/personaContext';
import { Select } from 'chakra-react-select';

const PersonaAttributesForm = forwardRef((props, ref) => {

  const {
    persona,
    personaId,
    refresh,
  } = useContext(PersonaContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm();

  const personaApi = usePersonaApi();
  const [ages, setAges] = useState([]);

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      if(personaId) {
        const atttributes = {}
        await personaApi.updateAttributes({
          age: data.age?.value || null,
          job: data.job,
          education: data.education,
          family_status: data.family_status,
          location: data.location,
          interests: data.interests
        }, personaId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(persona.age) {
      setValue('age', {
        label: persona.age,
        value: persona.age
      } );
    }
    if(persona.job) {
      setValue('job', persona.job);
    }
    if(persona.education) {
      setValue('education', persona.education);
    }
    if(persona.family_status) {
      setValue('family_status', persona.family_status);
    }
    if(persona.location) {
      setValue('location', persona.location);
    }
    if(persona.interests) {
      setValue('interests', persona.interests);
    }
    const newAges = [{value: null, label: 'Not Specified'}];
    for(let i = 0; i <= 130; i++ ) {
      newAges.push({value: i, label: i})
    }
    setAges(newAges);
  }, [persona]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex alignSelf={"stretch"}  w={"100%"}>
        <form style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'stretch'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.age}>
              <FormLabel>Age</FormLabel>
              <Controller
                name="age"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <Select
                  options={ages}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                )}
              />
              <FormErrorMessage>{errors && errors.age}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors && errors.job}>
              <FormLabel>Job</FormLabel>
              <Input
                placeholder='Job'
                {...register('job', {})}
              />
              <FormErrorMessage>{errors && errors.job}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors && errors.education}>
              <FormLabel>Education</FormLabel>
              <Input
                placeholder='Education'
                {...register('education', {})}
              />
              <FormErrorMessage>{errors && errors.education}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors && errors.family_status}>
            <FormLabel>Family Status</FormLabel>
              <Input
                placeholder='Family Status'
                {...register('family_status', {})}
              />
              <FormErrorMessage>{errors && errors.family_status}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors && errors.interests}>
              <FormLabel>Interests</FormLabel>
              <Input
                placeholder='Interests'
                {...register('interests', {})}
              />
              <FormErrorMessage>{errors && errors.interests}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                    <IconButton
                      variant="greyOutline"
                      icon={<MdClose />}
                      onClick={onCancel}
                    />
                    )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='blueOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default PersonaAttributesForm;


