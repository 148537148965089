import React, { useEffect, useState } from 'react';
import useInsightApi from '../../insight/lib/useInsightApi';
import { insightTypeDictionnary } from '../../insight/lib/insightTypeDictionnary';
import InsightTags from '@/insight/components/insightTags';

export default function ExperienceInsightTags(props) {

  const { experienceId, personaId, productId } = props;
  const insightApi = useInsightApi();
  const [isInit, setIsInit] = useState(false);
  const [nbPostiveFeedback, setnbPostiveFeedback] = useState(null);
  const [nbNegativeFeedback, setnbNegativeFeedback] = useState(null);
  const [nbImprovementFeedback, setnbImprovementFeedback] = useState(null);
  const [nbBehavior, setNbBehavior] = useState(null);
  const [nbMotivation, setNbMotivation] = useState(null);

  const init = async () => {
    try {
      setIsInit(false);
      let newPositiveFeedback, newNegativeFeedback, newImprovementFeedback, newBehaviors, newMotivations;
      if (personaId) {
        newPositiveFeedback = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.positive,
          status: 'active',
        });
        newNegativeFeedback = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.negative,
          status: 'active',
        });
        newImprovementFeedback = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.improvement,
          status: 'active',
        });
        newBehaviors = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.behavior,
          status: 'active',
        });
        newMotivations = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.motivation,
          status: 'active',
        });
      } else if (productId) {
        newPositiveFeedback = await insightApi.fetchInsightListByExperienceIdAndProductId(experienceId, productId, {
          type: insightTypeDictionnary.positive,
          status: 'active',
        });
        newNegativeFeedback = await insightApi.fetchInsightListByExperienceIdAndProductId(experienceId, productId, {
          type: insightTypeDictionnary.negative,
          status: 'active',
        });
        newImprovementFeedback = await insightApi.fetchInsightListByExperienceIdAndProductId(experienceId, productId, {
          type: insightTypeDictionnary.improvement,
          status: 'active',
        });
        newBehaviors = await insightApi.fetchInsightListByExperienceIdAndProductId(experienceId, productId, {
          type: insightTypeDictionnary.behavior,
          status: 'active',
        });
        newMotivations = await insightApi.fetchInsightListByExperienceIdAndProductId(experienceId, productId, {
          type: insightTypeDictionnary.motivation,
          status: 'active',
        });
      } else {
        newPositiveFeedback = await insightApi.fetchInsightListByExperienceId(experienceId, {
          type: insightTypeDictionnary.positive,
          status: 'active',
        });
        newNegativeFeedback = await insightApi.fetchInsightListByExperienceId(experienceId, {
          type: insightTypeDictionnary.negative,
          status: 'active',
          personaId
        });
        newImprovementFeedback = await insightApi.fetchInsightListByExperienceId(experienceId, {
          type: insightTypeDictionnary.improvement,
          status: 'active',
        });
        newBehaviors = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.behavior,
          status: 'active',
        });
        newMotivations = await insightApi.fetchInsightListByExperienceIdAndPersonaId(experienceId, personaId, {
          type: insightTypeDictionnary.motivation,
          status: 'active',
        });
      }

      setnbPostiveFeedback(newPositiveFeedback ? newPositiveFeedback.length : 0);
      setnbNegativeFeedback(newNegativeFeedback ? newNegativeFeedback.length : 0);
      setnbImprovementFeedback(newImprovementFeedback ? newImprovementFeedback.length : 0);
      setNbBehavior(newBehaviors ? newBehaviors.length : 0);
      setNbMotivation(newMotivations ? newMotivations.length : 0);
      setIsInit(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (experienceId) {
      init()
    }
  }, [experienceId, personaId, productId]);

  return (
    <> {
      isInit && (
        <InsightTags
          nbPostiveFeedback={nbPostiveFeedback}
          nbNegativeFeedback={nbNegativeFeedback}
          nbImprovementFeedback={nbImprovementFeedback}
          nbBehavior={nbBehavior}
          nbMotivation={nbMotivation}
        />
      )
    }
    </>
  );
}
