import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { MdCreditScore } from "react-icons/md";
import { Link } from "react-router-dom";

export default function SuccessPage() {
  return (
    <Flex
      alignItems={"stretch"}
      justifyContent={"center"}
      flex="1"
      padding={3}
      >
      <Flex
        flexDirection={"column"}
        border={"1px solid"}
        borderColor={"divider.grey"}
        backgroundColor={"white"}
        alignItems={"center"}
        justifyContent={"center"}
        flex="1"
        >
        <Icon
          as={MdCreditScore}
          color={'fill.green'}
          boxSize={'50px'}
          mb={3}
          borderRadius={"2px"}
        />
        <Text>Thank you for your subscription!</Text>
        <Text>Your payment was successful.</Text>
        <Link to="/home">
          <Button variant="coralOutline" mt={3}>Go Home</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
