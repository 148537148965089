import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { MdCancel, MdContactSupport, MdOutlineCancel, MdOutlineContactSupport } from "react-icons/md";
import { Link } from "react-router-dom";

export default function CancelPage() {
  return (
    <Flex
      alignItems={"stretch"}
      justifyContent={"center"}
      flex="1"
      padding={3}
    >
      <Flex
        flexDirection={"column"}
        border={"1px solid"}
        borderColor={"divider.grey"}
        backgroundColor={"white"}
        alignItems={"center"}
        justifyContent={"center"}
        flex="1"
      >
        <Icon
          as={MdOutlineContactSupport}
          color={'fill.red'}
          boxSize={'50px'}
          mb={3}
          borderRadius={"2px"}
        />
        <Text>Your subscription was canceled.</Text>
        <Text>If you have any questions, please contact support</Text>
        <Link to="https://www.support.bibliona.com/">
          <Button variant="redOutline" mt={3}>Contact Support</Button>
        </Link>
        <Link to="/home">
          <Button variant="greyTransparent" mt={3}>Go Home</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
