import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, ModalFooter } from '@chakra-ui/modal';
import { Link, useNavigate } from 'react-router-dom';
import {Flex, Button, Icon, ListItem, Text, UnorderedList, Input, useToast } from "@chakra-ui/react";
import StripeContext from '../lib/stripeContext';
import { ProfileContext } from '../../profile/lib/profileContext';
import { MdOutlineWarning } from 'react-icons/md';
import { UserOrganisationContext } from '../../organisation/lib/userOrganisationContext';

export default function CancelSubscriptionModal(props) {

  const { disclosure } = props;

  const {
    subscription,
    refresh
  } = useContext(ProfileContext)

  const userOrganisationContext = useContext(UserOrganisationContext)
  const organisationRef = useRef();

  const {
    unsubscribe
  } = useContext(StripeContext)

  const [isCancelling, setIsCancelling] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  
  const handleChange = async(event) => {
    setValue(event.target.value)
    setIsValid(event.target.value.toLowerCase() === 'cancel subscription')
  };

  const cancelSubscription = async () => {
    try {
      setIsCancelling(true);
      await unsubscribe(subscription.id)
      const interval = setInterval(async () => {
        if(!organisationRef.current) {
          setIsCancelling(false);
          disclosure.onClose();
          toast({
            position: 'bottom-right',
            description: "Your subscription is canceled",
            status: 'success',
            duration: 1000,
            isClosable: true,
          });
        await refresh();
          navigate('/profile')
          clearInterval(interval);
        } else {
          await userOrganisationContext.refreshOrganisation();
        }
      }, 500)
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    organisationRef.current = userOrganisationContext.organisation;
  }, [userOrganisationContext.organisation]);
  
  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Box p={3}>
              <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="2px"
                backgroundColor="white"
                p={3}
                flexDirection={"column"}
              >
                <Flex  alignItems={'center'} >
                  <Icon
                    as={MdOutlineWarning}
                    color={'text.medium.red'}
                    boxSize={'18px'}
                    mr={3}
                  />
                  <Text textStyle="body" color={'text.medium.red'} >Are you sure to cancel your subscription? <br/> This will take effect immediately. You will no longer have access to the benefits of this subscription.</Text>
                </Flex>
              </Flex>

              <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="2px"
                backgroundColor="white"
                p={3}
                flexDirection={"column"}
                mt={2}
              >
                  <Text textStyle="body" mb={2}>Please type "cancel subscription" to confirm.</Text>
                  <Input value={value} onChange={handleChange} />
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={disclosure.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isCancelling}
              isDisabled={!isValid}
              onClick={cancelSubscription}>Cancel Subscription</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}