import React, { useContext, useEffect, useState } from 'react';
import usePersonaApi from './usePersonaApi';
import { PersonaContext } from './personaContext.js';
import { AuthContext } from '@/auth/lib/authContext';

export default function PersonaProvider(props) {

  let {
    personaId,
    originalPersona,
    children,
    onFetchingData,
    onDataFetched,
    onInit,
    needRights = true,
    needRole = true,
    needEditionRequest = true,
  } = props;
  const { profile, hasProfile} = useContext(AuthContext);
  const [persona, setPersona] = useState();
  const [role, setRole] = useState();
  const [hasRequestedEdition, setHasRequestedEdition] = useState(false);
  const [rights, setRights] = useState({
    can_update: false,
    can_insert: false,
    can_delete: false
  });
  const personaApi = usePersonaApi();
  const [isInit, setIsInit] = useState(false);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);

  const refresh = async (options = {skipPersona: false}) => {
    try {
      if(onFetchingData) {
        onFetchingData()
      }
      const promises = [];
      let nbRequest = 0;
      let orders = {};
      if(!options.skipPersona) {
        promises.push(personaApi.fetchPersona(personaId))
        orders.persona = nbRequest;
        nbRequest++;
      }
      if(needRights) {
        promises.push(personaApi.fetchUserRights(personaId, profile.id))
        orders.rights = nbRequest;
        nbRequest++;
      }
      if(needRole) {
        promises.push(personaApi.fetchMemberRole(personaId, profile.id))
        orders.role = nbRequest;
        nbRequest++;
      }
      if(needEditionRequest) {
        promises.push(personaApi.hasRequestedEdition(personaId, profile.id))
        orders.editionRequest = nbRequest;
        nbRequest++;
      }
      const promisesResult = await Promise.all(promises);
      if(!options.skipPersona) {
        setPersona(promisesResult[orders.persona]);
      }
      if(needRights) {
        setRights(promisesResult[orders.rights]);
      }
      if(needRole) {
        setRole(promisesResult[orders.role]);
      }
      if(needEditionRequest) {
        setHasRequestedEdition(promisesResult[orders.editionRequest]);
      }
      if(onDataFetched) {
        onDataFetched()
      }
      if(!isInit) {
        setIsInit(true);
        if(onInit) {
          onInit(true);
        }
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      if (originalPersona) {
        refresh({skipPersona: true})
      } else {
        refresh()
      }
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

useEffect(() => {
    if(personaId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
      setIsInit(false);
    }
  }, [personaId, hasProfile]);
  
  useEffect(() => {
    if (originalPersona && hasProfile) {
      setPersona(originalPersona);
    }
  }, [originalPersona, hasProfile]);

  return (
    <PersonaContext.Provider value={{
      role,
      persona,
      personaId,
      rights,
      refresh,
      hasRequestedEdition
    }} >
      {persona && (
        <>
          {children}
        </>
      )}
    </PersonaContext.Provider>
  );
}