'use client'

import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/button';
import { MdCheck, MdClose } from 'react-icons/md';
import { forwardRef } from '@chakra-ui/system';
import { OrganisationContext } from '../lib/organisationContext';
import useOrganisationApi from '../lib/useOrganisationApi';

const OrganisationNameForm = forwardRef((props, ref) => {

  const {
    organisation,
    organisationId,
    refresh,
  } = useContext(OrganisationContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const organisationApi = useOrganisationApi();

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if (name && organisationId) {
        await organisationApi.updateName(name, organisationId);
        await refresh();
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (organisation.name) {
      setValue('name', organisation.name);
    }
  }, [organisation.name]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.name}>
              <Input
                placeholder='Name'
                {...register('name', {
                  required: true,
                  minLength: 3
                })}
              />
              <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
              )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='greyFill'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default OrganisationNameForm;


