'use client'

import React, {useEffect, useState} from 'react';
import {Box, Flex, Text} from '@chakra-ui/layout';
import ProfileThumbnail from '@/profile/component/profileThumbnail';
import {Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import ProfileProvider from '@/profile/lib/profileProvider';

export default function PersonaUserTempListItem(props) {

  const {
    userTemp,
  } = props;  

  return (
    <>
      {userTemp && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={3}
          backgroundColor="white"
        >
          <Flex alignItems={'center'}>
            <ProfileProvider 
              profileId={userTemp.id}
              originalProfile={userTemp}
            >
              <ProfileThumbnail size={40} />
            </ProfileProvider>
            <Box ml={2}>
              <Text>{userTemp.email}</Text>
            </Box>
          </Flex>
          <Flex>
            <Tooltip label="This user will be added after signing up">
              <Tag variant="orange">
                  <TagLabel px={2}>Invited</TagLabel>
              </Tag>
            </Tooltip>
          </Flex>
      </Flex>
      )}
    </>
  )
}




