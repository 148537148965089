'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, FormLabel, Input, Skeleton, Switch, Textarea } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/button';
import { MdCheck, MdClose } from 'react-icons/md';
import { forwardRef } from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';
import { ExperienceContext } from '@/experiences/lib/experienceContext';
import { Select } from 'chakra-react-select';
import useProductsApi from '@/products/lib/useProductsApi';

const InsightProductListForm = forwardRef((props, ref) => {

  const {
    experienceId,
  } = useContext(ExperienceContext);

  const {
    insight,
    refresh
  } = useContext(InsightContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel,
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    formState: { isSubmitting, isValid }
  } = useForm();

  const insightApi = useInsightApi();
  const productApi = useProductsApi();
  const [isProductChecked, setIsProductChecked] = useState(true);
  const [productOptionsList, setProductOptionsList] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      if (!data.applies_to_all_product) {
        const productIds = data.product.map(p => p.value);
        await insightApi.updateApplicableToAllProducts(
          insight.id,
          false,
          productIds
        )
      } else {
        await insightApi.updateApplicableToAllProducts(
          insight.id,
          true
        )
      }
      refresh();
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const init = async () => {
    try {
      setIsProductChecked(insight.applicable_to_all_products)
      setValue('applies_to_all_product', insight.applicable_to_all_products)
      if(!insight.applicable_to_all_products) {
        const insightProductList = await productApi.fetchProductsByInsightId(insight.id);
        const productListValue = insightProductList.map((p) => {
          return { label: p.name, value: p.id }
        });
        setValue('product', productListValue)
      }
      let newProductList = await productApi.fetchProductsByExperienceId(experienceId);
      newProductList = newProductList.map((p) => {
        return { label: p.name, value: p.id }
      });
      setProductOptionsList(newProductList);
      setIsInit(true);
    } catch(e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (experienceId && insight && !isInit) {
      init();
    }
  }, []);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
     {!isInit && (
      <Skeleton height={"50px"} />
     )}
      {isInit && (
            <Flex
            flex="1"
            w="100%"
          >
            <form
              display="flex"
              style={{
                width: "100%"
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Flex
                alignItems={'center'}
                w="100%"
                flexDirection={'column'}
                gap={2}
              >
                <FormControl
                  display='flex'
                  alignItems='center'
                  mt={3}
                  mb={3}>
                  <Switch
                    {...register('applies_to_all_product', {
                      onChange: (e) => {
                        const value = getValues().applies_to_all_product;
                        setIsProductChecked(value);
                      },
                    })}
                  />
                  <FormLabel ml={2} mb="0">
                    Applies to all products of this experience
                  </FormLabel>
                  <FormErrorMessage>{errors && errors.applies_to_all_product}</FormErrorMessage>
                </FormControl>
                {(!isProductChecked) && (
                  <Controller
                    control={control}
                    name="product"
                    rules={{ required: "At least one item must be selected" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, error }
                    }) => (
                      <FormControl isInvalid={errors && errors.product} mb={3}>
                        <FormLabel>Involved Products</FormLabel>
                        <Select
                          isMulti
                          options={productOptionsList}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                        <FormErrorMessage>{errors && errors.product}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                )}
                <Flex gap={2}>
                  {onCancel && (
                    <IconButton
                      variant="greyOutline"
                      icon={<MdClose />}
                      onClick={onCancel}
                    />
                  )
                  }
                  <IconButton
                    style={{
                      display: hideSave ? 'none' : 'inherit',
                    }}
                    variant="coralOutline"
                    ref={ref}
                    icon={<MdCheck />}
                    type={'submit'}
                    aria-label='check'
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  />
                </Flex>
              </Flex>
            </form>
          </Flex>
     )}
    </>
  );
});

export default InsightProductListForm;


