import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';
import { Button, Flex,ListItem, Skeleton, Text, UnorderedList, useToast } from "@chakra-ui/react";
import StripeContext from '../lib/stripeContext';
import * as ct from 'countries-and-timezones';
import { OrganisationContext } from '../../organisation/lib/organisationContext';
import { Link, useNavigate } from 'react-router-dom';
import { UserOrganisationContext } from '../../organisation/lib/userOrganisationContext';
import useOrganisationApi from '../../organisation/lib/useOrganisationApi';
import { ProfileContext } from '../../profile/lib/profileContext';
import { AuthContext } from '../../auth/lib/authContext';

export default function SubscriptionModal(props) {

  const { disclosure } = props;
  const stripeContext = useContext(StripeContext);
  const {
    organisation,
    refresh } = useContext(OrganisationContext);
  const userOrganisationContext = useContext(UserOrganisationContext);
  const { profile } = useContext(ProfileContext)
  const authContext  = useContext(AuthContext)
  const navigate = useNavigate()
  const toast = useToast()
  const organisationApi = useOrganisationApi();
  const [proLoading, setProLoading] = useState(false);
  const [enterpriseLoading, setEnterpriseLoading] = useState(false);
  const [isJoining, setIsjoining] = useState(false);
  const [timezone, setTimezone] = useState();
  const [usesEuro, setUsesEuro] = useState(false);
  const [prices, setPrices] = useState(false);

  const join = async () => {
    try {
        setIsjoining(true)
        await organisationApi.addMember(profile.id, organisation.id)
        await userOrganisationContext.refreshOrganisation();
        await refresh();
        await authContext.refresh();
        stripeContext.updateQuantity();
        navigate('/organisation')
        toast({
          position: 'bottom-right',
          description: "You joined the organisation",
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        setIsjoining(false)
    } catch (error) {
      console.error(error);
    }
  };

  const chooseProPlan = async () => {
    try {
      setProLoading(true);
      await stripeContext.subscribe(prices.pro.id);
      setProLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  const chooseEnterprisePlan = async () => {
    try {
      setEnterpriseLoading(true);
      await stripeContext.subscribe(prices.enterprise.id);
      setEnterpriseLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  const getPrices = async () => {
    try {
      const prices = await stripeContext.getPrices();
      const proPrice = prices.pro.filter((p) => {
          return (usesEuro ? p.currency === 'eur' : p.currency === 'usd');
      })[0]
      const enterprisePrice = prices.enterprise.filter((p) => {
        return (usesEuro ? p.currency === 'eur' : p.currency === 'usd');
    })[0]
    setPrices({
      pro: proPrice,
      enterprise: enterprisePrice
    })
    return ;
    } catch (error) {
      console.error(error)
    }
  }

  const locateUser = () => {
    const euroCountries = ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'];
    const newTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countries = ct.getCountriesForTimezone(newTimezone);
    let usesEuro = false;
    countries.forEach((country) => {
      if(euroCountries.indexOf(country.id) !== -1) {
        usesEuro = true;
      }
    });
    setUsesEuro(usesEuro)
    setTimezone(newTimezone);
  }

  useEffect(() => {
    if(!timezone) {
      locateUser();
    } else {
      getPrices();
    }
  }, [timezone])


  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent maxW={900} >
          <ModalHeader>Choose your Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Box p={3}>
              {stripeContext.loadingPrices && (
                <Skeleton width={"100%"} height={"200px"} />
              )}
              {(!stripeContext.loadingPrices && prices) && (
                <Flex
                gap={3}
                flexWrap={"wrap"}
                flexDirection={["column", "row"]}
              >
                <Flex
                  flexDirection={"column"}
                  border={"1px solid"}
                  borderColor={"divider.coral"}
                  borderRadius={"20px"}
                  backgroundColor={"background.coral"}
                  padding={"20px"}
                  flex={"1"}
                >
                  <Text color="text.medium.coral">Basic</Text>
                  <Text
                    color="text.medium.coral"
                    fontSize={"30px"}
                    lineHeight={"30px"}
                    fontFamily={'var(--font-vidaloka)'}>Free</Text>
                    <Button 
                      my={"30px"}
                      variant="coralFill"
                      width={"100%"}
                      opacity={0.3}
                      cursor={"default"}
                    >Current Plan</Button>
                  <Flex flexDirection={"column"}>
                    <Text flexDirection={"column"} color={"text.medium.coral"}>Includes: </Text>
                    <UnorderedList color={"text.medium.coral"}>
                      <ListItem>Unlimited Products</ListItem>
                      <ListItem>Unlimited Personas</ListItem>
                      <ListItem>Unlimited Experiences</ListItem>
                    </UnorderedList>
                  </Flex>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  border={"1px solid"}
                  borderColor={"divider.blue"}
                  borderRadius={"20px"}
                  backgroundColor={"background.blue"}
                  padding={"20px"}
                  flex={"1"}
                >
                  <Text color="text.medium.blue">Pro</Text>
                  <Flex alignItems={"baseline"}>
                    <Text
                      color="text.medium.blue"
                      fontSize={"30px"}
                      fontFamily={'var(--font-vidaloka)'}
                      lineHeight={"30px"}
                    >{usesEuro ? '' : '$'}{prices?.pro?.unit_amount / 100}{usesEuro ? '€' : ''}</Text>
                    <Text
                      color="text.medium.blue"
                      fontSize={"20px"}
                      fontFamily={'var(--font-vidaloka)'}
                      lineHeight={"30px"}
                    >/month</Text>
                  </Flex>
                    <Button
                      my={"30px"}
                      variant="blueFill"
                      width={"100%"}
                      onClick={chooseProPlan}
                      isLoading={proLoading}
                    >Select</Button>
                  <Flex flexDirection={"column"}>
                    <Text flexDirection={"column"} color={"text.medium.blue"} >Includes: </Text>
                    <UnorderedList color={"text.medium.blue"}>
                      <ListItem>Sharing and collaboration features</ListItem>
                      <ListItem>Unlimited Products</ListItem>
                      <ListItem>Unlimited Personas</ListItem>
                      <ListItem>Unlimited Experiences</ListItem>
                    </UnorderedList>
                  </Flex>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  border={"1px solid"}
                  borderColor={"divider.purple"}
                  borderRadius={"20px"}
                  backgroundColor={"background.purple"}
                  padding={"20px"}
                  flex={1}
                >
                  <Text color="text.medium.purple">Enterprise</Text>
                  <Flex alignItems={"baseline"}>
                    {!organisation && (
                      <Text
                      color="text.medium.purple"
                      fontSize={"30px"}
                      fontFamily={'var(--font-vidaloka)'}
                      lineHeight={"30px"}
                    >{usesEuro ? '' : '$'}{prices?.enterprise?.unit_amount / 100}{usesEuro ? '€' : ''}</Text>
                    )}
                    {organisation && (
                        <Text
                          height={"33px"}
                          color="text.medium.purple"
                          opacity={0.5}
                        >An organisation with your email domain exists already</Text>
                    )}
                    {!organisation &&
                         <Text
                         color="text.medium.purple"
                         fontSize={"20px"}
                         fontFamily={'var(--font-vidaloka)'}
                         lineHeight={"30px"}
                       >/user/month</Text>
   
                    }
                  </Flex>
                  {!organisation && (
                    <Button
                      my={"30px"}
                      variant="purpleFill"
                      width={"100%"}
                      onClick={chooseEnterprisePlan}
                      isLoading={enterpriseLoading}
                    >Select</Button>
                  )}
                  {organisation && (
                    <Button
                      my={"30px"}
                      variant="purpleFill"
                      width={"100%"}
                      onClick={join}
                      isLoading={isJoining}
                    >Join</Button>
                  )}
                  <Flex flexDirection={"column"}>
                    <Text flexDirection={"column"} color={"text.medium.purple"} >Includes: </Text>
                    <UnorderedList color={"text.medium.purple"}>
                      <ListItem>Library of Personas and Products accessible to any collaborators</ListItem>
                      <ListItem>Sharing and collaboration features</ListItem>
                      <ListItem>Common Billing</ListItem>
                      <ListItem>Unlimited Products</ListItem>
                      <ListItem>Unlimited Personas</ListItem>
                      <ListItem>Unlimited Experiences</ListItem>
                    </UnorderedList>
                  </Flex>
                </Flex>
              </Flex>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}