'use client'

import React, { useContext, useEffect, useState } from 'react';
import OrganisationProvider from '../../organisation/lib/organisationProvider';
import useOrganisationApi from '../../organisation/lib/useOrganisationApi';
import { UserOrganisationContext } from './userOrganisationContext';
import { AuthContext } from '../../auth/lib/authContext';

export default function UserOrganisationProvider({ children }) {

  const organisationApi = useOrganisationApi();
  const [organisation, setOrganisation] = useState(false);
  const [hasOrganisation, sethasOrganisation] = useState(false);
  const [hasPotentialOrganisation, setHasPotentialOrganisation] = useState(false);
  const [potentialOrganisation, setPotentialOrganisation] = useState(false);
  const [loadingOrganisation, setLoadingOrganisation] = useState(false);
  const {profile, hasProfile} = useContext(AuthContext);

  const refreshOrganisation = async () => {
    try {
      setLoadingOrganisation(true);
      const newOrganisation = await organisationApi.fetchMyOrganisation()
      setOrganisation(newOrganisation);
      if(!newOrganisation) {
        const potentialOrganisation = await organisationApi.fetchPotentialOrganisationOfUser(profile.id)
        setPotentialOrganisation(potentialOrganisation)
        setHasPotentialOrganisation(!!potentialOrganisation);
        sethasOrganisation(false);
        setOrganisation(null);
      } else {
        setPotentialOrganisation(null)
        setHasPotentialOrganisation(false)
        sethasOrganisation(true);
      }
      setLoadingOrganisation(false);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (hasProfile) {
      refreshOrganisation();
    }
  }, [hasProfile]);

  return (
    <UserOrganisationContext.Provider value={{
      hasOrganisation,
      hasPotentialOrganisation,
      loadingOrganisation,
      organisation,
      potentialOrganisation,
      refreshOrganisation: refreshOrganisation
    }}>
      <OrganisationProvider
        originalOrganisation={(organisation ? organisation : potentialOrganisation)}
        organisationId={(organisation ? organisation?.id : potentialOrganisation?.id)}
        onRefreshOrganisation={(orga) => {
          if(hasOrganisation) {
            setOrganisation(orga)
          } else if (hasPotentialOrganisation) {
            setPotentialOrganisation(orga)
          }
        }}
      >
      {children}
    </OrganisationProvider>
    </UserOrganisationContext.Provider>
  );
}



