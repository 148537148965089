import {supabase} from '../../supabase.client';

const useFeedbackApi = () => {
  
  const insertFeedback = async (type, description) => {
    try {
      const {error} = await supabase
      .from('feedback')
      .insert({type, description})
      if(error) {
        throw error; 
      } 
    } catch(e) {
      console.error(e);
    }
  }

  return {
    insertFeedback
  }
};

export default useFeedbackApi;
